import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import { ButtonGroup, IconButton, useEditableControls } from '@chakra-ui/react'

const EditableControls = () => {
  const { isEditing, getSubmitButtonProps, getCancelButtonProps } =
    useEditableControls()

  return isEditing ? (
    <ButtonGroup justifyContent="center" size="sm" ml={'2'}>
      <IconButton
        aria-label="tick"
        icon={<CheckIcon />}
        {...getSubmitButtonProps()}
      />
      <IconButton
        aria-label="close"
        icon={<CloseIcon />}
        {...getCancelButtonProps()}
      />
    </ButtonGroup>
  ) : null
}

export default EditableControls
