import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useRef } from 'react'
import { GET_TARGET_ALERTS } from '../../graphql/API/queries'
import { useTargetAlertCreateMutation } from '../../graphql/generated/graphql'

const CreateTargetModal = ({ channelId }: { channelId: string }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const initialRef = useRef(null)
  const toast = useToast()

  const refetchQueries = [
    { query: GET_TARGET_ALERTS, variables: { where: { channelId } } },
  ]

  const [createTarget, { loading: createLoader }] =
    useTargetAlertCreateMutation({ refetchQueries })

  const onFormSubmit = (e: any) => {
    e.preventDefault()
    const createInputVariables = {
      data: {
        name: e.target.targetName.value,
        channelId,
        triggerCondition: 'none',
      },
    }
    createTarget({
      variables: createInputVariables,
      onCompleted: () => {
        onClose()
        toast({
          title: `Target ${e.target.targetName.value} Created Successfully`,
          status: 'success',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
      },
      onError: (err) => {
        toast({
          title: err.message,
          status: 'error',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
      },
    })
  }

  return (
    <>
      <Button onClick={onOpen} colorScheme={'blue'}>
        + Create Target
      </Button>

      <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
        <form onSubmit={(e) => onFormSubmit(e)}>
          <ModalOverlay />
          <ModalContent bg={'white'}>
            <ModalHeader>Create Target</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl isRequired={true}>
                <FormLabel>Target Name</FormLabel>
                <Input ref={initialRef} name={'targetName'} />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button mr={3} onClick={onClose}>
                Close
              </Button>
              <Button
                colorScheme="blue"
                type={'submit'}
                isLoading={createLoader}
              >
                Submit
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  )
}

export default CreateTargetModal
