import { useParams } from 'react-router-dom'
import Loader from '../../elements/loader'
import MainDashboard from './mainDashboard'
// import SimpleDashWrapper from './simpleDash'
import DynamicColorComponent from '../../styles/dynamicColor'
import { useEffect, useState } from 'react'
// import { topLargest } from '../../utils/basicFunc/basicFunc'
import {
  DashboardQuery,
  useDashboardQuery,
} from '../../graphql/generated/graphql'
import OldDashboard from './oldDashboard'
import GraphDashboard from './graphDashboard'
import { PfHeadlineItemProps } from '../../components/pfHeadline/pfHeadline'
import Renewables from '../../elements/icons/renewables'
import WholeBuilding from '../../elements/icons/wholeBuilding'
import CO2 from '../../elements/icons/co2'
import WaterUsage from '../../elements/icons/waterUsage'

export type CardData = {
  type: string
  target: number
  measurement: number
  comparison: string
  comment: string
  period?: string
}

export type DashboardDataType = {
  primary: CardData[]
  secondary: CardData[]
} & DashboardQuery['dashboard']

export const dashboardColors = {
  primaryColor: '#e8eceb',
  secondaryColor: '#7BA408',
  fontColor: '#417e9a',
}

export const oldDashboardColors = {
  primaryColor: 'rgb(123, 164, 8)',
  secondaryColor: 'rgb(123, 164, 8)',
  fontColor: 'white',
}

const DashboardWrapper = () => {
  const params = useParams()
  const buildingSlug: string = params['buildingSlug'] ?? 'unknown building'
  const companySlug: string | undefined = params['companySlug'] ?? undefined
  const { data, loading: dashboardLoading } = useDashboardQuery({
    variables: { where: { slug: buildingSlug, companySlug } },
  })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!dashboardLoading) {
        setLoading(false)
      }
    }, 100)
    return () => clearTimeout(timer)
  }, [dashboardLoading])

  const defaultPrimaryCardData: CardData[] = [
    {
      comment: '',
      comparison: 'We are processing the data',
      measurement: 70,
      target: 270,
      type: 'WholeBuilding',
    },
    {
      comment: '',
      comparison: 'We are processing the data',
      measurement: 100,
      target: 150,
      type: 'CarbonDioxide',
    },
    {
      comment: '',
      comparison: 'We are processing the data',
      measurement: 180,
      target: 160,
      type: 'Renewables',
    },
  ]

  const defaultSecondaryCardData: CardData[] = [
    {
      comment: '',
      comparison: 'We are processing the data',
      measurement: 30,
      target: 20,
      type: 'Cooling',
    },
    {
      comment: '',
      comparison: 'We are processing the data',
      measurement: 12,
      target: 100,
      type: 'ProcessPower',
    },
    {
      comment: '',
      comparison: 'We are processing the data',
      measurement: 4,
      target: 10,
      type: 'Heating',
    },
    {
      comment: '',
      comparison: 'We are processing the data',
      measurement: 70,
      target: 100,
      type: 'Lighting',
    },
    {
      comment: '',
      comparison: 'We are processing the data',
      measurement: 10,
      target: 10,
      type: 'SmallPower',
    },
    {
      comment: '',
      comparison: 'We are processing the data',
      measurement: 1,
      target: 1000,
      type: 'Ventilation',
    },
  ]

  const isClassicDashboard = data?.dashboard?.dashboardStyle === 'classic',
    isGraphDashboard = data?.dashboard?.dashboardStyle === 'graph'

  const primary: CardData[] = data?.dashboard
    ? isClassicDashboard
      ? [
          { type: 'WholeBuilding', ...data?.dashboard?.wholeBuilding },
          { type: 'CarbonDioxide', ...data?.dashboard?.carbonDioxide },
          { type: 'WaterUsage', ...data?.dashboard?.waterUsage },
        ]
      : [
          { type: 'WholeBuilding', ...data?.dashboard?.wholeBuilding },
          { type: 'CarbonDioxide', ...data?.dashboard?.carbonDioxide },
          { type: 'Renewables', ...data?.dashboard?.renewables },
        ]
    : defaultPrimaryCardData

  const secondary: CardData[] = data?.dashboard
    ? [
        { type: 'Cooling', ...data?.dashboard?.coolingEnergy },
        { type: 'Heating', ...data?.dashboard?.heatingEnergy },
        { type: 'Lighting', ...data?.dashboard?.lightingEnergy },
        { type: 'ProcessPower', ...data?.dashboard?.processEnergy },
        { type: 'SmallPower', ...data?.dashboard?.socketPower },
        { type: 'Ventilation', ...data?.dashboard?.ventilation },
      ]
    : defaultSecondaryCardData

  // const speedometerValues = {
  //   secondary: topLargest(secondary, 5),
  //   energyGenerated: 0,
  //   totalEnergy: secondary.reduce(
  //     (total: number, item: CardData) => total + item.measurement,
  //     0,
  //   ),
  // }

  const dashboardData = {
    ...data?.dashboard,
    primary,
    secondary,
    // speedometerValues,
  } as DashboardDataType

  const headlineItems: PfHeadlineItemProps[] = [
    {
      Icon: WholeBuilding,
      label: 'buildingEnergy',
      units: 'kWh',
      description: 'totalEnergyUsed',
      upIsBetter: false,
      // type: 'Renewables',
      value: dashboardData?.wholeBuilding?.measurement,
      target: dashboardData?.wholeBuilding?.target,
    },
    {
      Icon: CO2,
      label: 'carbonDioxide',
      units: 'KgCO<sub>2</sub>e',
      description: 'totalEmissions',
      upIsBetter: false,
      // type: 'Renewables',
      value: dashboardData?.carbonDioxide?.measurement,
      target: dashboardData?.carbonDioxide?.target,
    },
    {
      Icon: WaterUsage,
      label: 'water',
      units: 'm<sup>3</sup>',
      description: 'totalWaterUsed',
      upIsBetter: false,
      value: dashboardData?.waterUsage?.measurement,
      target: dashboardData?.waterUsage?.target,
    },
    {
      Icon: Renewables,
      label: 'renewables',
      units: 'kWh',
      description: 'totalGenerated',
      upIsBetter: true,
      // type: 'Renewables',
      value: dashboardData?.renewables?.measurement,
      target: dashboardData?.renewables?.target,
    },
  ]

  const portfolio = (({
    coolingEnergy,
    lightingEnergy,
    heatingEnergy,
    processEnergy,
    socketPower,
    ventilation,
    carbonDioxide,
    waterUsage,
    wholeBuilding,
    renewables,
  }) => ({
    coolingEnergy,
    lightingEnergy,
    heatingEnergy,
    processEnergy,
    socketPower,
    ventilation,
    carbonDioxide,
    waterUsage,
    wholeBuilding,
    renewables,
  }))(dashboardData)

  return (
    <DynamicColorComponent
      colorObject={dashboardData?.dashboardColors ?? dashboardColors}
    >
      {loading ? (
        <Loader />
      ) : isClassicDashboard ? (
        <OldDashboard dashboardData={dashboardData} />
      ) : isGraphDashboard ? (
        <GraphDashboard
          headlineItems={headlineItems}
          period={dashboardData.period}
          portfolio={portfolio}
          dashboardData={dashboardData}
        />
      ) : (
        <MainDashboard dashboardData={dashboardData} />
      )}
    </DynamicColorComponent>
  )
}
export default DashboardWrapper
