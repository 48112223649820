import { Box, GridItem, Image, SimpleGrid } from '@chakra-ui/react'
import Maps from '../../components/maps/maps'
import { Address } from '../../graphql/generated/graphql'

export type MapBuildingsData = {
  id: string
  address: Address
  name: string
}

type HeroProps = {
  imageUrl?: string
  altText?: string
  mapBuildingsData?: MapBuildingsData[]
  height?: 'reg' | 'tall'
}

const Hero = ({
  mapBuildingsData,
  height = 'reg',
  imageUrl,
  altText,
}: HeroProps) => {
  const h =
    height === 'tall'
      ? {}
      : { base: '25vh', sm: '25vh', md: '30vh', lg: '40vh' }

  const isMapDataPresent = mapBuildingsData?.some(
    (datum: MapBuildingsData) => datum.address.postcode,
  )
  let numberOfPostcodePresent = 0

  if (mapBuildingsData) {
    for (const mapData of mapBuildingsData) {
      if (mapData.address.postcode) {
        numberOfPostcodePresent += 1
      }
    }
  }

  return (
    <SimpleGrid alignItems={'center'} columns={12}>
      {!isMapDataPresent && (
        <GridItem
          colSpan={[1, 1, 1, 1, 2]}
          display={{ base: 'none', sm: 'none', md: 'flex' }}
        ></GridItem>
      )}

      <GridItem colSpan={!isMapDataPresent ? [12, 12, 10, 10, 8] : 12}>
        {!isMapDataPresent || numberOfPostcodePresent < 2 ? (
          <Image
            w={'full'}
            h={h}
            src={imageUrl}
            alt={altText}
            objectFit={'cover'}
          />
        ) : (
          <Box h={h} mt={2}>
            <Maps height={h} mapBuildingsData={mapBuildingsData!} />
          </Box>
        )}
      </GridItem>
    </SimpleGrid>
  )
}

export default Hero
