import { Box, Center, Flex, Skeleton, Spacer, Tooltip } from '@chakra-ui/react'
import displayScale from '../../utils/unitHelpers/displayScale'

type trafficLightBlockProps = {
  type: string
  value: number
  target: number
  units: string
  loading: boolean
}

const PfTrafficLightBlock = ({
  type,
  value,
  target,
  units,
  loading,
}: trafficLightBlockProps) => {
  const percentage = Math.round((value / target) * 100)
  const greenColor = loading
    ? '#91c138'
    : percentage < 95
      ? '#91c138'
      : 'gray.400'
  const amberColor = loading
    ? '#f9c138'
    : percentage >= 95 && percentage <= 115
      ? '#f9c138'
      : 'gray.400'
  const redColor = loading
    ? '#f3243e'
    : percentage > 115
      ? '#f3242e'
      : 'gray.400'

  const toolTipContent =
    greenColor === '#91c138'
      ? 'Great'
      : amberColor === '#f9c138'
        ? 'Good'
        : redColor === '#f3242e'
          ? 'Bad'
          : ''

  const { valueString, unit: formatUnits } = displayScale(value, 1, units)

  const finalUnits = formatUnits ? formatUnits : ''

  return (
    <Box w="100%">
      {isNaN(percentage) ? (
        <Center>-</Center>
      ) : (
        <>
          <Center>
            <Skeleton isLoaded={!loading}>
              {valueString}&thinsp;
              <span dangerouslySetInnerHTML={{ __html: finalUnits }}></span>
            </Skeleton>
          </Center>
          {percentage !== Infinity && (
            <Center>
              <Tooltip label={toolTipContent} fontSize={'md'}>
                <Flex
                  mt={'.5em'}
                  alignSelf={'center'}
                  justifyContent="space-around"
                  gap={'1em'}
                >
                  <Spacer />
                  <Box
                    w="1em"
                    h="1em"
                    borderRadius={'1em'}
                    bgColor={greenColor}
                  />
                  <Box
                    w="1em"
                    h="1em"
                    borderRadius={'1em'}
                    bgColor={amberColor}
                  />
                  <Box
                    w="1em"
                    h="1em"
                    borderRadius={'1em'}
                    bgColor={redColor}
                  />
                  <Spacer />
                </Flex>
              </Tooltip>
            </Center>
          )}
        </>
      )}
    </Box>
  )
}

export default PfTrafficLightBlock
