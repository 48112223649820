import { CloseIcon, HamburgerIcon, LockIcon } from '@chakra-ui/icons'
import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  GridItem,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import {
  Columns,
  Home,
  LogIn,
  LogOut,
  Settings,
  Users,
  //User
} from 'react-feather'

import OptiTwinLogo from '../../assets/logo-white.png'
import { useAuth } from '../../hooks/use-auth'
import { withTranslation } from 'react-i18next'
import { isCurrentUserEndUser } from '../../utils/helpers/basicFunc'

// interface NavBarProps extends WithTranslation {}

export const navbarColor = `linear(to-r, #37238A, #110b29, #110b29)`

const NavBar = () => {
  // const { t: tHook } = useTranslation('nav')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const auth = useAuth()
  const loggedIn = !(auth.user.firstName === '' && auth.user.lastName === '')

  return (
    <>
      <Box as="nav" px={4} bgGradient={navbarColor}>
        <SimpleGrid
          h={24}
          alignItems="centre"
          justifyContent="space-between"
          columns={12}
        >
          <GridItem
            colSpan={[1, 1, 1, 1, 2]}
            display={{ base: 'none', sm: 'none', md: 'flex' }}
          ></GridItem>
          <GridItem colSpan={[7, 5, 3, 3, 2, 2]}>
            <Link as={RouterLink} to={'/'}>
              <Image maxH={95} src={OptiTwinLogo} alt="Opti-Twin Logo" />
            </Link>
          </GridItem>
          <GridItem colSpan={[3, 5, 5, 4]}></GridItem>
          <GridItem colSpan={[2, 2]}>
            <Flex alignItems={'center'} justifyContent="flex-end" height="100%">
              <Stack direction="row" spacing={7}>
                <Menu onOpen={onOpen} onClose={onClose}>
                  <MenuButton
                    as={Button}
                    variant="link"
                    cursor="pointer"
                    minW={0}
                    boxShadow="lg"
                  >
                    {isOpen ? (
                      <CloseIcon
                        w={[6, 8, 8, 10]}
                        h={[6, 8, 8, 10]}
                        color="#eeebfa"
                      />
                    ) : (
                      <HamburgerIcon
                        w={[8, 10, 10, 12]}
                        h={[8, 10, 10, 12]}
                        color="#eeebfa"
                      />
                    )}
                  </MenuButton>
                  {loggedIn ? (
                    <MenuList alignItems="center">
                      <br />
                      <Center>
                        <Avatar
                          size="2xl"
                          src={auth.user.avatar}
                          objectPosition={'bottom'}
                        />
                      </Center>
                      <br />
                      <Center>
                        <Text>
                          {auth.user.firstName} {auth.user.lastName}
                        </Text>
                      </Center>
                      <MenuDivider />
                      {!isCurrentUserEndUser() && (
                        <>
                          <MenuItem>
                            <Link as={RouterLink} to={'system-administration'}>
                              <Center>
                                <Icon as={Settings} pr={1} /> System
                                Administration
                              </Center>
                            </Link>
                          </MenuItem>
                          <MenuItem>
                            <Link as={RouterLink} to={'users'}>
                              <Center>
                                <Icon as={Users} pr={1} /> Users
                              </Center>
                            </Link>
                          </MenuItem>
                          <MenuItem>
                            <Link as={RouterLink} to={'companies'}>
                              <Center>
                                <Icon as={Columns} pr={1} /> Companies
                              </Center>
                            </Link>
                          </MenuItem>
                        </>
                      )}
                      <MenuItem>
                        <Link as={RouterLink} to={'buildings'}>
                          <Center>
                            <Icon as={Home} pr={1} />
                            {/* {tHook('yourBuildings')} */}
                            Your Buildings
                          </Center>
                        </Link>
                      </MenuItem>
                      {/*<MenuItem>
                        <Link as={RouterLink} to={`/user/${auth.user.slug}`}>
                          <Icon as={User} pr={1} size={'lg'} /> {tHook('account')}
                        </Link>
                  </MenuItem>*/}
                      <MenuItem>
                        <Link as={RouterLink} to={'/changePassword'}>
                          <Center>
                            <LockIcon pr={1} /> Change Password
                          </Center>
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link as={RouterLink} to={'/signout'}>
                          <Center>
                            <Icon as={LogOut} pr={1} size="lg" />{' '}
                            {/* {tHook('signOut')} */}
                            Sign out
                          </Center>
                        </Link>
                      </MenuItem>
                    </MenuList>
                  ) : (
                    <MenuList alignItems="center">
                      <br />
                      <MenuItem>
                        <Link as={RouterLink} to={'/'}>
                          <Icon as={Home} pr={1} />
                          {/* {tHook('home')} */}
                          Home
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link as={RouterLink} to={'signin'}>
                          <Icon as={LogIn} pr={1} />
                          {/* {tHook('signIn')} */}
                          Sign in
                        </Link>
                      </MenuItem>
                    </MenuList>
                  )}
                </Menu>
              </Stack>
            </Flex>
          </GridItem>
        </SimpleGrid>
      </Box>
    </>
  )
}

export default withTranslation()(NavBar)
