import {
  Flex,
  Heading,
  Box,
  Input,
  FormControl,
  FormLabel,
  Button,
  useToast,
  Spinner,
} from '@chakra-ui/react'
import { useState } from 'react'
import { Check } from 'react-feather'
import { FieldValues, useForm } from 'react-hook-form'
import {
  CaloryData,
  CategorisedCaloryList,
} from '../../components/unitManagement/categorisedUnitList/categorisedUnitList'
import { GET_GAS_CALORIES } from '../../graphql/API/queries'
import {
  useGasCaloriesCreateByUrlMutation,
  useGasCaloriesDeleteMutation,
  useGasCaloriesGetAllQuery,
} from '../../graphql/generated/graphql'

export const GasCalories = () => {
  const toast = useToast()
  const toastSuccess = (message: string) =>
    toast({
      title: message,
      status: 'success',
      position: 'top',
      duration: 3000,
      isClosable: true,
    })
  const toastFail = (message: string) =>
    toast({
      title: message,
      status: 'error',
      position: 'top',
      duration: 3000,
      isClosable: true,
    })

  const { handleSubmit, register } = useForm()

  const [sendCaloriesUrl, { loading: awaitingCreate }] =
    useGasCaloriesCreateByUrlMutation()
  const [sendCaloriesDelete] = useGasCaloriesDeleteMutation()

  const [getDeletingIds, setDeletingIds] = useState<string[]>([])

  const { data, loading } = useGasCaloriesGetAllQuery()
  const unsortedData = data?.gasCaloriesGetAll

  const submitUrl = (values: FieldValues) => {
    sendCaloriesUrl({
      variables: {
        where: { url: values.url },
      },
      refetchQueries: [
        {
          query: GET_GAS_CALORIES,
        },
      ],
      onCompleted: () =>
        toastSuccess('Successfully retrieved new gas calorie data'),
      onError: (error) => toastFail(error.message),
    })
  }

  const submitCarbonDelete = (id: string) => {
    setDeletingIds([...getDeletingIds, id])
    sendCaloriesDelete({
      variables: {
        data: {
          id,
        },
      },
      refetchQueries: [{ query: GET_GAS_CALORIES }],
      onCompleted: () => {
        setDeletingIds(getDeletingIds.filter((item) => item !== id))
        toastSuccess('Successfully deleted gas carbon data')
      },
      onError: (error) => {
        setDeletingIds(getDeletingIds.filter((item) => item !== id))
        toastFail(error.message)
      },
    })
  }

  // Sort the data for the table
  const sortedData: CaloryData = {}
  if (unsortedData) {
    for (const datum of unsortedData) {
      if (!(datum.country in sortedData)) {
        sortedData[datum.country] = {}
      }
      if (!(datum.region in sortedData[datum.country])) {
        sortedData[datum.country][datum.region] = []
      }
      sortedData[datum.country][datum.region].push({
        id: datum.id,
        date: new Date(datum.date),
        value: datum.value,
      })
    }
  }
  for (const country in sortedData) {
    for (const region in sortedData[country]) {
      sortedData[country][region].sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
      )
    }
  }

  return (
    <>
      <form
        style={{ display: 'flex', gap: '8px', alignItems: 'end' }}
        onSubmit={handleSubmit(submitUrl)}
      >
        <FormControl>
          <FormLabel>Add Gas Calorie Data by URL</FormLabel>
          <Input
            type={'text'}
            placeholder={
              'https://data.nationalgas.com/api/find-gas-data-download...'
            }
            {...register('url')}
          />
        </FormControl>
        {awaitingCreate ? (
          <Box>
            <Spinner size={'lg'} verticalAlign={'center'} />
          </Box>
        ) : (
          <Button leftIcon={<Check />} iconSpacing={0} type={'submit'} />
        )}
      </form>

      <Box paddingTop={4}>
        <Flex flexDirection="column" gap={4}>
          <Heading paddingTop={8}>View Data</Heading>
          {loading ? (
            <Spinner size={'xl'} alignSelf={'center'} />
          ) : (
            <CategorisedCaloryList
              data={sortedData}
              getDeletingIds={getDeletingIds}
              submitDelete={submitCarbonDelete}
            />
          )}
        </Flex>
      </Box>
    </>
  )
}
