import { Box, Text, useBreakpointValue, useMediaQuery } from '@chakra-ui/react'
import { QRCodeSVG } from 'qrcode.react'

import { mediaQueries } from '../../../utils/mediaQueries/mediaQueries'

export type InfoProps = {
  qrCode?: string | undefined
}

const InfoTile = ({ qrCode }: InfoProps) => {
  const [is4k, is1440p] = useMediaQuery(mediaQueries)
  const qrSize = useBreakpointValue({
    base: 100,
    md: 120,
    lg: 80,
    xl: 120,
    '2xl': is1440p ? 200 : is4k ? 310 : 120,
  })
  const infoTileMessage = qrCode
    ? 'For more information scan the QR code to visit our website'
    : "As we fine tune the performance of this building we'll use this space to keep track of how well we've done since we started measuring the building"
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={{ base: 'space-between', lg: 'center' }}
      height={'100%'}
    >
      <Box position={'relative'}>
        {qrCode && (
          <QRCodeSVG
            value={qrCode}
            size={qrSize}
            bgColor={'white'}
            fgColor={'black'}
            level={'L'}
            includeMargin={true}
          />
        )}
      </Box>
      <Text
        fontSize={{
          base: '3vw',
          md: '2.5vw',
          lg: '12px',
          xl: '1.3vw',
          '2xl': is1440p ? '30px' : is4k ? '48px' : '22px',
        }}
        color={'white'}
        ml={{ base: 2, '2xl': is1440p ? '30px' : is4k ? '30px' : '22px' }}
      >
        {infoTileMessage}
      </Text>
    </Box>
  )
}

export default InfoTile
