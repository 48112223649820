import { Box, Flex, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

type PfNormRadioProps = {
  value: string
  onChange: (value: string) => void
  preferredUnits: string
  dataPeriod: string
}

const PfNormRadio = ({
  value,
  onChange,
  preferredUnits,
  dataPeriod,
}: PfNormRadioProps) => {
  const { t } = useTranslation(['portfolio'])

  const displayDataPeriod = (dataPeriod: string) => {
    switch (dataPeriod) {
      case 'daily':
        return 'Day'
      case 'weekly':
        return 'Week'
      case 'yearly':
        return 'Year'
      default:
        return 'Month'
    }
  }

  return (
    <RadioGroup
      defaultValue="total"
      size="sm"
      onChange={(value) => {
        onChange(value)
      }}
      value={value}
    >
      <Stack spacing={2} direction="row">
        <Radio value="total" spacing={1}>
          <Text fontSize="xs">
            {t('total')} / {displayDataPeriod(dataPeriod).toLocaleUpperCase()}
          </Text>
        </Radio>
        <Radio value="per" spacing={1}>
          <Flex fontSize="xs">
            {t('per')} &nbsp;{' '}
            <Flex textTransform={'lowercase'}>
              {preferredUnits === 'sqm' ? (
                <>
                  m<sup>2</sup>
                </>
              ) : (
                'sqft'
              )}
              &nbsp; / &nbsp;
              <Box>
                <Text textTransform={'uppercase'}>
                  {displayDataPeriod(dataPeriod).toLocaleUpperCase()}
                </Text>
              </Box>
            </Flex>
          </Flex>
        </Radio>
      </Stack>
    </RadioGroup>
  )
}

export default PfNormRadio
