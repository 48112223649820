import { createIcon } from '@chakra-ui/react'

const Info = createIcon({
  displayName: 'Info',
  viewBox: '0 0 32.034 32.034',
  path: (
    <g id="g3118">
      <circle
        cx="16.017"
        cy="16.017"
        r="16.017"
        id="circle3110"
        style={{ fill: '#f2253e' }}
      />
      <g id="g3116">
        <path
          d="m 15.9060,5.6561 c 1.911,0 3.047,1.551 3.047,2.826 0,1.523 -1.08,2.798 -3.047,2.798 -1.745,0 -2.992,-1.468 -2.992,-2.798 0,-1.275 1.053,-2.826 2.992,-2.826 z"
          id="path3112"
          style={{ fill: '#ffffff' }}
        />
        <polygon
          points="12.1380,22.7491 13.3020,22.7491 13.3020,16.1281 12.1380,16.1281 12.1380,12.5271 18.5650,12.5271 18.5650,22.7491 19.8950,22.7491 19.8950,26.3781 12.1380,26.3781"
          id="polygon3114"
          style={{ fill: '#ffffff' }}
        />
      </g>
    </g>
  ),
})

export default Info
