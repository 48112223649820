import { createIcon } from '@chakra-ui/react'

const Renewables = createIcon({
  displayName: 'RenewablesGeneration',
  viewBox: '0 0 270 165',
  path: (
    <g id="g32" transform="translate(-45.08272,-94.836996)">
      <g id="g8">
        <path
          fill="currentColor"
          d="M 308.31,222.006 H 52.213 c -2.292,0 -4.445,-1.102 -5.785,-2.962 -1.34,-1.86 -1.704,-4.251 -0.979,-6.425 L 82.854,100.498 c 0.971,-2.91 3.695,-4.874 6.764,-4.874 h 181.288 c 3.068,0 5.792,1.964 6.764,4.874 l 37.404,112.121 c 0.726,2.174 0.361,4.565 -0.979,6.425 -1.341,1.86 -3.493,2.962 -5.785,2.962 z M 62.108,207.746 H 298.415 L 265.767,109.885 H 94.755 Z"
          id="path6"
        />
      </g>
      <g id="g18">
        <g id="g12">
          <path
            fill="currentColor"
            d="m 129.008,222.008 c -0.462,0 -0.93,-0.046 -1.401,-0.14 -3.862,-0.769 -6.369,-4.523 -5.6,-8.385 l 22.331,-112.121 c 0.769,-3.862 4.526,-6.368 8.386,-5.6 3.862,0.769 6.37,4.524 5.601,8.387 L 135.993,216.27 c -0.676,3.391 -3.654,5.738 -6.985,5.738 z"
            id="path10"
          />
        </g>
        <g id="g16">
          <path
            fill="currentColor"
            d="m 231.515,222.008 c -3.331,0 -6.31,-2.347 -6.985,-5.739 L 202.199,104.147 c -0.769,-3.862 1.738,-7.616 5.6,-8.386 3.862,-0.767 7.616,1.738 8.386,5.6 l 22.33,112.122 c 0.77,3.862 -1.738,7.616 -5.6,8.386 -0.47,0.093 -0.938,0.139 -1.4,0.139 z"
            id="path14"
          />
        </g>
      </g>
      <g id="g22">
        <path
          fill="currentColor"
          d="M 286.03,160.704 H 74.491 c -3.937,0 -7.13,-3.192 -7.13,-7.13 0,-3.937 3.192,-7.131 7.13,-7.131 H 286.03 c 3.937,0 7.13,3.193 7.13,7.131 0,3.939 -3.192,7.13 -7.13,7.13 z"
          id="path20"
        />
      </g>
      <g id="g26">
        <path
          fill="currentColor"
          d="m 203.05,259.837 h -45.578 c -3.938,0 -7.13,-3.193 -7.13,-7.13 v -37.83 c 0,-3.938 3.192,-7.131 7.13,-7.131 h 45.578 c 3.937,0 7.131,3.192 7.131,7.131 v 37.83 c 0,3.937 -3.194,7.13 -7.131,7.13 z m -38.448,-14.261 h 31.317 v -23.57 h -31.317 z"
          id="path24"
        />
      </g>
      <g id="g30">
        <path
          fill="currentColor"
          d="M 246.576,259.837 H 113.945 c -3.938,0 -7.13,-3.193 -7.13,-7.13 0,-3.939 3.192,-7.131 7.13,-7.131 h 132.631 c 3.939,0 7.131,3.192 7.131,7.131 0,3.937 -3.192,7.13 -7.131,7.13 z"
          id="path28"
        />
      </g>
    </g>
  ),
})

export default Renewables
