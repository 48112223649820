import { ChevronRightIcon } from '@chakra-ui/icons'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react'

export type Links = {
  text: string
  route: string
}

export type BreadcrumbsProps = {
  links: Links[]
}

const Breadcrumbs = ({ links }: BreadcrumbsProps) => {
  return (
    <Breadcrumb
      p={'0.25% 0%'}
      fontSize={{ base: '13px', md: '16px' }}
      separator={<ChevronRightIcon color="gray.500" />}
    >
      {links.map((datum: Links, i: number) => (
        <BreadcrumbItem key={i} isCurrentPage={i === links.length - 1}>
          <BreadcrumbLink href={datum.route}>{datum.text}</BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  )
}

export default Breadcrumbs
