import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import FormDrawer from '../../../components/formDrawer/formDrawer'
import InfoAlert from '../../../components/infoAlert/infoAlert'
import {
  CompanyRelationship,
  RemoveCompanyRelationshipInput,
  UpdateCompanyRelationshipInput,
  useRemoveCompanyRelationshipMutation,
  useUpdateCompanyRelationshipMutation,
} from '../../../graphql/generated/graphql'
import { useAuth } from '../../../hooks/use-auth'
import {
  camelCaseToNormal,
  isCurrentUserStaff,
} from '../../../utils/helpers/basicFunc'
import { mapKeyValue } from '../../../utils/pageDrawerInputLists/inputLists'
import { tableHeaders } from '../../../utils/tableDataConstruct/tableDataConstruct'
import { SelectOptionProps } from '../../formElements/formElements'
import { FormDataType } from '../../../components/formDrawer/formDrawer'
import { GET_BUILDING } from '../../../graphql/API/queries'

type BuildingRelationTableProps = {
  companyRelationships: CompanyRelationship[]
  companySelectOptions: SelectOptionProps[]
  onRelationDelete: (arg: CompanyRelationship) => void
  onRelationUpdate: (arg: CompanyRelationship) => void
  buildingSlug: string
}

const BuildingRelationTable = ({
  companyRelationships,
  companySelectOptions,
  onRelationDelete,
  onRelationUpdate,
  buildingSlug,
}: BuildingRelationTableProps) => {
  const Auth = useAuth(),
    { user } = Auth,
    { isStaff } = user,
    havePermissions = isStaff ?? isCurrentUserStaff()

  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false)
  const [clickedElement, setClickedElement] = useState<CompanyRelationship>()
  const [editDrawerVisibility, setEditDrawerModalVisibility] = useState(false)

  const toast = useToast()

  const [removeRelation, { loading: deleteLoader }] =
    useRemoveCompanyRelationshipMutation()
  const [updateRelation, { loading: updateLoader }] =
    useUpdateCompanyRelationshipMutation()

  const onDelete = () => {
    const data = {
      buildingId: clickedElement?.buildingId,
      companyId: clickedElement?.companyId,
    } as RemoveCompanyRelationshipInput
    removeRelation({
      variables: { data },
      refetchQueries: [
        { query: GET_BUILDING, variables: { where: { slug: buildingSlug } } },
      ],
      onCompleted: () => {
        onRelationDelete(clickedElement as CompanyRelationship)
        setDeleteModalVisibility(false)
        toast({
          title: 'Deleted successfully',
          status: 'success',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
      },
      onError: (err) => {
        toast({
          title: err.message,
          status: 'error',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
      },
    })
  }

  const onUpdate = (data: FormDataType) => {
    const updateRelationData = {
      ...data,
      buildingId: clickedElement?.buildingId,
      companyId: clickedElement?.companyId,
    } as UpdateCompanyRelationshipInput
    updateRelation({
      variables: { data: updateRelationData },
      refetchQueries: [
        { query: GET_BUILDING, variables: { where: { slug: buildingSlug } } },
      ],
      onCompleted: (res) => {
        onRelationUpdate(res.updateCompanyRelationship!)
        toast({
          title: 'Relation updated successfully',
          status: 'success',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
        setEditDrawerModalVisibility(false)
      },
      onError: (err) => {
        toast({
          title: err.message,
          status: 'error',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
      },
    })
  }

  const onActionClick = ({
    action,
    element,
  }: {
    action: string
    element: CompanyRelationship
  }) => {
    setClickedElement(element)
    if (action === 'edit') {
      setEditDrawerModalVisibility(true)
    } else if (action === 'delete') {
      setDeleteModalVisibility(true)
    }
  }

  return (
    <>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              {tableHeaders['companyRelation'].map((datum: string) => (
                <Th key={datum}>
                  {datum === 'companyId' ? 'Company' : camelCaseToNormal(datum)}
                </Th>
              ))}
              <Th pt={'1.5%'} pb={'1.5%'} rowSpan={2}>
                Action
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {companyRelationships?.map(
              (
                values: CompanyRelationship & { [key: string]: string },
                i: number,
              ) => (
                <Tr key={i}>
                  {tableHeaders['companyRelation'].map((datum) => (
                    <Td key={datum} textTransform={'capitalize'}>
                      {datum === 'companyId'
                        ? companySelectOptions?.find(
                            (option) => option.value === values?.[datum],
                          )?.text
                        : values?.[datum]}
                    </Td>
                  ))}
                  <Td>
                    {havePermissions && (
                      <>
                        <Button
                          variant={'outline'}
                          colorScheme={'purple.500'}
                          onClick={() =>
                            onActionClick({ action: 'edit', element: values })
                          }
                        >
                          Edit
                        </Button>
                        <Button
                          colorScheme={'red'}
                          ml={4}
                          onClick={() =>
                            onActionClick({ action: 'delete', element: values })
                          }
                        >
                          Delete
                        </Button>
                      </>
                    )}
                  </Td>
                </Tr>
              ),
            )}
          </Tbody>
        </Table>
      </TableContainer>
      <InfoAlert
        isOpen={deleteModalVisibility}
        onClose={() => setDeleteModalVisibility(false)}
        onSubmit={onDelete}
        headerText={`Delete ${clickedElement?.buildingName}?`}
        bodyText={"Are you sure? You can't undo this action afterwards."}
        buttonText={'Delete'}
        loading={deleteLoader}
      />
      <FormDrawer
        openedFrom={'Relation'}
        isCreate={false}
        isOpen={editDrawerVisibility}
        onClose={() => setEditDrawerModalVisibility(false)}
        loading={updateLoader}
        inputElements={mapKeyValue({
          page: 'companyRelationEdit',
          data: clickedElement!,
        })}
        onDrawerFormSubmit={onUpdate}
      />
    </>
  )
}

export default BuildingRelationTable

//  <form onSubmit={handleSubmit(onSubmit)}>
//           <ModalBody pb={6}>
//             <FormControl>
//               <FormLabel>Company</FormLabel>
//               <Input
//                 {...register('text', {
//                   required: 'requiredItem',
//                 })}
//                 placeholder="Select Company"
//               />
//             </FormControl>

//             <FormControl>
//               <FormLabel>Company Reference Name</FormLabel>
//               <Input
//                 {...register('text', {
//                   required: 'requiredItem',
//                 })}
//                 // placeholder="First name"
//               />
//             </FormControl>
//             <FormControl>
//               <FormLabel>Company Reference</FormLabel>
//               <Input
//                 {...register('text', {
//                   required: 'requiredItem',
//                 })}
//                 // placeholder="First name"
//               />
//             </FormControl>
//             <FormControl>
//               <FormLabel>Building Name</FormLabel>
//               <Input
//                 {...register('text', {
//                   required: 'requiredItem',
//                 })}
//                 // placeholder="First name"
//               />
//             </FormControl>
//             <FormControl>
//               <FormLabel>Relationship Type</FormLabel>
//               <Input
//                 {...register('text', {
//                   required: 'requiredItem',
//                 })}
//                 placeholder="Select Relationship Type"
//               />
//             </FormControl>
//           </ModalBody>

//           <ModalFooter>
//             <Button colorScheme="blue" mr={3} type={'submit'}>
//               Save
//             </Button>
//             <Button onClick={onClose}>Cancel</Button>
//           </ModalFooter>
//         </form>
