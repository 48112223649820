import {
  Box,
  Button,
  Flex,
  Heading,
  Select,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { useState } from 'react'
import { countryCodes } from '../../../utils/locationMaps/countryCodes'
import { gasRegions } from '../../../utils/locationMaps/gasRegions'

export type CaloryData = {
  [country: string]: {
    [region: string]: {
      id: string
      date: Date
      value: number
    }[]
  }
}

export type CarbonData = {
  [country: string]: {
    id: string
    date: Date
    value: number
  }[]
}

export const CategorisedCaloryList = (props: {
  data: CaloryData
  getDeletingIds: string[]
  submitDelete: (id: string) => void
}) => {
  const data = props.data

  const [getCountry, setCountry] = useState<string>('GB')
  const [getRegion, setRegion] = useState<string>('NW')

  const createRows = (): JSX.Element => {
    if (data[getCountry] && data[getCountry][getRegion]) {
      const rows: JSX.Element[] = []
      for (const datum of data[getCountry][getRegion]) {
        if (!datum) {
          continue
        }
        rows.push(
          <Tr>
            <Td>{new Date(datum.date).toISOString().split('T')[0]}</Td>
            <Td>{datum.value}</Td>
            <Td>
              {props.getDeletingIds.includes(datum.id) ? (
                <Spinner textAlign={'center'} size="lg" marginLeft={'16px'} />
              ) : (
                <Button
                  size={'sm'}
                  colorScheme={'red'}
                  onClick={(event) => props.submitDelete(datum.id)}
                  isDisabled={props.getDeletingIds.includes(datum.id)}
                >
                  Delete
                </Button>
              )}
            </Td>
          </Tr>,
        )
      }
      return <>{rows}</>
    }
    return <></>
  }

  return (
    <>
      <Flex width={'100%'} gap={4} alignItems="start">
        <Box width={'200px'}>
          <Heading fontSize={'larger'} marginBottom={2}>
            Country
          </Heading>
          <Select onChange={(event) => setCountry(event.target.value)}>
            {Object.keys(data).map((country) => (
              <option value={country}>
                {countryCodes.find((item) => item.code === country)?.name ??
                  country}
              </option>
            ))}
          </Select>
        </Box>
        <Box width={'200px'}>
          <Heading fontSize={'larger'} marginBottom={2}>
            Region
          </Heading>
          <Select onChange={(event) => setRegion(event.target.value)}>
            {getCountry in data
              ? Object.keys(data[getCountry]).map((region) => (
                  <option value={region}>
                    {gasRegions.find((item) => item.code === region)?.name ??
                      region}
                  </option>
                ))
              : undefined}
          </Select>
        </Box>
      </Flex>
      <Box height={'500px'} overflowY={'auto'}>
        <Table variant={'simple'}>
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>Calorific Value</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>{createRows()}</Tbody>
        </Table>
      </Box>
    </>
  )
}

export const CategorisedCarbonList = (props: {
  data: CarbonData
  getDeletingIds: string[]
  submitDelete: (id: string) => void
}) => {
  const data = props.data

  const [getCountry, setCountry] = useState<string>('GB')

  const createRows = (): JSX.Element => {
    if (data[getCountry]) {
      const rows: JSX.Element[] = []
      for (const datum of data[getCountry]) {
        if (!datum) {
          continue
        }
        rows.push(
          <Tr>
            <Td>{new Date(datum.date).toISOString().split('T')[0]}</Td>
            <Td>{datum.value}</Td>
            <Td>
              {props.getDeletingIds.includes(datum.id) ? (
                <Spinner textAlign={'center'} size="lg" marginLeft={'16px'} />
              ) : (
                <Button
                  size={'sm'}
                  colorScheme={'red'}
                  onClick={(event) => props.submitDelete(datum.id)}
                  isDisabled={props.getDeletingIds.includes(datum.id)}
                >
                  Delete
                </Button>
              )}
            </Td>
          </Tr>,
        )
      }
      return <>{rows}</>
    }
    return <></>
  }

  return (
    <>
      <Flex width={'100%'} gap={4} alignItems="start">
        <Box width={'200px'}>
          <Heading fontSize={'larger'} marginBottom={2}>
            Country
          </Heading>
          <Select onChange={(event) => setCountry(event.target.value)}>
            {Object.keys(data).map((country) => (
              <option value={country}>
                {countryCodes.find((item) => item.code === country)?.name ??
                  country}
              </option>
            ))}
          </Select>
        </Box>
      </Flex>
      <Box height={'500px'} overflowY={'auto'}>
        <Table variant={'simple'} maxHeight={'500px'} overflow={'scroll'}>
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>Value</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>{createRows()}</Tbody>
        </Table>
      </Box>
    </>
  )
}
