import {
  Button,
  Center,
  ComponentWithAs,
  GridItem,
  Heading,
  IconProps,
  SimpleGrid,
  useToast,
  Box,
  Text,
  RadioGroup,
  Radio,
  Flex,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs'
import FormDrawer, {
  FormDataType,
} from '../../components/formDrawer/formDrawer'
import PfHeadline from '../../components/pfHeadline/pfHeadline'
import PfTable, { BuildingProps } from '../../components/pfTable/pfTable'
import Hero from '../../elements/hero'
import carbonIcon from '../../elements/icons/co2'
import renewablesIcon from '../../elements/icons/renewables'
import waterUsageIcon from '../../elements/icons/waterUsage'
import wholeBuildingIcon from '../../elements/icons/wholeBuilding'
import Title from '../../elements/title'
import {
  BuildingGetManyQuery,
  Company,
  PortfolioTypes,
  useBuildingGetManyQuery,
  useCompaniesQuery,
  useCompanyLazyQuery,
  BuildingCreateInput,
  useBuildingCreateMutation,
  Exact,
  BuildingWhereUniqueSearchManyInput,
} from '../../graphql/generated/graphql'
import { SelectOptionProps } from '../../elements/formElements/formElements'
import { useAuth } from '../../hooks/use-auth'
import { isCurrentUserStaff } from '../../utils/helpers/basicFunc'
import { allDrawerInputs } from '../../utils/pageDrawerInputLists/inputLists'
import { InspectorChannel } from './issues'
import { useOnboarding } from '../../onboardingContextProvider'
import { BUILDING_SLUG, FLOW_DEMO_TOUR_FINISHED } from '../../constants'

export interface HeadlineItems {
  Icon: ComponentWithAs<'svg', IconProps>
  label: string
  value: number
  target: number
  units: string
  description: string
  upIsBetter: boolean
}

export type buildingGetManyWhereType = { where: { dataPeriod: string | null } }

export type UploadConfigFormValues = {
  connectionType?: string | null
  host?: string | null
  port?: number | null
  username?: string | null
  password?: string | null
  protocol?: string | null
  useDefaultServer?: boolean | null
  parser?: string | null
  topic?: string | null
  id?: string | null
  buildingId?: string | null
}

export interface BuildingsProps {
  loading: boolean
  company:
    | {
        name: string
        portfolioImage?: string | null
        slug: string
      }
    | undefined
  headlineItems: HeadlineItems[] | undefined
  buildings: BuildingProps[] | undefined
  refetchBuildingGetMany: (
    arg: Partial<Exact<{ where: BuildingWhereUniqueSearchManyInput }>>,
  ) => void
  dataPeriod: string
}

const breadcrumbLinks = [
  { text: 'Home', route: '/' },
  { text: 'Buildings', route: '/buildings' },
]

export const defaultUploadConfig: UploadConfigFormValues = {
  connectionType: undefined,
  useDefaultServer: true,
  username: undefined,
  password: undefined,
  protocol: undefined,
  host: undefined,
  port: undefined,
  parser: undefined,
}

export const Buildings = ({
  loading,
  company,
  headlineItems,
  buildings,
  refetchBuildingGetMany,
  dataPeriod,
}: BuildingsProps) => {
  const toast = useToast()
  const [isOpen, setDrawerVisible] = useState(false)

  const { data: companyData } = useCompaniesQuery({
    variables: { where: {} },
  })
  const [companySelectOptions, setCompanyOptions] = useState<
    SelectOptionProps[] | undefined
  >([])
  const [uploadConfigs, setUploadConfigs] = useState<UploadConfigFormValues[]>(
    [],
  )
  const Auth = useAuth(),
    { user } = Auth,
    { isStaff } = user,
    havePermissions = isStaff ?? isCurrentUserStaff()

  const [createBuilding, { loading: createBuildingLoader }] =
    useBuildingCreateMutation()

  useEffect(() => {
    let selectOptions: SelectOptionProps[] | undefined = []
    selectOptions = companyData?.companies?.map(
      (company: Partial<Company>) => ({
        text: company.name!,
        value: company.id!,
      }),
    )
    setCompanyOptions(selectOptions)
  }, [companyData])

  const onClose = () => {
    setDrawerVisible(false)
  }

  const onDrawerFormSubmit = (formData: FormDataType) => {
    const {
      city,
      companyId,
      companyRelationshipType,
      gasRegion,
      country,
      epcRating,
      breeamRating,
      breeamScore,
      constructionYear,
      firstLine,
      picture,
      postcode,
      projectRef,
      region,
      slug,
      secondLine,
      name,
      defaultName,
      epcUrl,
      dashboardStyle,
      floorArea,
    } = formData
    const data = {
      name,
      defaultName,
      companyId,
      companyRelationshipType,
      epcRating,
      epcUrl,
      breeamRating,
      breeamScore: breeamScore ? +breeamScore : undefined,
      constructionYear: constructionYear ? +constructionYear : undefined,
      picture,
      dashboardStyle,
      projectRef,
      slug,
      floorArea: floorArea ? +floorArea : undefined,
      thumb: picture,
      // uploadConfig: [],
      hasFileServer: true,
      hasDataServer: true,
      address: {
        city: city ?? '',
        country: country ?? '',
        firstLine: firstLine ?? '',
        secondLine: secondLine ?? '',
        gasRegion: gasRegion ?? '',
        postcode: postcode ?? '',
        region: region ?? '',
      },
      uploadConfig: uploadConfigs,
    }

    createBuilding({
      variables: { data: data as BuildingCreateInput },
      onCompleted: () => {
        setDrawerVisible(false)
        refetchBuildingGetMany({ where: { dataPeriod } })
        toast({
          title: 'Building created successfully',
          status: 'success',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
      },
      onError: (err) => {
        toast({
          title: err.message,
          status: 'error',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
      },
    })
    setUploadConfigs([])
  }
  const mapBuildingsData = buildings?.map((building: BuildingProps) => {
    return {
      id: building.id,
      name: building.name,
      address: building.address,
    }
  })

  const onDataPeriodChange = (e: string) => {
    refetchBuildingGetMany({ where: { dataPeriod: e } })
  }

  const uploadConfigCrud = (
    type: 'add' | 'edit' | 'delete',
    editedConfig?: UploadConfigFormValues[],
  ) => {
    if (type === 'add') {
      const addedConfigs = structuredClone(uploadConfigs)
      addedConfigs.push(defaultUploadConfig)
      setUploadConfigs(addedConfigs)
    } else if ((type === 'edit' || type === 'delete') && editedConfig) {
      setUploadConfigs(editedConfig)
    }
  }

  return (
    <Box padding={{ base: '0 15px', md: 0 }} mb={'5em'}>
      <SimpleGrid columns={12}>
        <GridItem
          colStart={{ base: 1, sm: 1, md: 2, lg: 3 }}
          colSpan={{ base: 12, sm: 12, md: 10, lg: 8 }}
        >
          <Breadcrumbs links={breadcrumbLinks} />
          <Box className="demo_title">
            <Title loading={loading}>{company?.name}</Title>
          </Box>
          <Box className="demo_map">
            <Hero
              mapBuildingsData={mapBuildingsData}
              imageUrl={company?.portfolioImage!}
            />
          </Box>
          <Box className="demo_headline">
            <PfHeadline
              items={headlineItems ?? []}
              loading={loading}
              period={dataPeriod}
            />
          </Box>
          <Box className="demo_radioperiod">
            <Flex
              justifyContent={{ base: 'center', md: 'end' }}
              w={'100%'}
              mt={'1em'}
            >
              <RadioGroup
                value={dataPeriod}
                onChange={(e) => onDataPeriodChange(e)}
              >
                <Center justifyContent={'space-between'} flexWrap={'wrap'}>
                  <Radio value="daily" mr={2}>
                    Daily
                  </Radio>
                  <Radio value="weekly" mr={2}>
                    Weekly
                  </Radio>
                  <Radio value="monthly" mr={2}>
                    Monthly
                  </Radio>
                  <Radio value="yearly">Yearly</Radio>
                </Center>
              </RadioGroup>
            </Flex>
          </Box>

          <Center justifyContent={'space-between'} mt={'1em'} mb={'1em'}>
            <Center>
              <Heading fontSize={{ base: '28px', md: '2.25rem' }}>
                Buildings
              </Heading>
              &nbsp;
              <Text fontSize={{ base: 20 }} color={'grey'} mt={{ md: '4px' }}>
                ({buildings?.length})
              </Text>
            </Center>
            <Box className="demo_createBuilding">
              {havePermissions && (
                <Button onClick={() => setDrawerVisible(true)}>
                  + Create Building
                </Button>
              )}
            </Box>
          </Center>

          <FormDrawer
            openedFrom={'Building'}
            isCreate={true}
            isOpen={isOpen}
            onClose={onClose}
            inputElements={allDrawerInputs['buildingsCreate']}
            onDrawerFormSubmit={onDrawerFormSubmit}
            selectOptions={companySelectOptions}
            loading={createBuildingLoader}
            isSlug={true}
            modalInputs={uploadConfigs}
            modalCrudFunc={uploadConfigCrud}
          />
          <Box className="demo_pftable">
            <PfTable
              buildings={buildings ?? []}
              loading={loading}
              dataPeriod={dataPeriod}
            />
          </Box>
        </GridItem>
      </SimpleGrid>
    </Box>
  )
}

const WrappedBuildings = () => {
  const auth = useAuth()
  const whereVariable: buildingGetManyWhereType = {
    where: { dataPeriod: null },
  }
  const {
    data: buildingsData,
    loading,
    refetch,
  } = useBuildingGetManyQuery({
    variables: whereVariable,
    notifyOnNetworkStatusChange: true,
  })
  const [getCompany, { data: companyData }] = useCompanyLazyQuery()
  const [dataPeriod, setDataPeriod] = useState('monthly')

  const { setRun } = useOnboarding()
  useEffect(() => {
    return () => {
      setRun(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (buildingsData?.buildingGetMany) {
      if (
        localStorage.getItem(`${FLOW_DEMO_TOUR_FINISHED}-${auth.user.id}`) !==
          'TRUE' &&
        buildingsData
      ) {
        localStorage.setItem(
          BUILDING_SLUG,
          buildingsData.buildingGetMany[0].slug,
        )
        setTimeout(() => {
          setRun(true)
        }, 1000)
      }
      getCompany({
        variables: {
          where: { id: auth.user.companyId },
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingsData])

  const refetchBuildingGetMany = (
    variables: Partial<Exact<{ where: BuildingWhereUniqueSearchManyInput }>>,
  ) => {
    refetch(variables)
    setDataPeriod(variables.where?.dataPeriod ?? 'monthly')
  }

  const wholeBuilding = {
    Icon: wholeBuildingIcon,
    label: 'buildingEnergy',
    units: 'kWh',
    description: 'totalEnergyUsed',
    upIsBetter: false,
    value: 0,
    target: 0,
  }

  const renewables = {
    Icon: renewablesIcon,
    label: 'renewables',
    units: 'kWh',
    description: 'totalGenerated',
    upIsBetter: true,
    value: 0,
    target: 0,
  }

  const carbon = {
    Icon: carbonIcon,
    label: 'carbonDioxide',
    units: 'kgCO<sub>2</sub>e',
    description: 'totalEmissions',
    upIsBetter: false,
    value: 0,
    target: 0,
  }

  const water = {
    Icon: waterUsageIcon,
    label: 'water',
    units: 'm<sup>3<sup>',
    description: 'totalWaterUsed',
    upIsBetter: false,
    value: 0,
    target: 0,
  }

  const unknown = {
    Icon: wholeBuildingIcon,
    label: 'Unknown',
    type: 'unknown',
    units: '',
    description: 'unknown total',
    upIsBetter: false,
    value: 0,
    target: 0,
  }

  const buildings: BuildingGetManyQuery['buildingGetMany'] | undefined =
    buildingsData?.buildingGetMany
  const portfolios: Partial<PortfolioTypes>[] = (buildings || [])?.map(
    (building) => building?.portfolio! as Partial<PortfolioTypes>,
  )

  for (const portfolio of portfolios) {
    wholeBuilding.value += portfolio?.wholeBuilding?.measurement ?? 0
    wholeBuilding.target += portfolio?.wholeBuilding?.target ?? 0

    carbon.value += portfolio?.carbonDioxide?.measurement ?? 0
    carbon.target += portfolio?.carbonDioxide?.target ?? 0

    water.value += portfolio?.waterUsage?.measurement ?? 0
    water.target += portfolio?.waterUsage?.target ?? 0

    renewables.value += portfolio?.renewables?.measurement ?? 0
    renewables.target += portfolio?.renewables?.target ?? 0
  }

  const headlineItems: HeadlineItems[] = [
    wholeBuilding,
    renewables,
    carbon,
    water,
    unknown,
  ]

  const buildingsWithTargets = buildings?.map((building) => {
    const findCompanyRelation = building?.companyRelationships.find(
      (relationship) => relationship.companyId === auth.user.companyId,
    )
    const channels: InspectorChannel[] = []
    for (const channel of building.channelIssues) {
      if (!channel || !channel.channelId) {
        continue
      }
      channels.push({
        channelId: channel.channelId,
        isImportant: channel.isImportant,
        lastSeen: channel.lastSeen ?? null,
        notTransmitting: channel.notTransmitting,
        notCumulative: channel.notCumulative,
        includesZeroes: channel.includesZeroes,
      })
    }
    return {
      id: building.id,
      defaultName: building.defaultName,
      name: building.name,
      slug: building.slug,
      thumbnail: building.picture,
      epcRating: building.epcRating,
      epcUrl: building.epcUrl,
      floorArea: building.floorArea,
      floorAreaDisplay: 0,
      picture: building.picture,
      projectRef: building.projectRef,
      company: findCompanyRelation?.companyId ?? '',
      address: building.address,
      lastSeen: building.lastSeen ?? null,
      buildingTransmitting: building.buildingTransmitting,
      channelIssues: channels,
      tlData: [
        {
          type: 'energy',
          target: building.portfolio.wholeBuilding.target ?? 0,
          value: building.portfolio.wholeBuilding.measurement ?? 0,
          units: 'kWh',
        },
        {
          type: 'renewables',
          target: building.portfolio.renewables.target ?? 0,
          value: building.portfolio.renewables.measurement ?? 0,
          units: 'kWh',
        },
        {
          type: 'carbon',
          target: building.portfolio.carbonDioxide.target ?? 0,
          value: building.portfolio.carbonDioxide.measurement ?? 0,
          units: 'kgCO<sub>2</sub>e',
        },
      ],
    } as BuildingProps
  })

  return (
    <Buildings
      loading={loading}
      company={companyData?.company}
      headlineItems={headlineItems}
      buildings={buildingsWithTargets}
      refetchBuildingGetMany={refetchBuildingGetMany}
      dataPeriod={dataPeriod}
    />
  )
}

export default WrappedBuildings
