import {
  AddIcon,
  CloseIcon,
  SettingsIcon,
  ViewIcon,
  ViewOffIcon,
} from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import { UploadConfigFormValues } from '../../Pages/buildings/buildings'

type UploadConfigProps = {
  modalCrudFunc: (
    type: 'edit' | 'delete' | 'add',
    editedConfig?: UploadConfigFormValues[],
  ) => void
  modalInputs?: UploadConfigFormValues[]
}

const forbiddenPattern = /[(){}[\]|`¬¦! "£$%^&*"<>:;#~_\-+=,@]/

const UploadConfigModal = (uploadConfigProps: UploadConfigProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { modalInputs: uploadConfigs, modalCrudFunc } = uploadConfigProps
  const [hidePassword, setHidePassword] = useState<boolean>(true)

  const toast = useToast()
  const toastFail = (message: string) =>
    toast({
      title: message,
      status: 'error',
      position: 'top',
      duration: 3000,
      isClosable: true,
    })

  const onFormChange = (
    e: string | number | boolean,
    fieldName: keyof UploadConfigFormValues,
    changedIndex: number,
  ) => {
    const containsForbiddenChar =
      typeof e === 'string' && forbiddenPattern.test(e)

    if (containsForbiddenChar) {
      toastFail(
        `Special character ${e.match(forbiddenPattern)} is not allowed in the field ${fieldName}`,
      )
      return
    }

    let updatedUploadConfigs = structuredClone(uploadConfigs)
    updatedUploadConfigs = updatedUploadConfigs?.map((uploadConfig, i) => {
      const id = uploadConfig.id
      if (i === changedIndex) {
        if (
          (uploadConfig.connectionType === 'mqtt' && e === 'ftp') ||
          (uploadConfig.connectionType === 'ftp' && e !== 'mqtt')
        ) {
          const returnObj = {
            connectionType: 'ftp',
            [fieldName]: e,
            useDefaultServer: true,
          }
          return id ? { ...returnObj, id } : { ...returnObj, create: true }
        } else {
          if (
            (fieldName === 'useDefaultServer' && e === true) ||
            uploadConfig.useDefaultServer === undefined
          ) {
            const returnObj = {
              connectionType: 'mqtt',
              useDefaultServer: true,
            }
            return id ? { ...returnObj, id } : { ...returnObj, create: true }
          } else {
            const returnObj = {
              ...uploadConfig,
              [fieldName]: e,
              port:
                fieldName === 'port'
                  ? +e
                  : uploadConfig.port
                    ? +uploadConfig.port
                    : undefined,
              parser: undefined,
            }
            return id ? { ...returnObj, id } : { ...returnObj, create: true }
          }
        }
      }
      return uploadConfig
    })
    if (updatedUploadConfigs) {
      modalCrudFunc('edit', updatedUploadConfigs)
    }
  }

  const onClickDone = () => {
    if (checkForRequiredFields().some((bool) => !bool)) {
      toastFail('Please fill the required fields')
    } else {
      onClose()
    }
  }

  const checkForRequiredFields = () => {
    const checkUploadConfigs = structuredClone(uploadConfigs)
    let allRequiredFieldsAreFilled: boolean[] = []

    checkUploadConfigs?.forEach((uploadConfig) => {
      const {
        connectionType,
        parser,
        useDefaultServer,
        username,
        password,
        protocol,
        host,
        port,
        topic,
      } = uploadConfig
      if (connectionType === 'ftp') {
        allRequiredFieldsAreFilled.push(!!parser)
      } else if (connectionType === 'mqtt' && !useDefaultServer) {
        const requiredVariables = [
          username,
          password,
          protocol,
          host,
          port,
          topic,
        ]
        allRequiredFieldsAreFilled.push(
          !(
            requiredVariables.includes(undefined) ||
            requiredVariables.includes('') ||
            requiredVariables.includes(null)
          ),
        )
      } else if (!connectionType) {
        allRequiredFieldsAreFilled.push(false)
      }
    })

    return allRequiredFieldsAreFilled
  }

  const onDeleteClick = (i: number) => {
    let updatedConfigs = structuredClone(uploadConfigs)
    updatedConfigs?.splice(i, 1)
    modalCrudFunc('delete', updatedConfigs)
  }

  return (
    <>
      <Button
        leftIcon={<SettingsIcon />}
        onClick={onOpen}
        colorScheme={'blue'}
        variant="outline"
      >
        Configure...
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent bg={'white'}>
          <ModalHeader>
            <Flex justifyContent={'space-between'} alignItems={'center'}>
              Upload Config{' '}
              {!!uploadConfigs?.length && (
                <Button
                  colorScheme="blue"
                  mr={6}
                  size={'sm'}
                  variant={'outline'}
                  onClick={() => modalCrudFunc('add')}
                  leftIcon={<AddIcon />}
                >
                  Add
                </Button>
              )}
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {uploadConfigs?.length ? (
              uploadConfigs?.map((uploadConfig, i) => (
                <Box
                  padding={3}
                  border={'1px solid #3182ce'}
                  borderRadius={5}
                  mb={5}
                  key={i}
                >
                  <Flex justifyContent={'right'}>
                    <CloseIcon
                      cursor={'pointer'}
                      onClick={() => onDeleteClick(i)}
                    />
                  </Flex>
                  <Flex mb={5}>
                    <FormControl id={`connectionType-${i}`}>
                      <FormLabel>Connection Type</FormLabel>
                      <Select
                        value={uploadConfig.connectionType!}
                        onChange={(e) =>
                          onFormChange(e.target.value, 'connectionType', i)
                        }
                      >
                        <option value={undefined}>-</option>
                        <option value={'ftp'}>FTP</option>
                        <option value={'mqtt'}>MQTT</option>
                      </Select>
                    </FormControl>
                    {uploadConfig.connectionType === 'mqtt' && (
                      <FormControl id={`useDefaultServer-${i}`} ml={3}>
                        <FormLabel>Use Default Server</FormLabel>
                        <Switch
                          isChecked={!!uploadConfig.useDefaultServer}
                          onChange={(e) =>
                            onFormChange(
                              e.target.checked,
                              'useDefaultServer',
                              i,
                            )
                          }
                        />
                      </FormControl>
                    )}
                  </Flex>
                  {uploadConfig.connectionType === 'mqtt' &&
                    !uploadConfig.useDefaultServer && (
                      <Box>
                        <Flex mb={3}>
                          <FormControl
                            id={`protocol-${i}`}
                            width={'35%'}
                            key={i}
                          >
                            <FormLabel>Protocol</FormLabel>
                            <Select
                              value={uploadConfig.protocol!}
                              onChange={(e) =>
                                onFormChange(e.target.value, 'protocol', i)
                              }
                            >
                              <option value={undefined}>-</option>
                              <option value={'ws'}>WS</option>
                              <option value={'mqtt'}>MQTT</option>
                            </Select>
                          </FormControl>{' '}
                          <FormControl id={`host-${i}`} ml={3}>
                            <FormLabel>Host</FormLabel>
                            <Input
                              value={uploadConfig.host!}
                              onChange={(e) =>
                                onFormChange(e.target.value.trim(), 'host', i)
                              }
                            />
                          </FormControl>
                          <FormControl width={'40%'} id={`port-${i}`} ml={3}>
                            <FormLabel>Port</FormLabel>
                            <Input
                              type={'number'}
                              value={uploadConfig.port ?? undefined}
                              onChange={(e) =>
                                onFormChange(e.target.value, 'port', i)
                              }
                            />
                          </FormControl>
                        </Flex>
                        <Flex mb={3} alignItems={'center'}>
                          <FormControl id={`username-${i}`} key={i}>
                            <FormLabel>User Name</FormLabel>
                            <Input
                              value={uploadConfig.username ?? undefined}
                              onChange={(e) =>
                                onFormChange(
                                  e.target.value.trim(),
                                  'username',
                                  i,
                                )
                              }
                            />
                          </FormControl>
                          <FormControl id={`password-${i}`} ml={3}>
                            <FormLabel>Password</FormLabel>
                            <InputGroup>
                              <Input
                                value={uploadConfig.password ?? undefined}
                                type={hidePassword ? 'password' : 'text'}
                                onChange={(e) =>
                                  onFormChange(
                                    e.target.value.trim(),
                                    'password',
                                    i,
                                  )
                                }
                              />
                              <InputRightElement>
                                <IconButton
                                  size="sm"
                                  onClick={() => setHidePassword(!hidePassword)}
                                  aria-label={'hidePass'}
                                  icon={
                                    !hidePassword ? (
                                      <ViewIcon />
                                    ) : (
                                      <ViewOffIcon />
                                    )
                                  }
                                />
                              </InputRightElement>
                            </InputGroup>
                          </FormControl>
                        </Flex>
                        <FormControl id={`topic-${i}`}>
                          <FormLabel>Topic</FormLabel>
                          <Input
                            value={uploadConfig.topic!}
                            onChange={(e) =>
                              onFormChange(e.target.value.trim(), 'topic', i)
                            }
                          />
                        </FormControl>
                      </Box>
                    )}

                  {uploadConfig.connectionType === 'ftp' && (
                    <FormControl id={`parser-${i}`}>
                      <FormLabel>Parser Type</FormLabel>
                      <Select
                        value={uploadConfig.parser!}
                        onChange={(e) =>
                          onFormChange(e.target.value, 'parser', i)
                        }
                      >
                        <option value={undefined}>-</option>
                        <option value={'syn'}>SYN</option>
                        <option value={'lms'}>LMS</option>
                        <option value={'csvTable'}>CSV Table</option>
                      </Select>
                    </FormControl>
                  )}
                </Box>
              ))
            ) : (
              <Center>
                <Button
                  colorScheme="blue"
                  mr={6}
                  size={'md'}
                  variant={'outline'}
                  onClick={() => modalCrudFunc('add')}
                  leftIcon={<AddIcon />}
                >
                  Add Config
                </Button>
              </Center>
            )}
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} mr={3}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={onClickDone}>
              Done
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default UploadConfigModal
