import { Center, Heading, Skeleton } from '@chakra-ui/react'
import { ReactNode } from 'react'

type TitleProps = {
  loading?: boolean
  children: ReactNode
}

const Title = ({ loading = false, children }: TitleProps) => {
  return (
    <Center pt="3" pb="3">
      <Skeleton isLoaded={!loading}>
        <Heading as="h1" size="3xl" color="purple.500">
          {loading ? 'placeholder name' : children}
        </Heading>
      </Skeleton>
    </Center>
  )
}

export default Title
