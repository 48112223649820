import { Flex, Link, Tooltip } from '@chakra-ui/react'
import { icon } from './icon'

export interface IIssueIconInterface {
  icons: {
    iconType: 'spanner' | 'warn' | 'error' | 'none'
    tooltipText: string
  }
}

export const issueIcon = (
  buildingSlug: string,
  iconType: 'spanner' | 'warn' | 'error' | 'none',
  tooltipText: string,
): JSX.Element => {
  if (iconType === 'none') {
    return <></>
  }
  return (
    <Flex height={'100%'} alignItems={'center'}>
      <Tooltip hasArrow placement="right" label={tooltipText}>
        <Link href={`/buildings/${buildingSlug}/issues`}>
          {icon(iconType, { colorOnHover: true })}
        </Link>
      </Tooltip>
    </Flex>
  )
}
