import { Button, IconButton } from '@chakra-ui/button'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/form-control'
import {
  CheckCircleIcon,
  ViewIcon,
  ViewOffIcon,
  WarningIcon,
} from '@chakra-ui/icons'
import { Input, InputGroup, InputRightElement } from '@chakra-ui/input'
import { Box, Center, Heading, Text, Link } from '@chakra-ui/layout'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useBoolean,
} from '@chakra-ui/react'
import { ToastId, useToast } from '@chakra-ui/toast'
import { t } from 'i18next'
import { ChangeEvent, Suspense, useRef, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import {
  useChangePasswordMutation,
  useCheckResetTokenLazyQuery,
  useSendResetPasswordEmailMutation,
  useSetPasswordMutation,
} from '../graphql/generated/graphql'
import { camelCaseToNormal } from '../utils/helpers/basicFunc'

type PasswordConditions = {
  moreThanTwelveCharacters: boolean
  upperCase: boolean
  lowerCase: boolean
  number: boolean
  specialCharacters: boolean
  moreThanTwentyTwoCharacter: boolean
}

type PasswordChecklistProps = {
  passwordConditions: PasswordConditions
}

interface FormData {
  password: string
  currentPassword: string
  confirmPassword: string
  email: string
}

const PASSWORD_CONDITIONS: PasswordConditions = {
  moreThanTwelveCharacters: true,
  upperCase: true,
  lowerCase: true,
  number: true,
  specialCharacters: true,
  moreThanTwentyTwoCharacter: true,
}

const PasswordChecklist = ({ passwordConditions }: PasswordChecklistProps) => {
  return (
    <Box>
      {Object.entries(passwordConditions).map(
        ([type, value]) =>
          type !== 'moreThanTwentyTwoCharacter' && (
            <Center
              justifyContent={'start'}
              color={value ? 'red' : 'green'}
              textTransform={'capitalize'}
              fontSize={13}
            >
              {value ? <WarningIcon mr={2} /> : <CheckCircleIcon mr={2} />}
              {camelCaseToNormal(type)}
            </Center>
          ),
      )}
      <Center fontSize={'12px'}>OR</Center>
      <Center
        justifyContent={'start'}
        color={passwordConditions.moreThanTwentyTwoCharacter ? 'red' : 'green'}
        fontSize={13}
      >
        {passwordConditions.moreThanTwentyTwoCharacter ? (
          <WarningIcon mr={2} />
        ) : (
          <CheckCircleIcon mr={2} />
        )}
        {camelCaseToNormal(
          'You can have more than 22 characters to bypass all the above',
        )}
      </Center>
    </Box>
  )
}

const SetResetPassword = () => {
  const [searchParams] = useSearchParams(),
    token = searchParams.get('token'),
    email = searchParams.get('email'),
    resetType = searchParams.get('resetType'),
    navigate = useNavigate()

  useEffect(() => {
    if (token) {
      checkResetToken()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  const [checkResetToken, { data: checkResetTokenData }] =
    useCheckResetTokenLazyQuery({
      variables: { where: token! },
    })

  useEffect(() => {
    if (
      checkResetTokenData &&
      checkResetTokenData.checkResetToken &&
      !checkResetTokenData?.checkResetToken.isValid
    ) {
      addToast()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkResetTokenData])

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm<FormData>({ mode: 'onChange' })

  const [currentPasswordView, setCurrentPasswordView] = useBoolean()
  const [confirmPasswordView, setConfirmPasswordView] = useBoolean()
  const [newPasswordView, setNewPasswordView] = useBoolean()

  const location = useLocation(),
    { pathname } = location,
    toast = useToast(),
    toastIdRef = useRef<ToastId>()
  const [passwordConditions, setPasswordConditions] =
    useState<PasswordConditions>({
      ...PASSWORD_CONDITIONS,
    })

  const password = watch('password')

  let title = pathname.includes('change') ? 'Change Password' : 'Set Password'
  const passwordRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[#?!@$%^&*\\-]).{12,150}$|^.{22,150}$/i

  if (pathname.includes('reset')) {
    title = 'Forgot Password'
  }

  const toastFunc = (title: string, status: 'success' | 'error') => {
    toast({
      title,
      status,
      position: 'top',
      duration: 3000,
      isClosable: true,
    })
  }
  const [resetPassword] = useSendResetPasswordEmailMutation()
  const [setPassword] = useSetPasswordMutation()
  const [changePassword] = useChangePasswordMutation()

  const addToast = () => {
    toastIdRef.current = toast({
      render: () => <CustomToast />,
      duration: null,
      position: 'top',
      isClosable: false,
    })
  }

  const onSubmit = (data: FormData) => {
    if (pathname.includes('reset')) {
      resetPassword({
        variables: {
          resetType: 'reset',
          email: data.email,
        },
        onCompleted: (response) => {
          toastFunc(response.sendResetPasswordEmail.message, 'success')
          navigate('/signin')
        },
        onError: (err) => {
          toastFunc(err.message, 'error')
        },
      })
    } else if (pathname.includes('verify')) {
      setPassword({
        variables: { password: data.confirmPassword, token: token! },
        onCompleted: (response) => {
          toastFunc(response.setPassword.message, 'success')
          navigate('/signin')
        },
        onError: (err) => {
          if (err.message === 'TOKEN_EXPIRED') {
            addToast()
          } else {
            toastFunc(err.message, 'error')
          }
        },
      })
    } else if (pathname.includes('change')) {
      changePassword({
        variables: {
          oldPassword: data.currentPassword,
          newPassword: data.password,
        },
        onCompleted: (response) => {
          navigate('/signin', { replace: true })
          toast({
            title: response.changePassword.message,
            status: 'success',
            position: 'top',
            duration: 3000,
            isClosable: true,
          })
        },
      })
    }
  }

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { target } = e,
      { value } = target
    const newConditions: PasswordConditions = {
      moreThanTwelveCharacters: value.length < 12 || value.length > 22,
      upperCase: !/[A-Z]/.test(value) || value.length > 22,
      lowerCase: !/[a-z]/.test(value) || value.length > 22,
      number: !/\d/.test(value) || value.length > 22,
      specialCharacters: !/[#?!@$%^&*-]/.test(value) || value.length > 22,
      moreThanTwentyTwoCharacter: value.length <= 22,
    }

    setPasswordConditions(newConditions)
  }

  const onResendEmailClick = () => {
    if (email && resetType) {
      resetPassword({
        variables: { resetType, email: decodeURI(email) },
        onCompleted: (response) => {
          toastFunc(
            resetType === 'verify'
              ? `A new verify email has been sent to the registered email: ${decodeURI(email)}`
              : response.sendResetPasswordEmail.message,
            'success',
          )
          if (toastIdRef.current) {
            toast.close(toastIdRef.current)
          }
        },
      })
    } else {
      toastFunc('Verify link is not correct', 'error')
    }
  }

  const CustomToast = () => {
    return (
      <Center bg={'white'} borderRadius={5} p={3}>
        <Text fontWeight={'bold'}>
          The link you've clicked is expired. To resend the link click
        </Text>
        <Button
          colorScheme={'green'}
          ml={3}
          onClick={() => onResendEmailClick()}
        >
          Resend
        </Button>
      </Center>
    )
  }

  return (
    <Suspense
      fallback={
        <Modal isOpen={true} onClose={() => {}} size="md" isCentered>
          <ModalOverlay
            bgGradient={
              'radial-gradient(circle, purple.300 0%, purple.500 80%, purple.800 100%)'
            }
          />
        </Modal>
      }
    >
      <Modal isOpen={true} onClose={() => {}} size="md" isCentered>
        <ModalOverlay
          bgGradient={
            'radial-gradient(circle, purple.300 0%, purple.500 80%, purple.800 100%)'
          }
        />
        <ModalContent bg={'white'}>
          <ModalBody p={30}>
            <Center mb={10}>
              <Heading>{title}</Heading>
            </Center>
            <form onSubmit={handleSubmit(onSubmit)}>
              {pathname.includes('reset') ? (
                <FormControl
                  variant="floating"
                  id="email"
                  isInvalid={!!errors.email}
                >
                  <Input
                    type="email"
                    placeholder={''}
                    {...register('email', {
                      required: 'requiredItem',
                      pattern: {
                        value: /^\S+@\S+$/i,
                        message: 'requiredEmail',
                      },
                    })}
                  />
                  <FormLabel>Email</FormLabel>
                  {errors.email ? (
                    <FormErrorMessage minH={8}>
                      {t((errors.email?.message as string) || '')}
                    </FormErrorMessage>
                  ) : (
                    <Box minH={8} mt={2}></Box>
                  )}
                </FormControl>
              ) : (
                <>
                  {pathname.includes('change') && (
                    <FormControl
                      variant="floating"
                      id="currentPassword"
                      isInvalid={!!errors.currentPassword}
                      mt={4}
                    >
                      <InputGroup>
                        <Input
                          type={currentPasswordView ? 'text' : 'password'}
                          placeholder=" "
                          {...register('currentPassword', {
                            required: 'Please fill in the field',
                          })}
                        />
                        <FormLabel>Current Password</FormLabel>
                        <InputRightElement>
                          <IconButton
                            size="sm"
                            onClick={setCurrentPasswordView.toggle}
                            aria-label={t('signIn.aria.hidePass')}
                            icon={
                              currentPasswordView ? (
                                <ViewIcon />
                              ) : (
                                <ViewOffIcon />
                              )
                            }
                          />
                        </InputRightElement>
                      </InputGroup>
                      <Box minH={8} mt={2}></Box>
                    </FormControl>
                  )}

                  <FormControl
                    variant="floating"
                    id="password"
                    isInvalid={!!errors.password}
                    mt={4}
                  >
                    <InputGroup>
                      <Input
                        type={newPasswordView ? 'text' : 'password'}
                        placeholder=" "
                        {...register('password', {
                          onChange: (e) => onPasswordChange(e),
                          required: 'Please fill in the field',
                          pattern: {
                            value: passwordRegex,
                            message: '',
                          },
                        })}
                      />
                      <FormLabel>Set Password</FormLabel>
                      <InputRightElement>
                        <IconButton
                          size="sm"
                          onClick={setNewPasswordView.toggle}
                          aria-label={t('signIn.aria.hidePass')}
                          icon={
                            newPasswordView ? <ViewIcon /> : <ViewOffIcon />
                          }
                        />
                      </InputRightElement>
                    </InputGroup>
                    <Box minH={8} mt={2}>
                      {Object.values(passwordConditions).some(
                        (value) => !value,
                      ) && (
                        <PasswordChecklist
                          passwordConditions={passwordConditions}
                        />
                      )}
                    </Box>
                  </FormControl>

                  <FormControl
                    variant="floating"
                    id="confirmPassword"
                    isInvalid={!!errors.confirmPassword}
                    mt={4}
                  >
                    <InputGroup>
                      <Input
                        type={confirmPasswordView ? 'text' : 'password'}
                        placeholder=" "
                        {...register('confirmPassword', {
                          required: 'Please fill in the field',
                          validate: (value) =>
                            value === password || 'Passwords do not match', // Custom validation
                        })}
                      />
                      <FormLabel>Confirm Password</FormLabel>
                      <InputRightElement>
                        <IconButton
                          size="sm"
                          onClick={setConfirmPasswordView.toggle}
                          aria-label={t('signIn.aria.hidePass')}
                          icon={
                            confirmPasswordView ? <ViewIcon /> : <ViewOffIcon />
                          }
                        />
                      </InputRightElement>
                    </InputGroup>
                    {errors.confirmPassword ? (
                      <FormErrorMessage minH={8}>
                        {errors.confirmPassword.message as string}
                      </FormErrorMessage>
                    ) : (
                      <Box minH={8} mt={2}></Box>
                    )}
                  </FormControl>
                </>
              )}
              <Center justifyContent={'space-between'}>
                {pathname.includes('reset') && (
                  <Box float={'left'}>
                    <Text color={'purple'}>
                      <Link href={'/signin'}>Back to login</Link>
                    </Text>
                  </Box>
                )}

                <Button colorScheme={'purple'} type={'submit'} float={'right'}>
                  Submit
                </Button>
              </Center>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Suspense>
  )
}

export default SetResetPassword
