import {
  Box,
  Button,
  GridItem,
  Heading,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import HeadlineImage from '../../assets/Screen-placeholder.jpg'
import Hero from '../../elements/hero'

const Home = () => {
  return (
    <>
      <Hero
        height="tall"
        imageUrl={HeadlineImage}
        altText="In building energy dashboard"
      />
      <SimpleGrid columns={12} bgColor="gray.300" textColor="purple.500">
        <GridItem
          colSpan={[1, 1, 1, 1, 2]}
          display={{ base: 'none', sm: 'none', md: 'flex' }}
        ></GridItem>
        <GridItem colSpan={[12, 12, 5, 5, 4]}>
          <Box bg="white" p={12}>
            <Heading mb={6}>
              Get optimal performance out of your building
            </Heading>
            <Text>
              Opti-Twin is a web technology start up that’s revolutionising the
              operation of buildings by providing the information and
              traceability needed to truly drive net zero carbon performance.
              Our building performance led approach makes us stand out from our
              peers, and our unique in-building performance dashboards give
              building users and operators the guidance they need to improve the
              sustainability of their building.
            </Text>
          </Box>
        </GridItem>
        <GridItem colSpan={[12, 12, 5, 5, 4]}>
          <Box p={12}>
            <Heading mb={6}>Contact us to find out more</Heading>
            <Text mb={6}>
              Our consultant led integration path ensures the best performance
              for your building and immediate notifications when your building
              is operating out of parameters.
            </Text>
            <Button
              variant="outline"
              color="purple.500"
              mt={18}
              as="a"
              href='mailto:info@opti-twin.co.uk?subject="Opti-Twin information request'
            >
              Get in touch
            </Button>
          </Box>
        </GridItem>
      </SimpleGrid>
    </>
  )
}

export default Home
