import { DeleteIcon, ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Checkbox,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Heading,
  IconButton,
  Input,
  Select,
  Spinner,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBreakpoint,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  GET_CHANNEL_SEARCH_MANY,
  GET_COMBI_CHANNELS,
  GET_RELATIONSHIP_TREE,
} from '../../graphql/API/queries'
import {
  Channel,
  ChannelSearchManyQuery,
  ChannelUpdateInput,
  useChannelDeleteMutation,
  useChannelUpdateManyMutation,
  useChannelUpdateMutation,
  useInspectorChannelUpdateOneMutation,
} from '../../graphql/generated/graphql'
import {
  InspectorChannel,
  inspectorChannelGuard,
} from '../../Pages/buildings/issues'
import {
  isCurrentUserEndUser,
  isCurrentUserStaff,
} from '../../utils/helpers/basicFunc'
import { tableHeadings } from '../../utils/tableDataConstruct/tableDataConstruct'
import { issueIcon } from '../icon/issueIcon'
import InfoAlert from '../infoAlert/infoAlert'
import EditableControls from './editableControls'
import TargetAlerts from '../../Pages/targetAlerts/targetAlerts'
import { incomingUnitMap } from '../../utils/unitMaps/unitMap'

type EditableTableProps = {
  data: ChannelSearchManyQuery['channelSearchMany']
  from: 'channels'
  buildingSlug: string
  buildingId: string
}

const EditableTable = ({
  data: channels,
  from,
  buildingSlug,
  buildingId,
}: EditableTableProps) => {
  const havePermissions =
    from === 'channels' ? !isCurrentUserEndUser() : isCurrentUserStaff()

  const navigate = useNavigate()
  const breakPoint = useBreakpoint()
  const toast = useToast()
  const toastFunc = ({
    type,
    message,
  }: {
    type: 'error' | 'success'
    message: string
  }) => {
    toast({
      title: message,
      status: type,
      position: 'top',
      duration: 3000,
      isClosable: true,
    })
  }

  const channelVariables = {
    where: {
      buildingId,
    },
  }
  let refetchQueries = [
    {
      query: GET_CHANNEL_SEARCH_MANY,
      variables: channelVariables,
    },
    {
      query: GET_RELATIONSHIP_TREE,
      variables: channelVariables,
    },
    {
      query: GET_COMBI_CHANNELS,
      variables: channelVariables,
    },
  ]

  const [clickedElement, setClickedElement] = useState<
    Partial<Channel> & { fieldName?: string; value?: string }
  >()
  const [inProgressChannels, setInProgressChannels] = useState<
    { id: string; fieldName: string }[]
  >([])

  const [isAlertsOpen, setAlertModalVisible] = useState(false)
  const [checkedChannels, setCheckedChannels] = useState<string[]>([])
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false)
  const [editConfirmVisibility, setEditConfirmVisibility] = useState(false)

  const [updateManyChannels] = useChannelUpdateManyMutation({ refetchQueries })
  const [updateInspectorChannel] = useInspectorChannelUpdateOneMutation({
    refetchQueries,
  })
  const [editChannel, { loading: editChannelLoader }] =
    useChannelUpdateMutation({ refetchQueries })
  const [deleteChannel, { loading: deleteChannelLoader }] =
    useChannelDeleteMutation({ refetchQueries })

  const tableHeadingsSelect = ():
    | 'channels'
    | 'channels-sm'
    | 'channels-md'
    | 'channels-lg' => {
    switch (breakPoint) {
      case 'base': {
        return `${from}-sm`
      }
      case 'sm': {
        return isCurrentUserEndUser() ? `${from}-md` : 'channels'
      }
      case 'md': {
        return isCurrentUserEndUser() ? `${from}-md` : 'channels'
      }
      case 'lg': {
        return isCurrentUserEndUser() ? `${from}-lg` : 'channels'
      }
      default: {
        return from
      }
    }
  }

  const onImportanceToggle = (props: {
    channel: Partial<Channel>
    isImportant: boolean
  }) => {
    const channel = props.channel
    if (!channel.id) {
      return
    }
    if (typeof props.isImportant !== 'boolean') {
      return
    }

    updateInspectorChannel({
      variables: {
        where: {
          channelId: channel.id,
          isImportant: props.isImportant,
        },
      },
      onCompleted: () => {
        toastFunc({
          type: 'success',
          message: `Successfully edited importance for the channel: ${channel.name}`,
        })
      },
      onError: (err) => {
        toastFunc({
          type: 'error',
          message:
            (err.networkError as any).result.errors[0].message ?? err.message,
        })
      },
    })
  }

  const onEditSubmit = (submitData: {
    value: string
    fieldName: string
    channel: Partial<Channel>
  }) => {
    const { value, fieldName, channel } = submitData
    if (!channel.id) {
      return
    }
    const channelUpdateInput: ChannelUpdateInput = {
      channelId: channel.id as string,
      [fieldName]: value,
    }
    setInProgressChannels([
      ...inProgressChannels,
      { id: channel.id, fieldName: fieldName },
    ])

    editChannel({
      variables: { data: channelUpdateInput },
      onCompleted: () => {
        setInProgressChannels(
          inProgressChannels.filter(
            (item) => !(item.id === channel.id && item.fieldName === fieldName),
          ),
        )
        toastFunc({
          type: 'success',
          message: `Successfully edited ${fieldName} for the channel: ${channel.name}`,
        })
      },
      onError: (err) => {
        setInProgressChannels(
          inProgressChannels.filter(
            (item) => !(item.id === channel.id && item.fieldName === fieldName),
          ),
        )
        toastFunc({
          type: 'error',
          message:
            (err.networkError as any).result.errors[0].message ?? err.message,
        })
      },
    })
  }

  const onDeleteClick = ({
    datum,
    from,
  }: {
    datum: Partial<Channel>
    from: string
  }) => {
    if (from === 'channels') {
      setClickedElement(datum)
      setDeleteModalVisibility(true)
    }
  }

  const onDeleteModalClose = () => {
    setClickedElement(undefined)
    setDeleteModalVisibility(false)
  }

  const onDelete = () => {
    if (clickedElement) {
      switch (from) {
        case 'channels':
          deleteChannel({
            variables: { data: { channelId: clickedElement.id as string } },
            onCompleted: () => {
              toastFunc({
                type: 'success',
                message: `Successfully deleted channel: ${clickedElement.name}`,
              })
              setDeleteModalVisibility(false)
            },
            onError: (err) => {
              toastFunc({ type: 'error', message: err.message })
            },
          })
          break
      }
    }
  }

  const haveDeletePermissions = (datum: Partial<Channel>): boolean => {
    switch (from) {
      case 'channels':
        return datum.channelType === 'virtual'
    }
  }

  const createIssueIcon = (
    channelIssues: InspectorChannel,
    measurementType?: string,
  ) => {
    if (measurementType === 'unknown') {
      return issueIcon(
        buildingSlug,
        'spanner',
        'Channel has not yet been configured',
      )
    }
    if (!channelIssues.lastSeen) {
      return issueIcon(
        buildingSlug,
        'spanner',
        'Channel has not yet transmitted data',
      )
    }

    if (channelIssues.notTransmitting) {
      return issueIcon(buildingSlug, 'error', 'Channel not transmitting')
    }

    if (channelIssues.notCumulative && channelIssues.includesZeroes) {
      return issueIcon(
        buildingSlug,
        'warn',
        'Channel has multiple issues associated with it',
      )
    }

    if (channelIssues.notCumulative) {
      return issueIcon(buildingSlug, 'warn', 'Channel has non-cumulative data')
    }

    if (channelIssues.includesZeroes) {
      return issueIcon(buildingSlug, 'warn', 'Channel raw data includes zeroes')
    }

    return <></>
  }

  const onCheckboxChange = ({
    id,
    isChecked,
  }: {
    id: string
    isChecked: boolean
  }) => {
    let changedCheckChannels = structuredClone(checkedChannels)
    if (checkedChannels?.includes(id)) {
      changedCheckChannels = changedCheckChannels?.filter(
        (checkedId) => checkedId !== id,
      )
    } else {
      changedCheckChannels?.push(id)
    }
    setCheckedChannels(changedCheckChannels)
  }

  const onSelectChange = (measurementType: 'cumulative' | 'independent') => {
    const channelUpdateManyInput = {
      data: {
        ids: checkedChannels,
        measurementType,
      },
    }
    updateManyChannels({
      variables: channelUpdateManyInput,
      onCompleted: () => {
        toastFunc({
          type: 'success',
          message: 'Channels have been updated successfully',
        })
        setCheckedChannels([])
      },
      onError: (err, res) => {
        toastFunc({ type: 'error', message: err.message })
      },
    })
  }

  if (!channels.length) {
    return (
      <Center>
        <Heading>No Data</Heading>
      </Center>
    )
  }

  const actualChannels = (): string[] | undefined => {
    const actualChannels: string[] = []
    for (const channel of channels) {
      if (channel?.channelType === 'actual') {
        actualChannels.push(channel.id as string)
      }
    }
    return actualChannels
  }

  const onAllCheckChange = (check: boolean) => {
    let allChannelsToggle = actualChannels()
    if (allChannelsToggle) {
      if (check) {
        setCheckedChannels(allChannelsToggle)
      } else {
        setCheckedChannels([])
      }
    }
  }

  const shouldShowSpinner = (
    channelId: string | undefined | null,
    fieldName: string,
  ): boolean => {
    return (
      !!channelId &&
      inProgressChannels.some(
        (item) => item.id === channelId && item.fieldName === fieldName,
      )
    )
  }

  return (
    <>
      {checkedChannels.length > 0 &&
        !isCurrentUserEndUser() &&
        breakPoint !== 'base' &&
        breakPoint !== 'md' && (
          <Center justifyContent={'left'} mb={'10px'}>
            <Text mr={3} fontSize={{ base: '13px', lg: '16px' }}>
              Choose the measurement type for the selected channels
            </Text>
            <Select
              placeholder="Select measurement"
              w={{ lg: '20%' }}
              size={'sm'}
              onChange={(e) =>
                onSelectChange(e.target.value as 'cumulative' | 'independent')
              }
            >
              <option value="cumulative">Cumulative</option>
              <option value="independent">Independent</option>
            </Select>
          </Center>
        )}

      <TableContainer
        mt={checkedChannels.length ? 0 : '42px'}
        overflowY={'auto'}
        maxHeight={'calc(100vh - 350px)'}
      >
        <Table variant="simple">
          <Thead>
            <Tr>
              {from === 'channels' &&
                breakPoint !== 'base' &&
                breakPoint !== 'md' &&
                !isCurrentUserEndUser() && (
                  <Th>
                    <Checkbox
                      borderColor={'black'}
                      onChange={(e) => onAllCheckChange(e.target.checked)}
                      isChecked={
                        checkedChannels.length > 0 &&
                        actualChannels()?.length === checkedChannels.length
                      }
                      isIndeterminate={
                        checkedChannels.length > 0 &&
                        actualChannels()?.length !== checkedChannels.length
                      }
                    />
                  </Th>
                )}
              {tableHeadings[tableHeadingsSelect()].map((header, i) => (
                <Th key={i}>{header.displayName}</Th>
              ))}
              {havePermissions && breakPoint !== 'base' && (
                <Th textAlign={'center'}>Important</Th>
              )}
              {havePermissions && breakPoint !== 'base' && <Th>Action</Th>}
            </Tr>
          </Thead>
          <Tbody>
            {channels.map(
              (channel, i: number) =>
                channel && (
                  <Tr key={i}>
                    {breakPoint !== 'base' &&
                      breakPoint !== 'md' &&
                      !isCurrentUserEndUser() && (
                        <Td>
                          {channel.id && channel.channelType !== 'virtual' && (
                            <Checkbox
                              borderColor={'black'}
                              onChange={(e) =>
                                onCheckboxChange({
                                  id: channel.id!,
                                  isChecked: e.target.checked,
                                })
                              }
                              isChecked={checkedChannels.includes(channel.id)}
                            />
                          )}
                        </Td>
                      )}

                    {tableHeadings[tableHeadingsSelect()].map((header, i) => {
                      if (!header.editable) {
                        return (
                          <Td textTransform={'capitalize'} key={i}>
                            {channel[header.fieldName]}
                          </Td>
                        )
                      }

                      if (header.fieldName === 'measurementType') {
                        return (
                          <Td
                            display={'flex'}
                            height={'100%'}
                            justifyContent="space-between"
                            key={i}
                          >
                            <Select
                              isDisabled={shouldShowSpinner(
                                channel.id,
                                header.fieldName,
                              )}
                              value={channel[header.fieldName] ?? 'unknown'}
                              onChange={(event) => {
                                onEditSubmit({
                                  value: event.target.value,
                                  fieldName: 'measurementType',
                                  channel: channel,
                                })
                              }}
                            >
                              <option value="unknown">Unknown</option>
                              <option value="cumulative">Cumulative</option>
                              <option value="independent">Independent</option>
                            </Select>
                            {shouldShowSpinner(
                              channel.id,
                              header.fieldName,
                            ) && <Spinner alignSelf={'center'} />}
                          </Td>
                        )
                      }

                      if (header.fieldName === 'incomingUnit') {
                        return (
                          <Td
                            display={'flex'}
                            height={'100%'}
                            justifyContent="space-between"
                            gap={'3'}
                            key={i}
                          >
                            <Select
                              isDisabled={shouldShowSpinner(
                                channel.id,
                                header.fieldName,
                              )}
                              value={channel[header.fieldName] ?? 'unknown'}
                              onChange={(event) => {
                                onEditSubmit({
                                  value: event.target.value,
                                  fieldName: 'incomingUnit',
                                  channel: channel,
                                })
                              }}
                            >
                              {incomingUnitMap.map((unit, i) => (
                                <option value={unit.code} key={i}>
                                  {unit.name}
                                </option>
                              ))}
                            </Select>
                            {shouldShowSpinner(
                              channel.id,
                              header.fieldName,
                            ) && <Spinner alignSelf={'center'} />}
                          </Td>
                        )
                      }

                      return (
                        <Td key={i}>
                          <Editable
                            defaultValue={channel[header.fieldName] ?? ''}
                            placeholder={`+ Add ${header.fieldName}`}
                            onSubmit={(e) =>
                              onEditSubmit({
                                value: e,
                                fieldName: header.fieldName,
                                channel: channel,
                              })
                            }
                            isDisabled={!havePermissions}
                            submitOnBlur={false}
                            selectAllOnFocus={false}
                          >
                            <Flex
                              alignItems={'center'}
                              justifyContent={'space-between'}
                            >
                              <Tooltip
                                label="Click to edit"
                                shouldWrapChildren={true}
                                isDisabled={!havePermissions}
                              >
                                <EditablePreview
                                  p={2}
                                  cursor={'pointer'}
                                  _hover={{
                                    background: havePermissions
                                      ? 'gray.100'
                                      : 'none',
                                  }}
                                />
                              </Tooltip>
                              <Input as={EditableInput} />
                              <EditableControls />

                              <Flex>
                                {from === 'channels' &&
                                  header.fieldName === 'name' && (
                                    <>
                                      <Box>
                                        {inspectorChannelGuard(
                                          channel.channelIssues,
                                        ) &&
                                          createIssueIcon(
                                            channel.channelIssues,
                                            channel?.measurementType ??
                                              undefined,
                                          )}
                                      </Box>
                                      <Box>
                                        <IconButton
                                          ml={2}
                                          aria-label="external-link"
                                          onClick={() =>
                                            navigate(
                                              `/buildings/${buildingSlug}/data?channelId=${channel.id}`,
                                            )
                                          }
                                          icon={
                                            <ExternalLinkIcon
                                              fontSize={'20px'}
                                            />
                                          }
                                          variant="ghost"
                                          // colorScheme={'purple'}
                                        />
                                      </Box>
                                    </>
                                  )}
                              </Flex>
                            </Flex>
                          </Editable>
                        </Td>
                      )
                    })}
                    {havePermissions ? (
                      breakPoint !== 'base' &&
                      channel.channelType === 'actual' ? (
                        <Td textAlign={'center'}>
                          <Switch
                            defaultChecked={channel.channelIssues?.isImportant}
                            onChange={(event) =>
                              onImportanceToggle({
                                channel,
                                isImportant: event.target.checked,
                              })
                            }
                          ></Switch>
                        </Td>
                      ) : (
                        <Td></Td>
                      )
                    ) : (
                      <></>
                    )}
                    {breakPoint !== 'base' && (
                      <Td>
                        {!isCurrentUserEndUser() && (
                          <Button
                            variant={'outline'}
                            colorScheme={'purple.500'}
                            mr={4}
                            onClick={() => {
                              setClickedElement(channel)
                              setAlertModalVisible(true)
                            }}
                          >
                            View Alerts
                          </Button>
                        )}

                        {haveDeletePermissions(channel) && (
                          <IconButton
                            aria-label="delete"
                            icon={<DeleteIcon />}
                            colorScheme="red"
                            variant="outline"
                            onClick={() =>
                              onDeleteClick({ datum: channel, from })
                            }
                          />
                        )}
                      </Td>
                    )}
                  </Tr>
                ),
            )}
          </Tbody>
        </Table>
        <InfoAlert
          isOpen={deleteModalVisibility}
          onClose={onDeleteModalClose}
          onSubmit={onDelete}
          headerText={`Delete ${clickedElement?.name}`}
          bodyText={"Are you sure? You can't undo this action afterwards."}
          buttonText={'Delete'}
          loading={deleteChannelLoader}
        />
        <InfoAlert
          isOpen={editConfirmVisibility}
          onClose={() => setEditConfirmVisibility(false)}
          onSubmit={() =>
            onEditSubmit({
              value: clickedElement?.value ?? '',
              fieldName: clickedElement?.fieldName ?? '',
              channel: clickedElement!,
            })
          }
          headerText={`Edit ${clickedElement?.name}'s measurement type`}
          bodyText={"Are you sure? You can't undo this action afterwards."}
          buttonText={'Submit'}
          loading={editChannelLoader}
          colorScheme={'blue'}
        />
        {isAlertsOpen && (
          <TargetAlerts
            isOpen={isAlertsOpen}
            onClose={() => setAlertModalVisible(false)}
            channelId={clickedElement?.id as string}
            channelName={clickedElement?.name ?? ''}
          />
        )}
      </TableContainer>
    </>
  )
}

export default EditableTable
