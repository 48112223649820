import { createIcon } from '@chakra-ui/react'

const Education = createIcon({
  displayName: 'Education',
  viewBox: '0 0 239 192',
  path: (
    <g id="g20" transform="translate(-86.103,-81.886)">
      <path
        d="M 306.892,159.018 205.754,93.751 104.619,159.018 c -2.312,1.499 -5.401,0.828 -6.892,-1.484 -1.494,-2.314 -0.827,-5.4 1.485,-6.892 l 106.543,-68.756 106.546,68.756 c 2.313,1.493 2.978,4.578 1.485,6.892 -1.499,2.32 -4.594,2.972 -6.894,1.484 z"
        fill="currentColor"
      />
      <path
        d="m 205.755,127.701 c -3.666,0 -6.646,2.981 -6.646,6.647 0,3.666 2.98,6.647 6.646,6.647 3.666,0 6.647,-2.981 6.647,-6.647 0,-3.666 -2.981,-6.647 -6.647,-6.647 z m 0,23.264 c -9.163,0 -16.617,-7.455 -16.617,-16.617 0,-9.163 7.455,-16.618 16.617,-16.618 9.163,0 16.618,7.456 16.618,16.618 0,9.163 -7.456,16.617 -16.618,16.617 z"
        fill="currentColor"
      />
      <g id="g18">
        <path
          d="m 91.09,172.468 h 3.284 v 101.515 h 9.855 c 0.031,-13.895 -0.013,-101.515 -0.013,-101.515 h 12.17 c 0,0 -0.045,87.62 -0.013,101.515 h 9.824 c 0.032,-13.895 -0.013,-101.515 -0.013,-101.515 h 12.181 c 0,0 -0.063,87.62 -0.018,101.515 h 10.966 c 0.044,-13.895 -0.018,-101.515 -0.018,-101.515 h 12.126 c 0,0 -0.045,87.62 -0.013,101.515 h 9.782 c 0.045,-13.895 0.072,-101.515 0.072,-101.515 h 69.198 v 101.515 h 9.886 V 172.468 h 12.082 v 101.515 h 9.886 V 172.468 h 12.082 V 273.983 H 295.38 V 172.468 h 12.082 v 101.515 h 9.886 V 172.468 h 3.075 c 2.757,0 4.987,-2.186 4.987,-4.943 0,-2.757 -2.229,-4.943 -4.987,-4.943 H 91.09 c -2.746,0 -4.987,2.186 -4.987,4.943 0,2.758 2.241,4.943 4.987,4.943 z"
          fill="currentColor"
        />
        <path
          d="m 205.936,189.273 h -0.352 c -12.39,0 -22.462,10.523 -22.462,23.462 v 61.248 h 9.973 v -61.248 c 0,-7.447 5.602,-13.499 12.488,-13.499 h 0.352 c 6.876,0 12.477,6.052 12.477,13.499 v 61.248 h 9.973 v -61.248 c 0.002,-12.939 -10.07,-23.462 -22.449,-23.462 z"
          fill="currentColor"
        />
      </g>
    </g>
  ),
})

export default Education
