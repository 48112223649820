import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'
import { useRef } from 'react'

type DeleteAlertProps = {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
  loading?: boolean
  headerText: string
  bodyText: string
  buttonText: string
  colorScheme?: string
  cancelText?: string
}

const InfoAlert = ({
  isOpen,
  onClose,
  onSubmit,
  loading,
  headerText,
  bodyText,
  buttonText,
  colorScheme = 'red',
  cancelText = 'Cancel',
}: DeleteAlertProps) => {
  const cancelRef = useRef(null)
  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
        motionPreset={'slideInBottom'}
      >
        <AlertDialogOverlay>
          <AlertDialogContent bg={'white'}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {headerText}
            </AlertDialogHeader>

            <AlertDialogBody>{bodyText}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                {cancelText}
              </Button>
              <Button
                colorScheme={colorScheme}
                onClick={onSubmit}
                ml={3}
                isLoading={loading}
              >
                {buttonText}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default InfoAlert
