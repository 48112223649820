import ParentSize from '@visx/responsive/lib/components/ParentSize'
import BrushChart from '../graphPlots/brushChart'
import { Box } from '@chakra-ui/react'
import { staticGraphData } from './staticGraphData'

const StaticGraph = () => {
  return (
    <Box minW={'100%'} h={`100%`}>
      <ParentSize>
        {({ width, height }) => (
          <BrushChart
            width={width}
            height={height}
            selectedChannelData={staticGraphData}
            isStaticGraph={true}
          />
        )}
      </ParentSize>
    </Box>
  )
}

export default StaticGraph
