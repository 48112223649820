import { useEffect, useState } from 'react'
import {
  GridItem,
  Heading,
  SimpleGrid,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react'

import { useParams } from 'react-router-dom'
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs'

import {
  ChannelSearchManyQuery,
  useBuildingGetIdQuery,
  useChannelSearchManyLazyQuery,
  useDataGetManyInBinsLazyQuery,
  useDataGetManyLazyQuery,
  useGetManyCompositeChannelLazyQuery,
} from '../../graphql/generated/graphql'
import Joyride, { CallBackProps, STATUS } from 'react-joyride'
import {
  BuildingData,
  SelectedChannel,
} from '../../components/buildingData/buildingData'
import { DATA_DEMO_TOUR_FINISHED } from '../../constants'
import { useAuth } from '../../hooks/use-auth'
import GraphReplacement from '../../elements/graphReplacement/graphReplacement'
import { mediaQueries } from '../../utils/mediaQueries/mediaQueries'

export const WrappedBuildingData = () => {
  const params = useParams()
  const toast = useToast()
  const buildingSlug = params['buildingSlug'] ?? 'unknown_building'
  const breadcrumbLinks = [
    { text: 'Home', route: '/' },
    { text: 'Buildings', route: '/buildings' },
    { text: buildingSlug, route: `/buildings/${buildingSlug}` },
    { text: 'data', route: `/buildings/${buildingSlug}/data` },
  ]

  const auth = useAuth()
  const [run, setRun] = useState<boolean>(false)
  const [stepIndex, setStepIndex] = useState<number>(0)
  const [graphType, setGraphType] = useState<'line' | 'heatmap'>('line')
  const [selectedChannels, setSelectedChannels] = useState<SelectedChannel[]>(
    [],
  )

  const [channels, setChannels] = useState<
    ChannelSearchManyQuery['channelSearchMany']
  >([])

  const { data: buildingData } = useBuildingGetIdQuery({
    variables: { where: { slug: buildingSlug } },
  })

  const [, , , , isTab, isMobile] = useMediaQuery(mediaQueries)

  let [getChannelData, { loading: channelGraphDataLoading }] =
    useDataGetManyLazyQuery({
      onCompleted: () => {
        if (
          localStorage.getItem(`${DATA_DEMO_TOUR_FINISHED}-${auth.user.id}`) !==
            'TRUE' &&
          !isTab &&
          !isMobile
        ) {
          setTimeout(() => {
            setRun(true)
          }, 1000)
        }
      },
    })

  const [getChannelList, { data: channelList, loading: channelListLoading }] =
    useChannelSearchManyLazyQuery()

  const [getCombiList, { data: combiList, loading: combiListLoading }] =
    useGetManyCompositeChannelLazyQuery()

  const [getChannelDataInBins, { data: channelDataInBins }] =
    useDataGetManyInBinsLazyQuery({
      onCompleted: () => {
        if (
          localStorage.getItem(`${DATA_DEMO_TOUR_FINISHED}-${auth.user.id}`) !==
          'TRUE'
        ) {
          setRun(true)
          if (stepIndex !== 10) {
            setStepIndex(8)
          }
        }
      },
    })

  useEffect(() => {
    if (channelList?.channelSearchMany && combiList?.getManyCompositeChannel) {
      setChannels(
        channelList?.channelSearchMany.map((channel) => ({
          ...channel,
          name:
            combiList.getManyCompositeChannel.find(
              (combi) => combi?.id && combi.id === channel?.id,
            )?.name ?? channel?.name,
        })),
      )
    }
  }, [channelList, combiList])

  useEffect(() => {
    if (buildingData?.buildingGetOne) {
      getChannelList({
        variables: {
          where: {
            buildingId: buildingData.buildingGetOne.id as string,
          },
        },
      })
      getCombiList({
        variables: {
          where: {
            buildingId: buildingData.buildingGetOne.id as string,
          },
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingData])

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, action, index, lifecycle } = data
    if (action === 'next' || action === 'prev') {
      if (index === stepIndex && lifecycle === 'complete') {
        if (index === 7 && lifecycle === 'complete' && action === 'next') {
          onGraphChange('heatmap')
          setRun(false)
        } else if (
          index === 8 &&
          lifecycle === 'complete' &&
          action === 'prev'
        ) {
          onGraphChange('line')
          setRun(false)
          setStepIndex(7)
        }
        setStepIndex(action === 'next' ? index + 1 : index - 1)
      }
    }
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED]
    if (finishedStatuses.includes(status)) {
      setRun(run)
      localStorage.setItem(`${DATA_DEMO_TOUR_FINISHED}-${auth.user.id}`, 'TRUE')
    }
  }

  const onGraphChange = (graphType: 'line' | 'heatmap') => {
    setGraphType(graphType)
    if (graphType === 'heatmap' && selectedChannels.length) {
      if (selectedChannels.length > 1) {
        toast({
          title: 'Have selected the latest channel data',
          description:
            "The heatmap can only show one channel's data at a time.",
          status: 'warning',
          duration: 5000,
          isClosable: true,
        })
      }
      setSelectedChannels([selectedChannels[selectedChannels.length - 1]])
      getChannelDataInBins({
        variables: {
          where: {
            channelId: selectedChannels[selectedChannels.length - 1].id,
          },
        },
      })
    }
  }

  return (
    <>
      <SimpleGrid columns={12}>
        <Joyride
          callback={(e) => handleJoyrideCallback(e)}
          continuous
          run={run}
          scrollToFirstStep
          showProgress
          disableOverlayClose
          showSkipButton
          spotlightClicks
          stepIndex={stepIndex}
          hideCloseButton={stepIndex === 0}
          steps={[
            {
              content: (
                <Heading as={'h6'} size={'md'}>
                  Data Page Tutorial
                </Heading>
              ),
              placement: 'center',
              target: 'body',
            },
            {
              content:
                'This is where you can see the graph data in line graph representation',
              placement: 'bottom',
              target: '.demo_linegraph',
              title: (
                <Heading as={'h5'} size={'md'}>
                  Line Graph
                </Heading>
              ),
            },
            {
              content:
                'This is where you can adjust the graph to zoom in to data',
              placement: 'bottom',
              target: '.demo_brushchart',
              title: (
                <Heading as={'h5'} size={'md'}>
                  Brush Graph
                </Heading>
              ),
            },
            {
              content:
                'You can make target particular period and filter out the data',
              placement: 'bottom',
              target: '.demo_graphfilters',
              title: (
                <Heading as={'h5'} size={'md'}>
                  Filters
                </Heading>
              ),
            },
            {
              content: 'You can download the data either as JPEG or csv',
              placement: 'bottom',
              target: '.demo_graphactions',
              title: (
                <Heading as={'h5'} size={'md'}>
                  Actions
                </Heading>
              ),
            },
            {
              content:
                'All the channels are listed here including composite channels',
              placement: 'bottom',
              target: '.demo_datachanneltable',
              title: (
                <Heading as={'h5'} size={'md'}>
                  Channel List
                </Heading>
              ),
            },
            {
              content:
                'You can select multiple channels (upto 5) to view the data and compare between channels',
              placement: 'bottom',
              target: '.demo_viewhidebtn-1',
              title: (
                <Heading as={'h5'} size={'md'}>
                  Select multiple channels
                </Heading>
              ),
            },
            {
              content:
                'You can select different map to analyse the channel data',
              placement: 'bottom',
              target: '.demo_heatmapselect',
              title: (
                <Heading as={'h5'} size={'md'}>
                  Select different graph type
                </Heading>
              ),
            },
            {
              content:
                'This is where you can see overall values throughout the period selected',
              placement: 'bottom',
              target: '.demo_heatmap',
              title: (
                <Heading as={'h5'} size={'md'}>
                  Heatmap
                </Heading>
              ),
            },
            {
              content:
                'This is where you can adjust the graph to zoom in to data',
              placement: 'bottom',
              target: '.demo_brushchart',
              title: (
                <Heading as={'h5'} size={'md'}>
                  Brush Graph
                </Heading>
              ),
            },
            {
              content: 'Can only select one channel for heatmap',
              placement: 'bottom',
              target: '.demo_viewhidebtn-1',
              title: (
                <Heading as={'h5'} size={'md'}>
                  View Heatmap
                </Heading>
              ),
            },
            {
              content:
                'This is where you can see the locations of the building',
              placement: 'center',
              target: 'body',
              title: (
                <Heading as={'h5'} size={'md'}>
                  Tutorial done
                </Heading>
              ),
            },
          ]}
          locale={{
            last: 'Finish and close the tutorial',
          }}
          styles={{
            options: {
              primaryColor: '#342b6b',
              zIndex: 10000,
            },
          }}
        />
        <GridItem
          colStart={{ base: 1, sm: 1, md: 2, lg: 3 }}
          colSpan={{ base: 12, sm: 12, md: 10, lg: 8 }}
          p={{ base: '5px', md: 0 }}
        >
          <Breadcrumbs links={breadcrumbLinks} />
          <GraphReplacement>
            <BuildingData
              loading={channelListLoading || combiListLoading}
              channels={channels}
              buildingSlug={buildingSlug}
              buildingName={buildingData?.buildingGetOne?.name!}
              buildingId={buildingData?.buildingGetOne?.id!}
              channelListDataLoading={channelListLoading}
              graphType={graphType}
              onGraphChange={onGraphChange}
              selectedChannels={selectedChannels}
              setSelectedChannels={setSelectedChannels}
              channelDataInBins={channelDataInBins}
              getChannelDataInBins={getChannelDataInBins}
              getChannelData={getChannelData}
              channelGraphDataLoading={channelGraphDataLoading}
            />
          </GraphReplacement>
        </GridItem>
      </SimpleGrid>
    </>
  )
}
