import { createIcon } from '@chakra-ui/react'

const Speedo = createIcon({
  displayName: 'Speedo',
  viewBox: '0 0 62 114.147',
  path: (
    <g id="g3792">
      <g id="g3790">
        <g id="g3780">
          <path
            d="m 8.1360,29.0240 c -10.158,17.999 -9.483,39.195 -0.026,56.039 l 48.544,-27.991 z"
            id="path3774"
            style={{ fill: '#f9c138' }}
          />
          <path
            d="m 8.7080,86.1020 c 4.828,8.193 11.746,15.306 20.566,20.405 8.821,5.099 18.437,7.545 27.947,7.64 l 0.032,-56.037 z"
            id="path3776"
            style={{ fill: '#f2253e' }}
          />
          <path
            d="m 57.2860,0 c -19.316,0.21 -38.022,10.203 -48.55,27.987 l 48.519,28.048 z"
            id="path3778"
            style={{ fill: '#91b706' }}
          />
        </g>
        <path
          d="m 36.0350,44.8740 c -6.721,11.626 -2.745,26.5 8.881,33.222 3.835,2.217 8.277,3.247 12.409,3.246 v -48.594 c -8.385,0.013 -16.792,4.344 -21.29,12.126 z"
          id="path3782"
          style={{ fill: '#e8eceb' }}
        />
        <g id="g3788">
          <path
            d="m 61.047,55.253 c 1.296,1.759 0.92,4.236 -0.839,5.531 -1.759,1.296 -4.235,0.92 -5.531,-0.839 -1.296,-1.759 -12.316,-24.504 -12.316,-24.504 0,0 17.39,18.053 18.686,19.812 z"
            id="path3784"
            style={{ fill: '#252525' }}
          />
          <path
            d="m 57.817,55.441 c 1.026,0 1.858,0.832 1.858,1.858 0,1.026 -0.832,1.858 -1.858,1.858 -1.026,0 -1.858,-0.832 -1.858,-1.858 -10e-4,-1.026 0.831,-1.858 1.858,-1.858 z"
            id="path3786"
            style={{ fill: '#ffffff' }}
          />
        </g>
      </g>
    </g>
  ),
})

export default Speedo
