import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride'
import { useNavigate } from 'react-router-dom'
import { FLOW_DEMO_TOUR_FINISHED } from '../../constants'
import { useAuth } from '../../hooks/use-auth'
import { useOnboarding } from '../../onboardingContextProvider'
import { findSlug } from '../../utils/helpers/basicFunc'

const Onboarding = ({ steps }: { steps: Step[] }) => {
  // const [steps, setOnboardingState] = useState<Step[]>(stepsFromComp)

  const { run, stepIndex, setRun, setStepIndex } = useOnboarding()
  const navigate = useNavigate()
  const auth = useAuth()

  const handleJoyrideCallback = (callbackData: CallBackProps) => {
    const { status, step, action, index, lifecycle } = callbackData
    if (action === 'next' || action === 'prev') {
      if (index === stepIndex && lifecycle === 'complete') {
        setStepIndex(action === 'next' ? index + 1 : index - 1)
      }
      if (step?.data?.[action] && lifecycle === 'complete') {
        setRun(false)
        const templateString = step.data[action]
        const url = templateString.replace('{{SLUG}}', findSlug())
        navigate(url)
      }
    }

    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED]
    if (finishedStatuses.includes(status)) {
      setRun(run)
      localStorage.setItem(`${FLOW_DEMO_TOUR_FINISHED}-${auth.user.id}`, 'TRUE')
    }
  }

  return (
    <Joyride
      callback={(e) => handleJoyrideCallback(e)}
      continuous
      run={run}
      scrollToFirstStep
      disableOverlayClose
      hideCloseButton={stepIndex === 0}
      showSkipButton
      stepIndex={stepIndex}
      steps={steps}
      locale={{
        last: 'Finish and close the tutorial',
        skip: 'Skip Tutorial',
      }}
      styles={{
        options: {
          primaryColor: '#342b6b',
          zIndex: 10000,
        },
      }}
    />
  )
}

export default Onboarding
