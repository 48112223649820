import { Box, Flex, Image, Text, useMediaQuery } from '@chakra-ui/react'
import PfHeadline, {
  PfHeadlineItemProps,
} from '../../components/pfHeadline/pfHeadline'
import EnergyDist from '../../elements/building/energyDist'
import EnergyUse from '../../elements/building/energyUse'
import { PortfolioTypes } from '../../graphql/generated/graphql'
import OptiTwinLogoBlack from '../../assets/logo-black.svg'
import { mediaQueries } from '../../utils/mediaQueries/mediaQueries'
import { DashboardDataType } from './dashboardWrapper'

type GraphDashboardProps = {
  headlineItems: PfHeadlineItemProps[]
  period: string
  portfolio: PortfolioTypes
  dashboardData: DashboardDataType
}

const GraphDashboard = ({
  headlineItems,
  period,
  portfolio,
  dashboardData,
}: GraphDashboardProps) => {
  const [is4k, is1440p] = useMediaQuery(mediaQueries)
  return (
    <Box
      minH={{ lg: 1000 }}
      height={{ lg: '100vh' }}
      bg={'gray.300'}
      p={{ base: 4, lg: 8 }}
    >
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={{ base: '20px', '2xl': `20px` }}
        h={'9%'}
      >
        <Image
          src={OptiTwinLogoBlack}
          alt={'logo.png'}
          maxW={{
            base: '90px',
            md: '150px',
            xl: '200px',
            '2xl': is1440p ? '250px' : is4k ? '350px' : '200px',
          }}
        />
        <Text
          color={'dashboard.fontColor'}
          fontSize={{ base: '4vw', lg: '2.89vw' }}
        >
          {dashboardData?.buildingName}
        </Text>
        <Image
          src={
            dashboardData?.occupierLogo
              ? dashboardData?.occupierLogo
              : OptiTwinLogoBlack
          }
          maxW={{
            base: '90px',
            md: '150px',
            xl: '200px',
            '2xl': is1440p ? '250px' : is4k ? '350px' : '200px',
          }}
          maxH={{
            base: 100,
            '2xl': is4k ? '170px' : 100,
          }}
          alt={'occupier.png'}
        />
      </Box>
      <Box h={'25%'} mb={{ lg: 8 }}>
        <PfHeadline
          items={headlineItems}
          period={period}
          loading={false}
          isDashboard={true}
        />
      </Box>
      <Flex
        width={'100%'}
        h={{ base: '62%', '2xl': is4k ? '66%' : is1440p ? '64%' : '62%' }}
        justifyContent={'center'}
        alignItems={'center'}
        flexDir={{ base: 'column', lg: 'row' }}
      >
        <Box
          w={{ base: '100%', lg: '60%' }}
          h={{ base: 400, lg: '100%' }}
          my={{ base: 4, lg: 0 }}
        >
          <Box h={'100%'} mr={{ lg: 10 }}>
            <EnergyUse
              portfolio={portfolio}
              loading={false}
              isDashboard={true}
            />
          </Box>
        </Box>
        <Box w={{ base: '100%', lg: '40%' }} h={{ base: 400, lg: '100%' }}>
          <EnergyDist
            portfolio={portfolio}
            loading={false}
            isDashboard={true}
          />
        </Box>
      </Flex>
    </Box>
  )
}

export default GraphDashboard
