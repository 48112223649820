import { createIcon } from '@chakra-ui/react'

const Question = createIcon({
  displayName: 'Question',
  viewBox: '0 0 51.180698 51.180698',
  path: (
    <g id="g3505">
      <circle
        cx="25.590349"
        cy="25.590349"
        r="25.590349"
        id="circle3497"
        style={{ fill: '#91b706', strokeWidth: 0.97796267 }}
      />
      <g id="g3503">
        <g id="g3501">
          <path
            d="m 28.336349,32.527949 h -7.413 v -2.288 c 0,-6.178 7.001,-7.551 7.001,-11.166 0,-1.419 -0.915,-2.745 -3.02,-2.745 -2.197,0 -2.974,1.647 -3.432,2.608 l -5.995,-2.7 c 0.869,-2.608 3.432,-6.681 9.93,-6.681 5.995,0 10.296,3.615 10.296,9.015 0,6.544 -7.368,8.009 -7.368,12.172 v 1.785 z m -8.283,6.72 c 0,-2.517 2.014,-4.576 4.531,-4.576 2.517,0 4.576,2.059 4.576,4.576 0,2.471 -2.059,4.53 -4.576,4.53 -2.517,0 -4.531,-2.059 -4.531,-4.53 z"
            id="path3499"
            style={{ fill: '#ffffff' }}
          />
        </g>
      </g>
    </g>
  ),
})

export default Question
