import { createIcon } from '@chakra-ui/react'

const Heating = createIcon({
  displayName: 'Heating',
  viewBox: '0 0 194 220',
  path: (
    <g id="g12" transform="translate(-97.334576,-67.000002)">
      <path
        fill="currentColor"
        d="m 195.272,113.749 c 0.787,-14.084 4.846,-21.794 8.26,-22.987 6.374,-2.244 9.715,-9.215 7.471,-15.587 -2.244,-6.325 -9.213,-9.74 -15.587,-7.472 -8.379,2.936 -22.725,12.676 -24.562,44.639 -1.146,20.026 2.936,44.447 11.482,68.722 7.399,20.983 11.076,42.561 10.121,59.199 -0.788,14.084 -4.846,21.794 -8.26,22.987 -6.372,2.244 -9.715,9.213 -7.471,15.587 1.766,5.013 6.493,8.163 11.529,8.163 1.338,0 2.722,-0.214 4.058,-0.692 8.379,-2.936 22.725,-12.674 24.563,-44.637 1.145,-20.026 -2.936,-44.447 -11.482,-68.723 -7.4,-20.982 -11.077,-42.562 -10.122,-59.199 z"
        id="path6"
      />
      <path
        fill="currentColor"
        d="m 121.942,113.749 c 0.787,-14.084 4.846,-21.794 8.26,-22.987 6.374,-2.244 9.715,-9.215 7.471,-15.587 -2.219,-6.325 -9.166,-9.74 -15.587,-7.472 -8.379,2.936 -22.725,12.676 -24.562,44.639 -1.146,20.026 2.936,44.447 11.482,68.722 7.399,20.983 11.076,42.561 10.121,59.199 -0.788,14.084 -4.846,21.794 -8.26,22.987 -6.372,2.244 -9.715,9.213 -7.471,15.587 1.766,5.013 6.493,8.163 11.529,8.163 1.338,0 2.722,-0.214 4.058,-0.692 8.379,-2.936 22.725,-12.674 24.563,-44.637 1.145,-20.026 -2.936,-44.447 -11.482,-68.723 -7.4,-20.982 -11.077,-42.562 -10.122,-59.199 z"
        id="path8"
      />
      <path
        fill="currentColor"
        d="m 268.602,113.749 c 0.787,-14.084 4.845,-21.794 8.26,-22.987 6.374,-2.244 9.715,-9.215 7.471,-15.587 -2.244,-6.325 -9.19,-9.74 -15.587,-7.472 -8.379,2.936 -22.725,12.676 -24.562,44.639 -1.146,20.026 2.936,44.447 11.482,68.722 7.399,20.983 11.076,42.561 10.121,59.199 -0.788,14.084 -4.846,21.794 -8.26,22.987 -6.372,2.244 -9.715,9.213 -7.471,15.587 1.766,5.013 6.493,8.163 11.529,8.163 1.338,0 2.722,-0.214 4.058,-0.692 8.379,-2.936 22.725,-12.674 24.563,-44.637 1.145,-20.026 -2.936,-44.447 -11.482,-68.723 -7.4,-20.982 -11.077,-42.562 -10.122,-59.199 z"
        id="path10"
      />
    </g>
  ),
})

export default Heating
