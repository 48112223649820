import {
  Button,
  Center,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
  FormControl,
  FormLabel,
  useToast,
  Box,
  Text,
  Tooltip,
  SimpleGrid,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { Filter } from 'react-feather'
import { useForm } from 'react-hook-form'
import { camelCaseToTitleCase } from '../../utils/helpers/basicFunc'
import {
  getCategory,
  getCategoryUnits,
  unitCategory,
} from '../../utils/unitMaps/unitCategories'
import { categoryMap, incomingUnitMap } from '../../utils/unitMaps/unitMap'
import { DataQueryParams, SelectedChannel } from './buildingData'

export const FilterData = ({
  setFilterValues,
  filterValues,
  loading,
  graphType,
  selectedChannels,
}: {
  setFilterValues: (arg: DataQueryParams) => void
  filterValues: DataQueryParams
  loading: boolean
  graphType: 'line' | 'heatmap'
  selectedChannels: SelectedChannel[]
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const [localState, setLocalState] = useState<DataQueryParams>(filterValues)
  const { reset, handleSubmit, register } = useForm<DataQueryParams>({
    mode: 'onChange',
  })

  const startTime =
      localState?.startTime && isNaN(localState?.startTime?.getTime()!)
        ? filterValues?.startTime && isNaN(filterValues?.startTime?.getTime()!)
          ? filterValues?.startTime?.toISOString().slice(0, -1)
          : undefined
        : localState?.startTime?.toISOString().slice(0, -1),
    endTime =
      localState?.endTime && isNaN(localState?.endTime?.getTime()!)
        ? filterValues?.endTime && isNaN(filterValues?.endTime?.getTime()!)
          ? filterValues?.endTime?.toISOString().slice(0, -1)
          : undefined
        : localState?.endTime?.toISOString().slice(0, -1)

  const isValidateDateRange = () => {
    const { startTime, endTime } = localState
    if (startTime && endTime && startTime > endTime) {
      return false
    } else return true
  }

  const onSubmit = (data: DataQueryParams) => {
    if (isValidateDateRange()) {
      setFilterValues({
        ...data,
        startTime: data.startTime ? new Date(data.startTime) : undefined,
        endTime: data.endTime ? new Date(data.endTime) : undefined,
      })
      onClose()
    } else {
      toast({
        title: 'Please select a valid start and end time',
        status: 'error',
        position: 'top',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  const onReset = () => {
    setFilterValues({})
    onClose()
    reset()
    setLocalState({})
  }

  const makeUnitElement = () => {
    const categories = selectedChannels
      .map((channel) => getCategory(channel.incomingUnit)) // Get categories
      .filter(
        (category, index, array) =>
          array.indexOf(category) === index &&
          category !== unitCategory.boolean &&
          category !== unitCategory.airQuality,
      ) // Remove duplicates and unwanted categories
    categories.sort() // Sort alphabetically

    if (categories.length === 0) {
      return (
        <>
          <FormLabel marginTop={5}>Units</FormLabel>
          <i>No channels selected</i>
        </>
      )
    }

    const categoryElements: JSX.Element[] = categories.map(
      (category, index) => {
        if (
          category === unitCategory.boolean ||
          category === unitCategory.airQuality
        ) {
          return <React.Fragment key={index}></React.Fragment>
        }

        const affectedChannels = selectedChannels
          .filter((channel) => getCategory(channel.incomingUnit) === category)
          .map((channel, index) => (
            <Text key={index}>
              <i>{channel.name}</i>
            </Text>
          ))
          .sort()

        return (
          <FormControl
            key={index}
            // border={categories.length > 1 ? '1px solid lightgrey' : undefined}
            padding={categories.length > 1 ? 2 : undefined}
          >
            {/* <FormControl key={index} border={"1px solid lightgrey"} padding={2}> */}
            <FormLabel>
              <Tooltip
                hasArrow
                label={[
                  <Text key="title">Channels affected:</Text>,
                  ...affectedChannels,
                ]}
                placement="right"
              >
                <Text display={'inline-block'}>
                  {categoryMap.find((item) => item.code === category)?.name ??
                    camelCaseToTitleCase(category)}{' '}
                  Channels
                </Text>
              </Tooltip>
            </FormLabel>
            <Select {...register(category)}>
              {getCategoryUnits(category).map((unit, index) => {
                const name = incomingUnitMap.find(
                  (item) => item.code === unit,
                )?.name
                if (!name) {
                  return <React.Fragment key={index}></React.Fragment>
                }
                return (
                  <option value={unit} key={index}>
                    {name}
                  </option>
                )
              })}
            </Select>
          </FormControl>
        )
      },
    )

    return (
      <>
        <FormLabel marginTop={5}>Units</FormLabel>
        <SimpleGrid columns={2} gap={2}>
          {categoryElements}
        </SimpleGrid>
      </>
    )
  }

  return (
    <>
      <Button
        onClick={onOpen}
        mr={5}
        colorScheme={'blue'}
        className={'demo_graphfilters'}
      >
        <Filter />
      </Button>

      <Modal
        onClose={() => {
          onClose()
          setLocalState({ period: 'none' })
        }}
        isOpen={isOpen}
        isCentered
        size={'xl'}
      >
        <ModalOverlay />
        <ModalContent bg={'white'}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>Configure Data</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Center mb={5} gap={4}>
                <FormControl id="startTime">
                  <FormLabel>Start Date</FormLabel>
                  <Input
                    type="dateTime-local"
                    {...register('startTime')}
                    onChange={(e) =>
                      setLocalState({
                        ...localState,
                        startTime: new Date(e.target.value),
                      })
                    }
                    value={startTime}
                  />
                </FormControl>
                <FormControl id="endTime">
                  <FormLabel>End Date</FormLabel>
                  <Input
                    type="dateTime-local"
                    {...register('endTime')}
                    onChange={(e) =>
                      setLocalState({
                        ...localState,
                        endTime: new Date(e.target.value),
                      })
                    }
                    value={endTime}
                  />
                </FormControl>
              </Center>

              <FormControl id="period">
                <FormLabel>Period</FormLabel>
                <Select
                  placeholder="Select Period"
                  {...register('period')}
                  onChange={(e) =>
                    setLocalState({
                      ...localState,
                      period: e.target.value,
                    })
                  }
                  disabled={graphType === 'heatmap'}
                  value={localState.period ?? filterValues.period}
                >
                  <option value="none">None</option>
                  <option value="hourly">Hourly</option>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
                </Select>
              </FormControl>
              {makeUnitElement()}
            </ModalBody>
            <ModalFooter justifyContent={'space-between'}>
              <Button onClick={onReset} mr={5}>
                Reset
              </Button>
              <Box>
                <Button
                  onClick={() => {
                    reset()
                    onClose()
                    setLocalState({})
                  }}
                  mr={5}
                >
                  Cancel
                </Button>
                <Button
                  type={'submit'}
                  colorScheme={'blue'}
                  isLoading={loading}
                >
                  Submit
                </Button>
              </Box>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}

export default FilterData
