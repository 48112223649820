import { Box, Center, Heading, useMediaQuery } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { mediaQueries } from '../../utils/mediaQueries/mediaQueries'

const GraphReplacement = ({
  children,
  text = 'For the best experience, please use a larger screen to view this content',
}: {
  children: ReactNode
  text?: string
}) => {
  const [, , , , isTab, isMobile] = useMediaQuery(mediaQueries)
  return (
    <Box position={'relative'}>
      <Center
        position={'absolute'}
        w={'100%'}
        h={'100%'}
        bg={'rgb(255 255 255 / 90%)'}
        zIndex={1}
        display={isTab || isMobile ? 'flex' : 'none'}
      >
        <Heading textAlign={'center'}>{text}</Heading>
      </Center>
      {children}
    </Box>
  )
}

export default GraphReplacement
