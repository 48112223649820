import {
  Button,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Select,
  useDisclosure,
  FormLabel,
  FormControl,
  useToast,
} from '@chakra-ui/react'
import { PropsWithChildren } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useEmailFullBuildingLazyQuery } from '../../../graphql/generated/graphql'

interface EmailPopoverProps {
  buildingId: string
}

export const EmailPopover = (props: PropsWithChildren<EmailPopoverProps>) => {
  const { t } = useTranslation('building')
  const toast = useToast()

  const { onOpen, onClose, isOpen } = useDisclosure()
  const { handleSubmit, register } = useForm()

  const [sendEmail, { loading: sendEmailLoader }] =
    useEmailFullBuildingLazyQuery({
      onCompleted: () => {
        onClose()
        toast({
          title: t('emailSentResult'),
          status: 'success',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
      },
      onError: (err) => {
        toast({
          title: err.message,
          status: 'error',
          position: 'top',
          duration: 3000,
          isClosable: true,
        })
      },
    })

  const onSubmit = (values: FieldValues) => {
    sendEmail({
      variables: {
        where: {
          buildingId: props.buildingId,
          period: values.period,
          startTime: values.startTime ? new Date(values.startTime) : undefined,
          endTime: values.endTime ? new Date(values.endTime) : undefined,
        },
      },
    })
  }

  return (
    <Popover
      onOpen={onOpen}
      onClose={onClose}
      isOpen={isOpen}
      placement={'top-start'}
    >
      <PopoverTrigger>{props.children}</PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow backgroundColor={'white'} />
          <PopoverCloseButton />
          <PopoverHeader backgroundColor={'white'}>Options</PopoverHeader>
          <PopoverBody backgroundColor={'white'} textAlign={'right'}>
            <form
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormControl>
                <FormLabel>Period</FormLabel>
                <Select defaultValue={'hourly'} {...register('period')}>
                  <option value="none">Raw</option>
                  <option value="hourly">Hourly</option>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel whiteSpace={'nowrap'}>Start time</FormLabel>
                <Input type="date" {...register('startTime')} />
              </FormControl>
              <FormControl>
                <FormLabel whiteSpace={'nowrap'}>End time</FormLabel>
                <Input type="date" {...register('endTime')} />
              </FormControl>
              {/* <Box display={"flex"} gap={"2"} alignItems={"baseline"}>
                
              </Box> */}
              <Button
                colorScheme={'blue'}
                variant={'solid'}
                type="submit"
                isLoading={sendEmailLoader}
              >
                Send
              </Button>
            </form>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
