import React, { createContext, ReactNode, useContext, useState } from 'react'
// Define the shape of the Onboarding context state
interface OnboardingContextProps {
  run: boolean
  setRun: React.Dispatch<React.SetStateAction<boolean>>
  stepIndex: number
  setStepIndex: React.Dispatch<React.SetStateAction<number>>
}

// Provide a default value for the context
const defaultContextValue: OnboardingContextProps = {
  run: false,
  setRun: () => {},
  stepIndex: 0,
  setStepIndex: () => {},
}
// Create a Context
const OnboardingContext =
  createContext<OnboardingContextProps>(defaultContextValue)

interface OnboardingContextProviderProps {
  children: ReactNode // Define children prop type
}

// Define a provider component
export const OnboardingContextProvider = ({
  children,
}: OnboardingContextProviderProps) => {
  // Onboarding state (shared across the app)
  const [run, setRun] = useState(false)
  const [stepIndex, setStepIndex] = useState(0)

  // Provide state and setters to the children
  return (
    <OnboardingContext.Provider
      value={{
        run,
        setRun,
        stepIndex,
        setStepIndex,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  )
}

// Hook to use OnboardingContext in other components
export const useOnboarding = () => useContext(OnboardingContext)
