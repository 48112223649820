import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import { DateTime } from 'luxon'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true, //process.env.NODE_ENV === 'development',
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
  })

i18n.services.formatter?.add('DATE_HUGE', (value, lng, options) => {
  return DateTime.fromJSDate(value)
    .setLocale(lng ?? 'en')
    .toLocaleString(DateTime.DATE_HUGE)
})

export default i18n
