import { Box } from '@chakra-ui/react'
import { Step } from 'react-joyride'
import { Outlet, useLocation } from 'react-router-dom'
import Footer from '../components/footer/footer'
import NavBar from '../components/navBar/navBar'
import Onboarding from '../components/onboarding/onboarding'

const Root = ({ steps }: { steps: Step[] }) => {
  const location = useLocation(),
    { pathname } = location
  return (
    <Box display={'flex'} flexDir={'column'} h={'100%'}>
      <Onboarding steps={steps} />
      {!pathname.includes('dashboard') && <NavBar />}
      <Box flexGrow={1}>
        <Outlet />
      </Box>
      {!pathname.includes('dashboard') && <Footer />}
    </Box>
  )
}

export default Root
