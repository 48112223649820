import {
  DataGetManyQuery,
  DataInBins,
  Datum,
} from '../../graphql/generated/graphql'
import { unknownUnit } from '../unitMaps/unitEnums'

export function minMaxTimeStamp(selectedChannelData: DataGetManyQuery[]) {
  const allData = selectedChannelData.flatMap(
    (channel) => channel?.dataGetMany.data,
  )

  // Use the reduce function to find the minimum and maximum timestamps
  const { minTimestamp, maxTimestamp } = allData.reduce(
    (acc, item) => {
      const timestamp = new Date(item.timestamp as Date).getTime()

      if (!acc.minTimestamp || timestamp < acc.minTimestamp) {
        acc.minTimestamp = timestamp
      }

      if (!acc.maxTimestamp || timestamp > acc.maxTimestamp) {
        acc.maxTimestamp = timestamp
      }

      return acc
    },
    { minTimestamp: 0, maxTimestamp: 0 },
  )

  return {
    minTimestamp: new Date(minTimestamp),
    maxTimestamp: new Date(maxTimestamp),
  }
}

export function flattenGraphData(selectedChannelData: DataGetManyQuery[]) {
  return selectedChannelData.flatMap((channel) => channel?.dataGetMany.data)
}

export function filterDataByTimestamp({
  selectedChannelData,
  startDate,
  endDate,
}: {
  selectedChannelData: DataGetManyQuery[]
  startDate: number
  endDate: number
}) {
  return selectedChannelData.map(({ dataGetMany }) => ({
    dataGetMany: {
      ...dataGetMany,
      data: dataGetMany.data.filter(({ timestamp }) => {
        const timestampDate = new Date(timestamp!)
        return (
          timestampDate.getTime() >= startDate &&
          timestampDate.getTime() <= endDate
        )
      }),
    },
  }))
}

export const convertDataStructure = (
  data: DataInBins[],
): DataGetManyQuery[] => {
  const result: Datum[] = []

  data.forEach((binData) => {
    binData.bins.forEach((binItem, i) => {
      const currentDate = new Date(binItem.timestamp!).setUTCHours(0),
        previousDate =
          i - 1 > -1
            ? new Date(binData.bins[i - 1].timestamp!).setUTCHours(0)
            : null,
        currentValue = binItem.value,
        previousValue = i - 1 > -1 ? binData.bins[i - 1].value : 0
      if (currentDate === previousDate) {
        let updatedResult = result.find(
            (res) => res.timestamp?.valueOf() === currentDate,
          ),
          foundIndex = result.findIndex(
            (res) => res.timestamp?.valueOf() === currentDate,
          )
        updatedResult = {
          ...updatedResult,
          value:
            i - 1 > -1 ? (currentValue! + previousValue!) / 2 : currentValue,
        }
        result[foundIndex] = updatedResult
      } else {
        result.push({
          id: binItem.id ?? 'test' + i,
          channelId: binItem.channelId ?? 'random',
          timestamp: new Date(currentDate),
          value: binItem.value,
          status: binItem.status ?? 'Processed',
        })
      }
    })
  })

  return [
    {
      dataGetMany: {
        data: result.sort((a, b) => {
          const dateA: Date = new Date(a.timestamp!)
          const dateB: Date = new Date(b.timestamp!)
          // }
          return dateA.getTime() - dateB.getTime()
        }),
        id: 'chanId for heatmap',
        name: 'heatmap',
        unit: unknownUnit.unknown,
      },
    },
  ]
}

export function convertHourlyToDaily(selectedChannelData: DataGetManyQuery[]) {
  let convertedData = structuredClone(selectedChannelData)
  convertedData = convertedData.map((channelDatum) => {
    let newData: Datum[] = []
    let data = channelDatum.dataGetMany.data
    data.forEach((datum, i) => {
      if (
        i > 0 &&
        datum.timestamp?.toString().split('T')[0] ===
          data[i - 1].timestamp?.toString().split('T')[0]
      ) {
        newData = newData.map((newDatum) =>
          newDatum.timestamp?.toString().split('T')[0] ===
          data[i - 1].timestamp?.toString().split('T')[0]
            ? {
                ...newDatum,
                value: ((datum.value ?? 0) + (newDatum.value ?? 0)) / 2,
              }
            : newDatum,
        )
      } else {
        newData.push({
          ...datum,
          timestamp: new Date(
            new Date(datum.timestamp!).setHours(0, 0, 0, 0),
          ).toISOString() as unknown as Date,
        })
      }
    })

    return {
      dataGetMany: {
        ...channelDatum.dataGetMany,
        data: newData,
      },
    }
  })

  return convertedData
}
