import { DeleteIcon } from '@chakra-ui/icons'
import {
  Center,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import EditableControls from '../../components/editableTable/editableControls'
import InfoAlert from '../../components/infoAlert/infoAlert'
import { GET_TARGET_ALERTS } from '../../graphql/API/queries'
import {
  TargetAlert,
  useTargetAlertDeleteMutation,
  useTargetAlertsGetManyLazyQuery,
  useTargetAlertUpdateManyMutation,
  useTargetAlertUpdateMutation,
} from '../../graphql/generated/graphql'
import {
  periods,
  tableDataConstruct,
  TableFields,
  tableHeadings,
} from '../../utils/tableDataConstruct/tableDataConstruct'
import CreateTargetModal from './createTargetModal'

export type TargetAlertsProps = {
  isOpen: boolean
  onClose: () => void
  channelId: string
  channelName: string
}

const TargetAlerts = (props: TargetAlertsProps) => {
  const { isOpen, onClose, channelId, channelName } = props

  const toast = useToast()
  const toastFunc = (props: { status: 'success' | 'error'; title: string }) => {
    const { title, status } = props
    toast({
      title,
      status,
      position: 'top',
      duration: 3000,
      isClosable: true,
    })
  }
  const refetchQueries = [
    { query: GET_TARGET_ALERTS, variables: { where: { channelId } } },
  ]

  //QUERIES
  const [getTargetAlerts, { data: targetAlertData }] =
    useTargetAlertsGetManyLazyQuery()

  //MUTATIONS
  const [updateTargetAlert] = useTargetAlertUpdateMutation({
    refetchQueries,
    onCompleted: () => {
      toastFunc({ title: 'Successfully edited the target', status: 'success' })
    },
    onError: (err) => {
      toastFunc({ title: err.message, status: 'error' })
    },
  })
  const [updateTargetAlertMultiple] = useTargetAlertUpdateManyMutation({
    refetchQueries,
    onCompleted: () => {
      toastFunc({ title: 'Successfully edited the target', status: 'success' })
    },
    onError: (err) => {
      toastFunc({ title: err.message, status: 'error' })
    },
  })
  const [deleteTargets, { loading: deleteLoader }] =
    useTargetAlertDeleteMutation({
      refetchQueries,
      onCompleted: () => {
        setDeleteConfirmModalVisibility(false)
        toastFunc({ title: 'Deleted successfully', status: 'success' })
      },
      onError: (err) => {
        toastFunc({ title: err.message, status: 'error' })
      },
    })

  //USESTATES
  const [targetAlerts, setTargetAlerts] = useState<TargetAlert[]>([])
  const [deleteConfirmModalVisibility, setDeleteConfirmModalVisibility] =
    useState(false)
  const [clickedElement, setClickedElement] = useState<TargetAlert>()

  //USEEFFECT
  useEffect(() => {
    if (isOpen) {
      getTargetAlerts({ variables: { where: { channelId } } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (targetAlertData?.targetAlertsGetMany.length) {
      const targetTableData = tableDataConstruct({
        data: targetAlertData.targetAlertsGetMany,
        page: 'targets',
      }) as TargetAlert[]
      setTargetAlerts(targetTableData)
    } else {
      setTargetAlerts([])
    }
  }, [targetAlertData?.targetAlertsGetMany])

  //FUNCTIONS
  const onEditSubmit = (props: {
    value: string | number
    fieldName: string
    targetId?: string
    targetIds?: string[]
  }) => {
    const { fieldName, value, targetId, targetIds } = props
    if (periods.includes(fieldName) && targetId) {
      updateTargetAlert({
        variables: { data: { id: targetId, targetValue: +value } },
      })
    } else if (targetIds) {
      updateTargetAlertMultiple({
        variables: { data: { targetIds, name: `${value}` } },
      })
    }
  }

  const onSelectChange = (props: { value: string; targetIds?: string[] }) => {
    const { targetIds, value } = props
    if (targetIds) {
      updateTargetAlertMultiple({
        variables: { data: { targetIds, triggerCondition: `${value}` } },
      })
    }
  }

  const CustomEditable = (props: {
    value: string
    fieldName: string
    targetId?: string
    targetIds?: string[]
  }) => {
    const { value, targetId, fieldName } = props
    return (
      <Editable
        defaultValue={value}
        onSubmit={(value) =>
          onEditSubmit({
            value,
            fieldName,
            targetId,
          })
        }
        submitOnBlur={false}
        selectAllOnFocus={false}
      >
        <Flex alignItems={'center'} justifyContent={'space-between'}>
          <Tooltip label="Click to edit" shouldWrapChildren={true}>
            <EditablePreview
              p={1}
              cursor={'pointer'}
              _hover={{
                background: 'gray.100',
              }}
            />
          </Tooltip>
          <Input as={EditableInput} type={targetId ? 'number' : 'text'} />
          <EditableControls />
        </Flex>
      </Editable>
    )
  }

  const getTargetIds = (target: TargetAlert): string[] | undefined => {
    if (
      target.daily?.targetId &&
      target.weekly?.targetId &&
      target.monthly?.targetId &&
      target.yearly?.targetId
    ) {
      return [
        target.daily.targetId,
        target.weekly.targetId,
        target.monthly.targetId,
        target.yearly.targetId,
      ]
    }
  }

  const getTableElements = (props: {
    target: TargetAlert
    heading: TableFields
  }) => {
    const { target, heading } = props
    const targetIds = getTargetIds(target)
    switch (heading.fieldName) {
      case 'daily':
        return (
          <CustomEditable
            value={`${target.daily?.targetValue ?? 0}`}
            targetId={`${target.daily?.targetId}`}
            fieldName={heading.fieldName}
          />
        )
      case 'weekly':
        return (
          <CustomEditable
            value={`${target.weekly?.targetValue ?? 0}`}
            targetId={`${target.weekly?.targetId}`}
            fieldName={heading.fieldName}
          />
        )
      case 'monthly':
        return (
          <CustomEditable
            value={`${target.monthly?.targetValue ?? 0}`}
            targetId={`${target.monthly?.targetId}`}
            fieldName={heading.fieldName}
          />
        )
      case 'yearly':
        return (
          <CustomEditable
            value={`${target.yearly?.targetValue ?? 0}`}
            targetId={`${target.yearly?.targetId}`}
            fieldName={heading.fieldName}
          />
        )
      case 'name':
        return (
          <CustomEditable
            value={target.name}
            fieldName={heading.fieldName}
            targetIds={targetIds}
          />
        )
      case 'triggerCondition':
        return (
          <Select
            defaultValue={target.triggerCondition ?? 'none'}
            onChange={(e) =>
              onSelectChange({
                value: e.target.value,
                targetIds: targetIds,
              })
            }
          >
            {heading.fieldOptions?.map((option, i) => (
              <option key={i} value={option.value}>
                {option.text}
              </option>
            ))}
          </Select>
        )
      default:
        return ''
    }
  }

  const onDeleteClick = () => {
    if (clickedElement) {
      const targetIds = getTargetIds(clickedElement)
      if (targetIds) {
        deleteTargets({ variables: { where: { targetIds } } })
      }
    }
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
        <ModalOverlay />
        <ModalContent bg={'white'}>
          <ModalHeader>
            <Heading>Targets - {channelName}</Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center mb={5} justifyContent={'end'}>
              <CreateTargetModal channelId={channelId} />
            </Center>
            {!targetAlerts.length ? (
              <Center>
                <Heading>No Data</Heading>
              </Center>
            ) : (
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      {tableHeadings['targets'].map((heading, i) => (
                        <Th key={i}>{heading.fieldName}</Th>
                      ))}
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {targetAlerts.map((target, i) => (
                      <Tr key={i}>
                        {tableHeadings['targets'].map((heading, i) => (
                          <Td key={i}>
                            {getTableElements({ target, heading })}
                          </Td>
                        ))}
                        <Td>
                          {!target.isDashboardDefault && (
                            <IconButton
                              aria-label="delete"
                              icon={<DeleteIcon />}
                              colorScheme="red"
                              variant="outline"
                              onClick={() => {
                                setClickedElement(target)
                                setDeleteConfirmModalVisibility(true)
                              }}
                            />
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </ModalBody>
        </ModalContent>
        <InfoAlert
          isOpen={deleteConfirmModalVisibility}
          onClose={() => setDeleteConfirmModalVisibility(false)}
          onSubmit={() => onDeleteClick()}
          headerText={`Delete ${clickedElement?.name}`}
          bodyText={"Are you sure? You can't undo this action afterwards."}
          buttonText={'Delete'}
          loading={deleteLoader}
        />
      </Modal>
    </>
  )
}

export default TargetAlerts
