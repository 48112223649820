type ColorCardProps = {
  measurement: number | undefined
  target: number | undefined
  upIsBetter?: boolean | undefined
}

export const colorCard = ({
  measurement = 0,
  target,
  upIsBetter = false,
}: ColorCardProps) => {
  if (!target) {
    return 'neutral'
  }

  const ratio = measurement / target

  if (ratio < 0.95) {
    return upIsBetter ? 'danger' : 'success'
  } else if (ratio < 1.05) {
    return upIsBetter ? 'success' : 'warn'
  } else {
    return upIsBetter ? 'success' : 'danger'
  }
}
