import { Box, Text } from '@chakra-ui/react'
import { LegendOrdinal, LegendItem, LegendLabel } from '@visx/legend'

import { scaleOrdinal } from '@visx/scale'
import { anyUnit, unknownUnit } from '../../utils/unitMaps/unitEnums'
import { incomingUnitMap } from '../../utils/unitMaps/unitMap'

export type LegendChannel = {
  id: string
  name: string
  unit: anyUnit
}

type LegendProps = {
  legendChannels: LegendChannel[]
  graphColors: string[]
  size: number
}

export default function Legend({
  legendChannels,
  graphColors,
  size,
}: LegendProps) {
  const ordinalColorScale = scaleOrdinal({
    domain: legendChannels,
    range: graphColors,
  })
  legendChannels.sort((a, b) => a.unit.localeCompare(b.unit))
  return (
    <LegendOrdinal
      scale={ordinalColorScale}
      labelFormat={(label) => `${label.name.toUpperCase()}`}
    >
      {(labels) => (
        <div style={{ display: 'flex' }}>
          {labels.map(
            (label, i) =>
              !!label.datum && (
                <LegendItem
                  key={`legend-quantile-${i}`}
                  margin="0 5px"
                  // onClick={() => {
                  //   if (events) alert(`clicked: ${JSON.stringify(label)}`)
                  // }}
                >
                  <svg
                    width={size}
                    height={size}
                    style={{ margin: '2px 0', flexShrink: 0 }}
                  >
                    <circle
                      fill={label.value}
                      r={size / 2}
                      cx={size / 2}
                      cy={size / 2}
                    />
                  </svg>
                  <LegendLabel
                    align="left"
                    style={{ fontSize: size, margin: '3px' }}
                  >
                    <Box pl={1}>
                      <Text fontSize={size + 2}>{label.datum.name}</Text>
                      <Text>
                        {label.datum.unit === unknownUnit.unknown
                          ? `No unit`
                          : incomingUnitMap.find(
                              (item) => item.code === label.datum.unit,
                            )?.name}
                      </Text>
                    </Box>
                  </LegendLabel>
                </LegendItem>
              ),
          )}
        </div>
      )}
    </LegendOrdinal>
  )
}
