import { unknownUnit } from '../../utils/unitMaps/unitEnums'

const generateRandomdGraphData = () => {
  const dataArray = []
  for (let i = 0; i < 25; i++) {
    const randomValue = Math.floor(Math.random() * 10) + 1 // Generate random value between 1 and 10
    const id = `${i}`
    const channelId = `${i}`
    const timestamp = getRandomTimestamp()

    dataArray.push({
      id,
      channelId,
      timestamp,
      value: randomValue,
      status: 'Processed',
    })
  }
  return dataArray.sort(
    (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
  )
}

function getRandomTimestamp() {
  const startTimestamp = new Date('2023-03-01').getTime() // Timestamp of March 1, 2023
  const endTimestamp = new Date('2023-11-30').getTime() // Timestamp of November 30, 2023
  const randomTime =
    startTimestamp + Math.random() * (endTimestamp - startTimestamp)
  return new Date(randomTime)
}

export const staticGraphData = [
  {
    dataGetMany: {
      id: '2',
      name: 'Sample Building',
      unit: unknownUnit.unknown,
      data: generateRandomdGraphData(),
    },
  },
]
