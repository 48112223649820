import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import { Check } from 'react-feather'
import { useForm } from 'react-hook-form'
import { useCheckAllDataMutation } from '../../graphql/generated/graphql'
import InfoAlert from '../infoAlert/infoAlert'

type FormProps = {
  type: 'cumulative' | 'independent' | 'actual' | 'virtual' | 'shadow'
  startTime?: Date
  endTime?: Date
}

export const DataRecalculate = (): JSX.Element => {
  const toast = useToast()
  const toastSuccess = (message: string) =>
    toast({
      title: message,
      status: 'success',
      position: 'top',
      duration: 3000,
      isClosable: true,
    })
  const toastFail = (message: string) =>
    toast({
      title: message,
      status: 'error',
      position: 'top',
      duration: 3000,
      isClosable: true,
    })

  const [confirmVisibility, setConfirmVisibility] = useState(false)
  const { handleSubmit, register, getValues: formValues } = useForm<FormProps>()

  const [sendCheckAllData, { loading: awaitingCheck }] =
    useCheckAllDataMutation()

  const submitCheckAllData = (props: FormProps) => {
    setConfirmVisibility(false)
    sendCheckAllData({
      variables: {
        data: {
          measurementType:
            props.type === 'cumulative' || props.type === 'independent'
              ? props.type
              : undefined,
          channelType:
            props.type === 'actual' ||
            props.type === 'virtual' ||
            props.type === 'shadow'
              ? props.type
              : undefined,
          startTime: props.startTime ? new Date(props.startTime) : undefined,
          endTime: props.endTime ? new Date(props.endTime) : undefined,
        },
      },
      onCompleted: () => toastSuccess('Successfully started data recheck'),
      onError: (error) => toastFail(error.message),
    })
  }

  return (
    <>
      <form
        style={{ display: 'flex', gap: '8px', alignItems: 'end' }}
        onSubmit={handleSubmit(() => {
          setConfirmVisibility(true)
        })}
      >
        <FormControl>
          <FormLabel>Recalculate Channels of Type</FormLabel>
          <Select
            {...register('type', {
              required: true,
            })}
          >
            <option value="cumulative">'Cumulative' Measurement</option>
            <option value="independent">'Independent' Measurement</option>
            <option value="actual">'Actual' Channel</option>
            <option value="virtual">'Virtual' Channel</option>
            <option value="shadow">'Shadow' Channel</option>
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel whiteSpace={'nowrap'}>Start Time</FormLabel>
          <Input
            type="date"
            {...register('startTime', {
              required: false,
            })}
          />
        </FormControl>
        <FormControl>
          <FormLabel whiteSpace={'nowrap'}>End Time</FormLabel>
          <Input
            type="date"
            {...register('endTime', {
              required: false,
            })}
          />
        </FormControl>
        <Button
          isLoading={awaitingCheck}
          leftIcon={<Check />}
          iconSpacing={0}
          type={'submit'}
        />
      </form>
      <InfoAlert
        isOpen={confirmVisibility}
        onClose={() => setConfirmVisibility(false)}
        onSubmit={() => submitCheckAllData(formValues())}
        headerText={`Trigger recalculate for all ${formValues().type} channels`}
        bodyText={`Are you sure? This is an expensive operation, and may take some time`}
        buttonText={'Confirm'}
        loading={awaitingCheck}
      />
    </>
  )
}
