import {
  Grid,
  GridItem,
  useBreakpointValue,
  useMediaQuery,
} from '@chakra-ui/react'
import { mediaQueries } from '../../../utils/mediaQueries/mediaQueries'

import IconBlock from './iconBlock'

// import { mediaQueries } from '../../../utils/mediaQueries/mediaQueries'

type FeedbackTileType = {
  contentSelection: string
  message: string | undefined
}

type TileDetailsType = {
  [key: string]: {
    iconLeft: string
    textLeft: string
  }
}

const tileDetails: TileDetailsType = {
  'tile-1': {
    iconLeft: 'Question',
    textLeft: 'How are we doing',
  },
  'tile-2': {
    iconLeft: 'Info',
    textLeft: 'Why not...',
  },
}

const FeedbackTile = ({ contentSelection, message }: FeedbackTileType) => {
  const [is4k, is1440p] = useMediaQuery(mediaQueries)
  const titleFontSize = useBreakpointValue({
    base: '3.8vw',
    lg: '1.7vw',
    '2xl': '1.6vw',
  })
  const descFontSize = useBreakpointValue({
    base: '2.6vw',
    lg: '1.2vw',
    '2xl': is1440p ? '1.2vw' : is4k ? '1.4vw' : '1.2vw',
  })
  const primaryIconSize = useBreakpointValue({
    base: '6.8vw',
    md: '6vw',
    lg: '2.5vw',
  })
  const secondaryIconWidth = useBreakpointValue({
    base: '7vw',
    md: '5.5vw',
    lg: '4vw',
    '2xl': is1440p ? '4vw' : is4k ? '4vw' : '2.7vw',
  })
  const secondaryIconHeight = useBreakpointValue({
    base: '13vw',
    md: '10vw',
    lg: '6vw',
    '2xl': is1440p ? '6vw' : is4k ? '6vw' : '5vw',
  })
  return (
    <Grid
      templateColumns={'repeat(3, 1fr)'}
      gap={{
        base: '10px',
        '2xl': is1440p ? '10px' : is4k ? '8px' : '10px',
      }}
      height={'100%'}
      padding={{
        base: '10px 15px',
        '2xl': is1440p ? '30px' : is4k ? '60px' : '10px 15px',
      }}
    >
      {/* <GridItem>
        <IconBlock
          fontWeight={'bold'}
          fontSize={titleFontSize}
          width={primaryIconSize}
          iconType={tileDetails[contentSelection].iconLeft}
          text={tileDetails[contentSelection].textLeft}
        />
      </GridItem>
      <GridItem colSpan={2}>
        <IconBlock
          fontWeight={'400'}
          fontSize={descFontSize}
          width={secondaryIconWidth}
          height={secondaryIconHeight}
          iconType={contentSelection === 'tile-1' ? 'Speedo' : undefined}
          text={message}
        />
      </GridItem> */}
      <GridItem>
        <IconBlock
          fontWeight={'bold'}
          fontSize={titleFontSize}
          width={primaryIconSize}
          iconType={tileDetails[contentSelection].iconLeft}
          text={tileDetails[contentSelection].textLeft}
        />
      </GridItem>
      <GridItem colSpan={2}>
        <IconBlock
          fontWeight={'400'}
          fontSize={descFontSize}
          width={secondaryIconWidth}
          height={secondaryIconHeight}
          iconType={contentSelection === 'tile-1' ? 'Speedo' : undefined}
          text={message}
        />
      </GridItem>
    </Grid>
  )
}

export default FeedbackTile
