import { createIcon } from '@chakra-ui/react'

const CO2 = createIcon({
  displayName: 'Carbon Dioxide Emissions',
  viewBox: '0 0 214 133',
  path: (
    <g id="g10" transform="translate(-75.422,-133.858)">
      <path
        fill="currentColor"
        d="m 75.422,180.295 c 0,-26.109 19.518,-46.008 46.264,-46.008 11.787,0 20.658,3.93 26.363,8.112 l -5.07,15.717 c -4.309,-3.041 -12.293,-7.099 -21.292,-7.099 -16.098,0 -27.632,12.548 -27.632,29.278 0,16.985 11.534,29.278 27.632,29.278 9.633,0 17.87,-4.309 22.054,-7.223 l 5.07,15.59 c -5.324,3.927 -14.197,8.363 -27.124,8.363 -27.761,0.001 -46.265,-19.899 -46.265,-46.008 z"
        id="path4"
      />
      <path
        fill="currentColor"
        d="m 156.274,180.295 c 0,-25.854 18.885,-46.008 46.136,-46.008 27.251,0 46.136,20.154 46.136,46.008 0,26.109 -18.885,46.008 -46.136,46.008 -27.251,0 -46.136,-19.899 -46.136,-46.008 z m 18.632,0 c 0,16.985 11.534,29.278 27.505,29.278 15.971,0 27.505,-12.293 27.505,-29.278 0,-16.73 -11.534,-29.278 -27.505,-29.278 -15.971,0 -27.505,12.548 -27.505,29.278 z"
        id="path6"
      />
      <path
        fill="currentColor"
        d="m 255.436,263.165 c 0,-14.78 22.834,-23.499 22.834,-33.029 0,-3.844 -2.513,-6.357 -6.651,-6.357 -4.212,0 -6.355,2.809 -7.315,6.06 l -9.089,-2.663 c 1.181,-5.172 5.616,-13.002 16.846,-13.002 9.976,0 16.848,6.057 16.848,15.515 0,13.895 -19.286,20.544 -21.133,28.082 H 289.5 v 9.087 h -34.064 z"
        id="path8"
      />
    </g>
  ),
})

export default CO2
