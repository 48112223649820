import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Link,
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Textarea,
  Tooltip,
} from '@chakra-ui/react'
import { ChangeEvent, useState } from 'react'
import { CheckboxOptions } from '../../components/formDrawer/formDrawer'
import { icon } from '../../components/icon/icon'
import UploadConfigModal from '../../components/uploadConfigModal/uploadConfigModal'
import { UploadConfigFormValues } from '../../Pages/buildings/buildings'
import { PossibleDataTypes } from '../../utils/pageDrawerInputLists/inputLists'
import CheckboxGroupSearch from './checkboxGroupSearch'

export type SelectOptionProps = {
  text: string
  value: string
}

export type RadioOptionProps = {
  text: string
  value: string
  component: JSX.Element
}

export type FormElementsType = {
  type: string
  value:
    | string
    | number
    | (string | number)[]
    | boolean
    | PossibleDataTypes
    | PossibleDataTypes[]
    | undefined
  name: string
  label: string
  onFormChange?: (
    e:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
      | string
      | (string | number)[],
    name?: string,
  ) => void
  required?: boolean
  helperText?: string
  selectOptions?: SelectOptionProps[]
  checkboxOptions?: CheckboxOptions[]
  radioOptions?: RadioOptionProps[]
  modalInputs?: UploadConfigFormValues[]
  modalCrudFunc?: (
    type: 'edit' | 'delete' | 'add',
    editedConfig?: UploadConfigFormValues[],
  ) => void
}

const hexCodeRegexPattern = /^#([A-Fa-f0-9]{6})$/,
  hexCodeRegex = new RegExp(hexCodeRegexPattern)

const FormElements = ({
  type,
  name,
  value = '',
  required = false,
  label,
  helperText = '',
  selectOptions = [],
  checkboxOptions = [],
  radioOptions = [],
  onFormChange,
  modalCrudFunc,
  modalInputs,
}: FormElementsType) => {
  const [touched, setTouched] = useState(false)
  let element = <Input />
  if (Array.isArray(value)) {
    element = (
      <CheckboxGroupSearch
        onFormChange={onFormChange}
        value={value as (string | number)[]}
        checkboxOptions={checkboxOptions}
      />
    )
  } else {
    if (type === 'text' || type === 'email' || type === 'number') {
      element = (
        <Input
          type={type}
          name={name}
          value={value as string | number}
          onChange={onFormChange}
        />
      )
    } else if (type === 'year') {
      element = (
        <Input
          type={'number'}
          min={1500}
          max={2099}
          name={name}
          value={value as number}
          onChange={onFormChange}
        />
      )
    } else if (type === 'patternText') {
      element = (
        <Box position={'relative'}>
          <Input
            type={type}
            name={name}
            value={value as string}
            onChange={onFormChange}
            onBlur={() => setTouched(true)}
          />
          <Tooltip label="Color Preview" hasArrow placement="top">
            <Box
              width={30}
              height={30}
              bg={`${value}`}
              position={'absolute'}
              top={'5px'}
              right={'8px'}
              cursor={'pointer'}
            />
          </Tooltip>
        </Box>
      )
    } else if (type === 'textarea') {
      element = (
        <Textarea name={name} value={value as string} onChange={onFormChange} />
      )
    } else if (type === 'select') {
      const selectElement = (
        <Select onChange={onFormChange} name={name} value={value as string}>
          <option value={''}>-</option>
          {selectOptions.map((option: SelectOptionProps, i: number) => (
            <option key={i} value={option.value}>
              {option.text}
            </option>
          ))}
        </Select>
      )
      if (name === 'gasRegion') {
        element = (
          <Flex>
            {selectElement}
            <Popover>
              <PopoverTrigger>
                <IconButton
                  aria-label="show-gas-region-info"
                  icon={icon('question')}
                  padding={0}
                  marginLeft={2}
                  verticalAlign={'center'}
                />
              </PopoverTrigger>
              <PopoverContent backgroundColor={'white'}>
                <PopoverHeader>
                  You can view a map of the gas regions here:{' '}
                  <Link
                    isExternal={true}
                    textColor={'blue'}
                    href="https://www.energybrokers.co.uk/gas/gas-network"
                  >
                    https://www.energybrokers.co.uk/gas/gas-network
                  </Link>
                </PopoverHeader>
              </PopoverContent>
            </Popover>
          </Flex>
        )
      } else {
        element = selectElement
      }
    } else if (type === 'imageRadio') {
      element = (
        <RadioGroup
          onChange={(e) => onFormChange && onFormChange(e, name)}
          name={name}
          value={`${value}`}
        >
          <Stack direction="row">
            {radioOptions.map((option: RadioOptionProps, i: number) => (
              <Radio key={i} value={option.value} border={'2px solid black'}>
                {option.text}
                {option.component}
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
      )
    } else if (type === 'modalButton') {
      if (name === 'uploadConfigModal') {
        element = (
          <UploadConfigModal
            modalCrudFunc={modalCrudFunc!}
            modalInputs={modalInputs}
          />
        )
      }
    }
  }

  const isError = type === 'patternText' && !hexCodeRegex.test(`${value}`)

  return (
    <FormControl isRequired={required} isInvalid={isError && touched}>
      <FormLabel textTransform={'capitalize'} mb={helperText ? 0 : 2}>
        {label}
      </FormLabel>
      {helperText && (
        <FormHelperText mt={0} mb={2} fontSize={12}>
          {helperText}
        </FormHelperText>
      )}
      {element}
      {isError && touched && (
        <FormErrorMessage>
          Fill in the hexcode correctly. Eg. '#abcdef'
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export default FormElements
