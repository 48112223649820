import { Search2Icon } from '@chakra-ui/icons'
import {
  Checkbox,
  CheckboxGroup,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Tooltip,
} from '@chakra-ui/react'
import { ChangeEvent, useState } from 'react'
import { CheckboxOptions } from '../../components/formDrawer/formDrawer'

const CheckboxGroupSearch = ({
  onFormChange,
  value,
  checkboxOptions,
}: {
  onFormChange?: (
    e:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
      | string
      | (string | number)[],
    name?: string,
  ) => void
  value: (string | number)[]
  checkboxOptions: CheckboxOptions[]
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredChannels, setFilteredChannels] = useState(checkboxOptions)

  const parentNameFormatting = (parentNames: string[]) => {
    return (
      <>
        <b>Already added to composite channels:</b>
        {'\r\n'}
        {parentNames.join('\r\n')}
      </>
    )
  }

  return (
    <>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <Search2Icon />
        </InputLeftElement>
        <Input
          placeholder="Search..."
          size={'md'}
          mb={4}
          htmlSize={24}
          width="auto"
          onChange={(e) => {
            setSearchTerm(e.target.value)
            setFilteredChannels(
              checkboxOptions.filter((channel) =>
                channel.text
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase()),
              ),
            )
          }}
          value={searchTerm}
        />
      </InputGroup>

      <CheckboxGroup onChange={onFormChange} value={value}>
        <Stack spacing={[4]} direction={['column']}>
          {filteredChannels?.map((datum, i) => (
            <Checkbox
              // colorScheme={i % 3 ? 'blue' : 'green'}
              key={datum?.value}
              value={datum?.value}
              isFocusable={false}
              borderColor={'black'}
              color={datum.parentNames?.length ? 'gray' : 'black'}
            >
              <Tooltip
                bg={'gray.200'}
                color={'black'}
                hasArrow
                whiteSpace={'pre'}
                label={
                  datum.parentNames?.length
                    ? parentNameFormatting(datum.parentNames)
                    : ''
                }
                isDisabled={datum.parentNames && datum.parentNames?.length < 1}
                openDelay={500}
              >
                {datum?.text}
              </Tooltip>
            </Checkbox>
          ))}
        </Stack>
      </CheckboxGroup>
    </>
  )
}

export default CheckboxGroupSearch
