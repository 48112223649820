import {
  ApolloClient,
  ApolloLink,
  concat,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import { AUTH_TOKEN } from './constants'

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_ENDPOINT,
})

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: localStorage.getItem(AUTH_TOKEN) || null,
    },
  })

  return forward(operation)
})

const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
})

export default client
