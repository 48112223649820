import {
  AspectRatio,
  Center,
  ComponentWithAs,
  Flex,
  Hide,
  HStack,
  IconProps,
  Skeleton,
  StackDivider,
  Text,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { tightMapCalc } from '../../utils/colorMaps/tightMap'
import { mediaQueries } from '../../utils/mediaQueries/mediaQueries'
import displayScale from '../../utils/unitHelpers/displayScale'
import { periodEnum, isPeriodEnum } from '../building/buildingConfiguration'

type pfHeadlineItemProps = {
  Icon: ComponentWithAs<'svg', IconProps>
  label: string
  value: number
  target: number
  units: string
  description: string
  upIsBetter: boolean
  period?: string
  isDashboard?: boolean
  loading: boolean
}

const PfHeadlineItem = ({
  Icon,
  label,
  value,
  target,
  units,
  description,
  upIsBetter,
  period,
  loading,
  isDashboard = false,
}: pfHeadlineItemProps) => {
  const { t } = useTranslation(['portfolio'])
  const [is4k, is2k] = useMediaQuery(mediaQueries)
  const percentage = loading ? 100 : Math.round((value / target) * 100)
  const { color: iconColor } = tightMapCalc({
    value: percentage,
    range: 150,
    upIsBetter,
  })
  const iconSize = {
      base: 10,
      sm: 10,
      md: 8,
      lg: 8,
      xl: 10,
      '2xl': 10,
    },
    iconSizeDashboard = {
      base: 10,
      sm: 10,
      md: 8,
      lg: 8,
      xl: 10,
      '2xl': is2k ? 16 : is4k ? 32 : 14,
    }
  const headingFontSize = {
      base: '0.8em',
      sm: '0.9em',
      md: '0.9em',
      lg: '0.95em',
      xl: '1.05em',
      '2xl': '1.1em',
    },
    headingFontSizeDashboard = {
      base: '0.8em',
      sm: '0.9em',
      md: '0.9em',
      lg: '0.95em',
      xl: '1.05em',
      '2xl': is2k ? '1.6em' : is4k ? '3em' : '1.3em',
    }
  const spacing = {
    base: '2px',
    sm: '2px',
    md: '2px',
    lg: '0px',
    xl: '3px',
    '2xl': '4px',
  }
  const numberFontSize = {
      base: '1em',
      sm: '1.1em',
      md: '1.3em',
      lg: '1.4em',
      xl: '1.65em',
      '2xl': '1.75em',
    },
    numberFontSizeDashboard = {
      base: '1em',
      sm: '1.1em',
      md: '1.3em',
      lg: '1.4em',
      xl: '1.65em',
      '2xl': is2k ? '3em' : is4k ? '6em' : '2em',
    }
  const unitSizeDashboard = {
    base: '1em',
    sm: '1.1em',
    md: '1.3em',
    lg: '1.4em',
    xl: '1.65em',
    '2xl': is2k ? '1.6em' : is4k ? '3.8em' : 'initial',
  }
  const lowerSpacing = {
    base: '0px',
    sm: '0px',
    md: '0px',
    lg: '0px',
    xl: '0px',
    '2xl': '1px',
  }
  const textFontSize = {
      base: '0.8em',
      sm: '0.8em',
      md: '0.8em',
      lg: '0.9em',
      xl: '0.8em',
      '2xl': '0.9em',
    },
    textFontSizeDashboard = {
      base: '0.8em',
      sm: '0.8em',
      md: '0.8em',
      lg: '0.9em',
      xl: '0.8em',
      '2xl': is2k ? '1.1em' : is4k ? '2em' : '0.9em',
    }

  let periodText: string = 'lastMonth'
  if (isPeriodEnum(period)) {
    if (period === periodEnum.hourly) {
      periodText = 'lastHour'
    } else if (period === periodEnum.daily) {
      periodText = 'lastDay'
    } else if (period === periodEnum.weekly) {
      periodText = 'lastWeek'
    } else if (period === periodEnum.monthly) {
      periodText = 'lastMonth'
    } else if (period === periodEnum.yearly) {
      periodText = 'lastYear'
    }
  }

  let unitStrings = 'kWh'
  if (units.includes('m')) {
    // to find out water unit m3
    unitStrings = 'm3'
  } else if (units.includes('e')) {
    // to find out renewables
    unitStrings = 'KgCO₂e'
  } else if (units.includes('t')) {
    // to find out carbon
    unitStrings = 'tCO₂'
  }

  return (
    <AspectRatio
      ratio={1}
      backgroundColor={'white'}
      w={isDashboard ? '100%' : '12.5em'}
      h={isDashboard ? '100%' : 'unset'}
      shadow="md"
      textColor={'purple.500'}
    >
      <Center p={2}>
        <VStack
          divider={
            <StackDivider
              borderColor={iconColor}
              w={isDashboard ? '80%' : '70%'}
              alignSelf="center"
            />
          }
          height="100%"
          width="100%"
          spacing={spacing}
          padding="0"
        >
          <VStack
            height="50%"
            width="100%"
            spacing={spacing}
            justifyContent="space-around"
          >
            <Hide breakpoint="(max-width: 330px)">
              <Skeleton isLoaded={!loading}>
                <Center>
                  <Icon
                    minWidth={isDashboard ? iconSizeDashboard : iconSize}
                    h={isDashboard ? iconSizeDashboard : iconSize}
                    color={iconColor}
                    display="block"
                  />
                </Center>
              </Skeleton>
            </Hide>
            <Skeleton isLoaded={!loading}>
              <Text
                fontSize={
                  isDashboard ? headingFontSizeDashboard : headingFontSize
                }
                textAlign="center"
                lineHeight="1.2em"
              >
                {t(label).toLocaleUpperCase()}
              </Text>
            </Skeleton>
          </VStack>
          <VStack
            height="50%"
            width="100%"
            overflow="hidden"
            spacing={lowerSpacing}
            padding="4px"
            justifyContent="space-around"
          >
            <Skeleton isLoaded={!loading} as={HStack}>
              <Flex alignItems={'baseline'}>
                <Text
                  fontSize={
                    isDashboard ? numberFontSizeDashboard : numberFontSize
                  }
                >
                  {displayScale(value, 1, unitStrings).valueString}
                </Text>
                &thinsp;
                <Text
                  fontSize={isDashboard ? unitSizeDashboard : 'initial'}
                  dangerouslySetInnerHTML={{
                    __html:
                      '&thinsp;' + displayScale(value, 1, unitStrings).unit,
                  }}
                />
              </Flex>
            </Skeleton>
            <VStack spacing="0">
              <Center maxW="100%">
                <Hide below="xl">
                  <Skeleton isLoaded={!loading}>
                    <Text
                      textAlign={'center'}
                      fontSize={
                        isDashboard ? textFontSizeDashboard : textFontSize
                      }
                    >
                      {t(description).toLocaleUpperCase()}
                    </Text>
                  </Skeleton>
                </Hide>
              </Center>
              <Center>
                <Hide breakpoint="(max-width: 300px)">
                  <Skeleton isLoaded={!loading}>
                    <Text
                      textAlign={'center'}
                      fontSize={
                        isDashboard ? textFontSizeDashboard : textFontSize
                      }
                    >
                      {t(periodText).toLocaleUpperCase()}
                    </Text>
                  </Skeleton>
                </Hide>
              </Center>
            </VStack>
          </VStack>
        </VStack>
      </Center>
    </AspectRatio>
  )
}

export default PfHeadlineItem
