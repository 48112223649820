import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { ChakraProvider } from '@chakra-ui/react'
import theme from './styles/theme'

import './styles/fonts/fonts.css'

import './i18n'
import { RouterProvider } from 'react-router-dom'
import router from './router'
import { ProvideAuth } from './hooks/use-auth'
import { ApolloProvider } from '@apollo/client'
import client from './client'
import { OnboardingContextProvider } from './onboardingContextProvider'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ProvideAuth>
        <ChakraProvider theme={theme}>
          <OnboardingContextProvider>
            <RouterProvider router={router} />
          </OnboardingContextProvider>
        </ChakraProvider>
      </ProvideAuth>
    </ApolloProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
