import { createIcon } from '@chakra-ui/react'

const HotWater = createIcon({
  displayName: 'HotWater',
  viewBox: '0 0 209 234',
  path: (
    <path
      d="m 182.9157,135.93031 c 18.176,-13.265 26.017,-38.002007 10.154,-56.425007 6.063,12.413 -12.703,29.806007 -28.182,16.311 -17.7,-15.433 27.341,-37.12 -23.885,-71.682 14.059,38.517 -52.199,49.295 -31.169,95.084007 5.814,12.659 16.537,20.356 28.586,23.8 l 0.454,1.934 c 0.875,4.816 1.351,9.896 1.351,15.335 0,36.733 -29.778,66.512 -66.512,66.512 -36.733,0 -66.5120002,-29.778 -66.5120002,-66.512 0,-55 48.6820002,-73.333007 66.5120002,-153.4880068 8.073,36.2959998 22.472,56.6559998 35.814,76.7439998"
      id="path6"
      style={{
        fill: 'none',
        stroke: 'currentColor',
        strokeWidth: 14.4014,
        strokeLinejoin: 'round',
        strokeMiterlimit: 10,
      }}
    />
  ),
})

export default HotWater
