import {
  ComponentWithAs,
  Grid,
  GridItem,
  IconProps,
  SimpleGrid,
  Box,
  Heading,
  Skeleton,
  Center,
  Flex,
  RadioGroup,
  Radio,
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import PfHeadline, {
  PfHeadlineItemProps,
} from '../../components/pfHeadline/pfHeadline'
import BuildingConfiguration, {
  isPeriodEnum,
  isStyleEnum,
} from '../../elements/building/buildingConfiguration'
import EnergyDist from '../../elements/building/energyDist'
import EnergyUse from '../../elements/building/energyUse'
import BuildingInformation from '../../elements/building/buildingInformation'
import Picture from '../../elements/building/picture'
import DetailedData from '../../elements/building/detailedData'
import CO2 from '../../elements/icons/co2'
import Renewables from '../../elements/icons/renewables'
import WaterUsage from '../../elements/icons/waterUsage'
import WholeBuilding from '../../elements/icons/wholeBuilding'
import DefaultImage from '../../assets/Default-image.png'
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs'
import { isValidImageUrl } from '../../utils/helpers/basicFunc'
import { useEffect, useState } from 'react'
import Maps from '../../components/maps/maps'
import {
  BuildingGetOneQuery,
  BuildingWhereUniqueSearchOneInput,
  Exact,
  PortfolioTarget,
  useBuildingGetOneQuery,
} from '../../graphql/generated/graphql'
import { useAuth } from '../../hooks/use-auth'
import { UploadConfigFormValues } from './buildings'
import { useOnboarding } from '../../onboardingContextProvider'
import { FLOW_DEMO_TOUR_FINISHED } from '../../constants'

export type BuildingProps = {
  loading: boolean
  buildingDTO?: BuildingGetOneQuery['buildingGetOne']
  buildingName?: string
  userCompanyId?: string
  buildingSlug?: string
  refetchBuildingGetOne: (
    arg:
      | Partial<Exact<{ where: BuildingWhereUniqueSearchOneInput }>>
      | undefined,
  ) => {}
}

export const Building = ({
  loading,
  buildingDTO,
  buildingName,
  userCompanyId,
  buildingSlug,
  refetchBuildingGetOne,
}: BuildingProps) => {
  const [isValidImage, setIsValidImage] = useState(false)
  const [heroElement, setHeroElement] = useState<JSX.Element>()
  const [dataPeriod, setDataPeriod] = useState('weekly')

  useEffect(() => {
    const checkImageUrl = async () => {
      try {
        const result = await isValidImageUrl(buildingDTO?.picture ?? '')
        setIsValidImage(result)
      } catch (error) {
        console.error('Error checking image URL:', error)
        setIsValidImage(false) // Handle the error case
      }
    }

    if (buildingDTO?.picture) {
      checkImageUrl()
    } else {
      setIsValidImage(false) // Handle the case where buildingPicURL is undefined
    }
  }, [buildingDTO?.picture])

  useEffect(() => {
    if (!loading) {
      if (isValidImage) {
        setHeroElement(
          <Picture
            src={buildingDTO?.picture || DefaultImage}
            alt={buildingName || ''}
            h={!buildingDTO?.picture ? '350' : '100%'}
          />,
        )
      } else if (buildingDTO?.address?.postcode) {
        setHeroElement(
          <Box height={'100%'}>
            <Maps
              mapBuildingsData={[
                {
                  name: buildingName || '',
                  id: buildingDTO?.id || '',
                  address: buildingDTO?.address,
                },
              ]}
              height={{ base: 350 }}
            />
          </Box>,
        )
      } else {
        setHeroElement(
          <Picture
            src={DefaultImage}
            alt={buildingName || ''}
            h={!buildingDTO?.picture ? '350' : ''}
          />,
        )
      }
    }
  }, [
    isValidImage,
    loading,
    buildingDTO?.picture,
    buildingName,
    buildingDTO?.id,
    buildingDTO?.address,
  ])

  const onDataPeriodChange = (value: string) => {
    setDataPeriod(value)
    refetchBuildingGetOne({
      where: { slug: buildingDTO?.slug, dataPeriod: value },
    })
  }

  const uploadConfig = buildingDTO?.uploadConfig

  let changedUploadConfigStructure: UploadConfigFormValues[] = []
  if (uploadConfig?.ftpConfig && uploadConfig?.mqttConfig) {
    changedUploadConfigStructure = [
      ...uploadConfig.mqttConfig,
      ...uploadConfig.ftpConfig.map((config) => {
        return { useDefaultServer: true, ...config }
      }),
    ]
  }

  const headlineItems: PfHeadlineItemProps[] =
    loading || !buildingDTO?.portfolio
      ? []
      : Object.entries(buildingDTO?.portfolio).map(
          ([type, item]: [string, PortfolioTarget]) => {
            const specs: {
              Icon: ComponentWithAs<'svg', IconProps>
              label: string
              units: string
              description: string
              upIsBetter: boolean
            } =
              type === 'wholeBuilding'
                ? {
                    Icon: WholeBuilding,
                    label: 'buildingEnergy',
                    units: 'kWh',
                    description: 'totalEnergyUsed',
                    upIsBetter: false,
                  }
                : type === 'carbonDioxide'
                  ? {
                      Icon: CO2,
                      label: 'carbonDioxide',
                      units: 'KgCO<sub>2</sub>e',
                      description: 'totalEmissions',
                      upIsBetter: false,
                    }
                  : type === 'waterUsage'
                    ? {
                        Icon: WaterUsage,
                        label: 'water',
                        units: 'm<sup>3</sup>',
                        description: 'totalWaterUsed',
                        upIsBetter: false,
                      }
                    : type === 'renewables'
                      ? {
                          Icon: Renewables,
                          label: 'renewables',
                          units: 'kWh',
                          description: 'totalGenerated',
                          upIsBetter: true,
                        }
                      : {
                          Icon: WholeBuilding,
                          label: 'Unknown',
                          units: '',
                          description: 'unknown total',
                          upIsBetter: false,
                        }
            return {
              ...specs,
              type: type.charAt(0).toUpperCase() + type.slice(1),
              value: item?.measurement,
              target: item?.target,
            }
          },
        )

  return (
    <>
      <Center>
        <Skeleton isLoaded={!loading}>
          <Heading color={'purple.500'} fontSize={{ base: '4xl', md: '5xl' }}>
            {buildingName}
          </Heading>
        </Skeleton>
      </Center>
      <Grid
        templateAreas={{
          base: ` "picture"
                  "info"
                  "head"
                  "head"
                  "detailedData"
                  "buildingConfig"
                  "energyUse"
                  "energyDist"
                  "empty1"
                  "empty2"`,
          md: ` "info picture"
                " head head "
                "energyUse energyDist"
                "detailedData buildingConfig"
                 `,
        }}
        gridTemplateColumns={{ base: '1fr', md: '1fr 1fr' }}
        gridTemplateRows={'auto'}
        paddingTop={3}
      >
        <GridItem
          area="info"
          textAlign={'left'}
          className={'demo_buildingInfo'}
        >
          <BuildingInformation
            loading={loading}
            epcRating={`${buildingDTO?.epcRating}`}
            floorArea={buildingDTO?.floorArea}
            epcUrl={buildingDTO?.epcUrl ?? undefined}
            breeamScore={buildingDTO?.breeamScore ?? undefined}
            breeamRating={buildingDTO?.breeamRating ?? undefined}
            constructionYear={buildingDTO?.constructionYear ?? undefined}
            address={buildingDTO?.address}
            companyRelationships={buildingDTO?.companyRelationships ?? []}
          />
        </GridItem>
        <GridItem area="picture" className="demo_hero">
          {heroElement}
        </GridItem>
        <GridItem area="head" bg={'gray.300'}>
          <Flex
            w={'100%'}
            justifyContent={{ base: 'center', md: 'end' }}
            px={{ base: '0.5rem', md: 6 }}
            pt={5}
            className={'demo_radioperiod'}
          >
            <RadioGroup
              value={dataPeriod}
              bg={'white'}
              p={'7px 13px'}
              borderRadius={'20px'}
              onChange={(e) => onDataPeriodChange(e)}
            >
              <Center justifyContent={'space-between'} flexWrap={'wrap'}>
                <Radio value="daily" mr={2} borderColor={'#9e9e9e'}>
                  Daily
                </Radio>
                <Radio value="weekly" mr={2} borderColor={'#9e9e9e'}>
                  Weekly
                </Radio>
                <Radio value="monthly" mr={2} borderColor={'#9e9e9e'}>
                  Monthly
                </Radio>
                <Radio value="yearly" borderColor={'#9e9e9e'}>
                  Yearly
                </Radio>
              </Center>
            </RadioGroup>
          </Flex>
          <Box className="demo_headline">
            <PfHeadline
              items={headlineItems}
              period={dataPeriod}
              loading={loading}
            />
          </Box>
        </GridItem>
        <GridItem area="energyUse" className="demo_bargraph">
          <EnergyUse portfolio={buildingDTO?.portfolio} loading={loading} />
        </GridItem>
        <GridItem area="energyDist" className="demo_piechart">
          <EnergyDist portfolio={buildingDTO?.portfolio} loading={loading} />
        </GridItem>
        <GridItem area="detailedData">
          <DetailedData buildingDTO={buildingDTO} companyId={userCompanyId} />
        </GridItem>
        <GridItem area="buildingConfig">
          <BuildingConfiguration
            id={buildingDTO?.id ?? ''}
            buildingSlug={buildingSlug ?? ''}
            name={buildingName ?? ''}
            defaultName={buildingDTO?.defaultName ?? ''}
            type={''}
            picture={buildingDTO?.picture ?? ''}
            companyRelationships={buildingDTO?.companyRelationships ?? []}
            projectRef={buildingDTO?.projectRef ?? ''}
            floorArea={buildingDTO?.floorArea ?? 0}
            epcRating={buildingDTO?.epcRating ?? ''}
            epcUrl={buildingDTO?.epcUrl ?? ''}
            loading={loading}
            address={buildingDTO?.address!}
            breeamScore={buildingDTO?.breeamScore!}
            constructionYear={buildingDTO?.constructionYear!}
            dashboardPeriod={
              isPeriodEnum(buildingDTO?.dashboardPeriod)
                ? buildingDTO?.dashboardPeriod
                : undefined
            }
            dashboardStyle={
              isStyleEnum(buildingDTO?.dashboardStyle)
                ? buildingDTO?.dashboardStyle
                : undefined
            }
            tryMessage={buildingDTO?.tryMessage ?? ''}
            infoMessage={buildingDTO?.infoMessage ?? ''}
            refetchBuildingGetOne={refetchBuildingGetOne}
            uploadConfigs={changedUploadConfigStructure}
          />
        </GridItem>
      </Grid>
    </>
  )
}

const WrappedBuilding = () => {
  const auth = useAuth()
  const params = useParams()
  const buildingSlug = params['buildingSlug'] ?? 'unknown building'
  const { setRun, setStepIndex } = useOnboarding()
  const {
    data,
    loading: QueryLoading,
    refetch: refetchBuildingGetOne,
    //error,
  } = useBuildingGetOneQuery({
    onCompleted: () => {
      if (
        localStorage.getItem(`${FLOW_DEMO_TOUR_FINISHED}-${auth.user.id}`) !==
        'TRUE'
      ) {
        setTimeout(() => {
          setRun(true)
          setStepIndex(7)
        }, 1000)
      }
    },
    variables: { where: { slug: buildingSlug, dataPeriod: 'weekly' } },
  })

  const building = data?.buildingGetOne
  const breadcrumbLinks = [
    { text: 'Home', route: '/' },
    { text: 'Buildings', route: '/buildings' },
    { text: buildingSlug, route: `/buildings/${buildingSlug}` },
  ]
  const loading = QueryLoading
  const companyRelationship = building?.companyRelationships.find(
    (relationship) => relationship.companyId === auth.user.companyId,
  )

  useEffect(() => {
    return () => {
      setRun(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SimpleGrid columns={12}>
      <GridItem
        colStart={{ base: 1, sm: 1, md: 2, lg: 3 }}
        colSpan={{ base: 12, sm: 12, md: 10, lg: 8 }}
        padding={'5px'}
      >
        <Breadcrumbs links={breadcrumbLinks} />

        <Building
          loading={loading}
          buildingDTO={building}
          buildingName={building?.name}
          userCompanyId={companyRelationship?.companyId}
          buildingSlug={building?.slug ?? buildingSlug}
          refetchBuildingGetOne={refetchBuildingGetOne}
        />
      </GridItem>
    </SimpleGrid>
  )
}

export default WrappedBuilding
