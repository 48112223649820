import { Box, Text, useMediaQuery } from '@chakra-ui/react'
import { mediaQueries } from '../../../utils/mediaQueries/mediaQueries'

import CardIcons from './cardIcons'

type IconBlockType = {
  fontWeight: number | string
  fontSize: number | string | undefined
  iconType?: string
  height?: number | string | undefined
  width?: number | string | undefined
  text: string | undefined
}

const IconBlock = ({
  fontWeight,
  fontSize,
  iconType,
  text,
  width,
  height,
}: IconBlockType) => {
  const [is4k, is1440p] = useMediaQuery(mediaQueries)
  return (
    <Box display={'flex'} alignItems={'center'} height={'100%'}>
      {iconType && width && (
        <CardIcons width={width} height={height || width} iconType={iconType} />
      )}
      <Text
        fontSize={fontSize}
        fontWeight={fontWeight}
        color={'black'}
        lineHeight={1}
        ml={{ base: '15px', '2xl': is1440p ? '30px' : is4k ? '50px' : '15px' }}
        noOfLines={3}
      >
        {text}
      </Text>
    </Box>
  )
}

export default IconBlock
