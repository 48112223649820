import { extendTheme, ChakraProvider } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { DashboardColors } from '../graphql/generated/graphql'
import theme from './theme'

const DynamicColorComponent = ({
  colorObject,
  children,
}: {
  colorObject: DashboardColors
  children: JSX.Element
}) => {
  const [dynamicTheme, setTheme] = useState(theme)
  useEffect(() => {
    setTheme(
      extendTheme({
        colors: {
          ...theme.colors,
          dashboard: colorObject ? colorObject : theme.colors.dashboard,
        },
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorObject])
  return <ChakraProvider theme={dynamicTheme}>{children}</ChakraProvider>
}

export default DynamicColorComponent
