import { ListItem, Skeleton, UnorderedList } from '@chakra-ui/react'
import {
  GoogleMap,
  useLoadScript,
  MarkerF,
  InfoWindowF,
} from '@react-google-maps/api'
import { useEffect, useState } from 'react'
import { MapBuildingsData } from '../../elements/hero'

const MAPS_KEY = process.env.REACT_APP_GOOGLE_API_KEY ?? ''

type HeightType = {
  base?: number | string
  sm?: number | string
  md?: number | string
  lg?: number | string
}

type MapsTypes = {
  height?: string | HeightType
  mapBuildingsData: MapBuildingsData[]
}

type Markers = {
  id: string
  lat: number
  lng: number
  name?: string
  postcode: string
}

type GroupedLocations = Record<string, string[]>

const mapContainerStyle = {
  width: '100%',
  height: '100%',
}

const Maps = ({ height, mapBuildingsData }: MapsTypes) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: MAPS_KEY,
  })
  const [mapRef, setMapRef] = useState<google.maps.Map>()
  const [isOpen, setIsOpen] = useState(false)
  const [infoWindowData, setInfoWindowData] = useState<GroupedLocations>()
  const [markerDetails, setMarkerDetails] = useState<Markers[]>([])
  const [clickedMarker, setClickedMarker] = useState<string>('')

  useEffect(() => {
    // PERFORMANCE ISSUES - API BEING CALLED 3 TIMES
    if (mapBuildingsData) {
      const buildingAddresses: { postcode: string; id: string }[] = []
      mapBuildingsData?.forEach((building: MapBuildingsData) => {
        if (building.address?.postcode)
          buildingAddresses.push({
            postcode: building.address?.postcode,
            id: building.id,
          })
      })
      let promises = buildingAddresses.map((postcodeDetails) =>
        fetchLatLong(postcodeDetails),
      )

      Promise.all(promises)
        .then((coordinates: Markers[]) => {
          let newCoordinates: Markers[] = []
          coordinates.forEach((coordinate: Markers) => {
            if (coordinate) {
              newCoordinates.push({
                ...coordinate,
                name:
                  mapBuildingsData.find(
                    (building: MapBuildingsData) =>
                      building.id === coordinate.id,
                  )?.name ?? '',
                postcode:
                  mapBuildingsData.find(
                    (building: MapBuildingsData) =>
                      building.id === coordinate.id,
                  )?.address.postcode ?? '',
              })
            }
          })
          const groupedLocations: GroupedLocations = newCoordinates.reduce(
            (result: GroupedLocations, location: Markers) => {
              const key: string = `${location.postcode}`
              if (!result[key]) {
                result[key] = []
              }
              result[key].push(location.name || '')
              return result
            },
            {},
          )
          setInfoWindowData(groupedLocations)
          setMarkerDetails(newCoordinates)
        })
        .catch((error) => {
          console.error('Error fetching coordinates:', error)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (mapRef && markerDetails.length > 0) {
      const newBounds = new window.google.maps.LatLngBounds()

      markerDetails.forEach((marker: Markers) => {
        newBounds.extend({ lat: marker.lat, lng: marker.lng })
      })
      if (markerDetails.length) {
        mapRef.fitBounds(newBounds)
      }
      if (markerDetails.length === 1) {
        mapRef.setZoom(17)

        mapRef.setCenter({
          lat: markerDetails[0].lat,
          lng: markerDetails[0].lng,
        })
      }
    }
  }, [mapRef, markerDetails])

  const fetchLatLong = async (postcodeDetails: {
    postcode: string
    id: string
  }) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${postcodeDetails.postcode}&key=${MAPS_KEY}`,
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data = await response.json()

      if (data.status === 'OK') {
        const location = data.results[0].geometry.location
        return { ...location, id: postcodeDetails.id }
      } else {
        console.error('Geocoding failed:', data.status)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const onLoad = (map: google.maps.Map) => {
    setMapRef(map)
    const bounds = new google.maps.LatLngBounds()
    markerDetails?.forEach(({ lat, lng }: Markers) =>
      bounds.extend({ lat, lng }),
    )
    if (markerDetails.length) {
      map.fitBounds(bounds)
      if (markerDetails.length === 1) {
        map.setZoom(17)
        map.setCenter({ lat: markerDetails[0].lat, lng: markerDetails[0].lng })
      }
    } else {
      const defaultCenter = new google.maps.LatLng({
        lat: 53.7996,
        lng: -1.5471,
      })

      // Set the map center to the default center
      map.setCenter(defaultCenter)
      map.setZoom(10)
    }
  }

  const handleMarkerClick = ({
    lat,
    lng,
    postcode,
  }: {
    lat: number
    lng: number
    postcode: string
  }) => {
    mapRef?.panTo({ lat, lng })
    setClickedMarker(postcode)
    setIsOpen(true)
  }

  return !isLoaded ? (
    <Skeleton isLoaded={true} h={height} />
  ) : (
    <GoogleMap onLoad={onLoad} mapContainerStyle={mapContainerStyle}>
      {markerDetails.map(
        (
          {
            lat,
            lng,
            postcode,
          }: {
            lat: number
            lng: number
            postcode: string
          },
          i,
        ) => (
          <MarkerF
            key={i}
            position={{ lat, lng }}
            onClick={() => {
              handleMarkerClick({ lat, lng, postcode })
            }}
          >
            {isOpen && postcode === clickedMarker && (
              <InfoWindowF
                onCloseClick={() => {
                  setIsOpen(false)
                }}
              >
                <UnorderedList p={1}>
                  {infoWindowData &&
                    infoWindowData[clickedMarker]?.map((info, i) => (
                      <ListItem key={i} fontWeight={'bold'}>
                        {info}
                      </ListItem>
                    ))}
                </UnorderedList>
              </InfoWindowF>
            )}
          </MarkerF>
        ),
      )}
    </GoogleMap>
  )
}

export default Maps
