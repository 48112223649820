import { useEffect } from 'react'
import {
  //redirect,
  useNavigate,
} from 'react-router-dom'
import { useAuth } from '../hooks/use-auth'
//import { fakeAuthProvider as AuthProvider } from '../utils/fakeAuth'

// export async function loader() {
//   AuthProvider.signOut(() => {
//     console.log('signed out')
//   })
//   console.log(`redirecting`)
//   return redirect('/')
// }

const SignOut = () => {
  const auth = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    auth.signOut(() => {
      navigate('/', {})
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <p>signing out</p>
}

export default SignOut
