import { Heading } from '@chakra-ui/react'
import { Step } from 'react-joyride'

export const AUTH_TOKEN = 'auth-token'
export const USER_ID = 'user-id'
export const USER_FIRST_NAME = 'user-first-name'
export const USER_LAST_NAME = 'user-last-name'
export const USER_IS_ADMIN = 'user-is-admin'
export const USER_IS_STAFF = 'user-is-staff'
export const USER_SLUG = 'user-slug'
export const USER_AVATAR = 'user-avatar'
export const USER_COMPANY_ID = 'user-company-id'
export const FLOW_DEMO_TOUR_FINISHED = 'ot-flow-demo-finished'
export const CHANNEL_DEMO_TOUR_FINISHED = 'ot-channel-demo-finished'
export const DATA_DEMO_TOUR_FINISHED = 'ot-data-demo-finished'
export const BUILDING_SLUG = 'ot-firstBuilding-slug'

export const onBoardingSteps: Step[] = [
  {
    content: (
      <Heading as={'h6'} size={'md'}>
        Let's begin our journey!
      </Heading>
    ),
    placement: 'center',
    target: 'body ',
  },
  {
    content: "This is the current user's company",
    target: '.demo_title',
    title: (
      <Heading as={'h5'} size={'md'}>
        Company name
      </Heading>
    ),
  },
  {
    content: 'This shows the locations of all currently monitored assets.',
    // placement: 'bottom',
    target: '.demo_map',
    title: (
      <Heading as={'h5'} size={'md'}>
        Locations
      </Heading>
    ),
  },
  {
    content: 'This shows key metrics across the full portfolio.',
    // placement: 'top',
    target: '.demo_headline',
    title: (
      <Heading as={'h5'} size={'md'}>
        Four key metrics
      </Heading>
    ),
  },
  {
    content: 'Allow the user to select and refine reporting periods.',
    placement: 'right',
    target: '.demo_radioperiod',
    title: (
      <Heading as={'h5'} size={'md'}>
        Period selector
      </Heading>
    ),
  },
  {
    content: 'Displays all monitored assets with a summary of key metric data.',
    // placement: 'top',
    target: '.demo_pftable',
    title: (
      <Heading as={'h5'} size={'md'}>
        Buildings list
      </Heading>
    ),
  },
  {
    content:
      "We've completed the portfolio page, lets move to the building page next.",
    placement: 'center',
    target: 'body',
    title: (
      <Heading as={'h5'} size={'md'}>
        Portfolio page done!
      </Heading>
    ),
    data: {
      next: `/buildings/{{SLUG}}`,
    },
  },
  {
    content: 'Key facts for the building are shown here',
    target: '.demo_buildingInfo',
    title: (
      <Heading as={'h5'} size={'md'}>
        Key facts
      </Heading>
    ),
    data: {
      prev: '/buildings/',
    },
  },
  {
    content:
      "An image of the building, or a map of the location if an image isn't available",
    target: '.demo_hero',
    title: (
      <Heading as={'h5'} size={'md'}>
        Building Image
      </Heading>
    ),
  },
  {
    content: 'Select the reporting period for the data displayed below here',
    target: '.demo_radioperiod',
    placement: 'right',
    title: (
      <Heading as={'h5'} size={'md'}>
        Period
      </Heading>
    ),
  },
  {
    content: 'The key metrics for this building.',
    target: '.demo_headline',
    title: (
      <Heading as={'h5'} size={'md'}>
        Key Metrics
      </Heading>
    ),
  },
  {
    content: 'The top 5 energy consumption categories are shown here.',
    target: '.demo_bargraph',
    title: (
      <Heading as={'h5'} size={'md'}>
        Key consumers
      </Heading>
    ),
  },
  {
    content:
      'A quick overview of all the consumption categories defined for the building.',
    target: '.demo_piechart',
    title: (
      <Heading as={'h5'} size={'md'}>
        Energy distribution
      </Heading>
    ),
  },
  {
    content: 'We are going to go to dashboard page next.',
    target: 'body',
    placement: 'center',
    title: (
      <Heading as={'h5'} size={'md'}>
        Building page done!
      </Heading>
    ),
    data: {
      next: `/buildings/{{SLUG}}/dashboard`,
      prev: `/buildings/{{SLUG}}`,
    },
  },
  {
    content: 'Shows the total energy used in the building',
    target: '.demo_primary-0',
    title: (
      <Heading as={'h5'} size={'md'}>
        Total energy
      </Heading>
    ),
  },
  {
    content: 'Estimates the total carbon footprint of the building',
    target: '.demo_primary-1',
    title: (
      <Heading as={'h5'} size={'md'}>
        Carbon emissions
      </Heading>
    ),
  },
  {
    content: 'Shows the total water usage in the building',
    target: '.demo_primary-2',
    title: (
      <Heading as={'h5'} size={'md'}>
        Water consumption
      </Heading>
    ),
  },
  {
    content: 'Shows end use of energy within the building',
    target: '.demo_secondary',
    title: (
      <Heading as={'h5'} size={'md'}>
        Energy breakdown
      </Heading>
    ),
  },
  {
    content:
      'A visual guide to how much energy the building is generating locally compared to its usage.',
    target: '.demo_seesaw',
    title: (
      <Heading as={'h5'} size={'md'}>
        How much solar energy we generated
      </Heading>
    ),
  },
  {
    content:
      "That's the initial tutorial complete, check out the channel and data pages on the building page for more.",
    target: 'body',
    title: (
      <Heading as={'h5'} size={'md'}>
        Tutorial complete
      </Heading>
    ),
    disableBeacon: true,
    placement: 'center',
    data: {
      next: `/buildings/{{SLUG}}`,
    },
  },
]
