import { ArrowDownIcon, ArrowUpIcon, UpDownIcon } from '@chakra-ui/icons'
import { ComponentWithAs, IconButton, IconProps } from '@chakra-ui/react'
import { createElement } from 'react'
import { useTranslation } from 'react-i18next'

const sortIcons: {
  [key: string]: { Icon: ComponentWithAs<'svg', IconProps>; label: string }
} = {
  none: { Icon: UpDownIcon, label: 'noSort' },
  asc: { Icon: ArrowUpIcon, label: 'ascSort' },
  dsc: { Icon: ArrowDownIcon, label: 'dscSort' },
}

type SortIconProps = {
  sort: string
  setSort: (sort: string, sortKey: string) => void
  sortKey: string
}

const SortIcon = ({ sort, setSort, sortKey }: SortIconProps) => {
  const { t } = useTranslation('tableSort')

  const changeButton = () => {
    if (sort === 'none') {
      setSort('asc', sortKey)
    } else if (sort === 'asc') {
      setSort('dsc', sortKey)
    } else {
      setSort('none', sortKey)
    }
  }
  return (
    <IconButton
      aria-label={t(sortIcons[sort].label)}
      icon={createElement(sortIcons[sort].Icon)}
      size="xs"
      variant="link"
      onClick={() => changeButton()}
    />
  )
}

export default SortIcon
