import {
  Box,
  GridItem,
  Heading,
  SimpleGrid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Button,
  Tooltip,
} from '@chakra-ui/react'
import { useEffect } from 'react'
import { RotateCcw } from 'react-feather'
import { useParams } from 'react-router-dom'
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs'
import { GET_BUILDING } from '../../graphql/API/queries'
import {
  useBuildingGetOneQuery,
  useChannelSearchManyLazyQuery,
  useResetFlagMutation,
} from '../../graphql/generated/graphql'
import { useAuth } from '../../hooks/use-auth'

export type InspectorChannel = {
  channelId: string | null
  lastSeen?: Date | null
  isImportant: boolean
  notCumulative: boolean
  includesZeroes: boolean
  notTransmitting: boolean
}

export const inspectorChannelGuard = (
  channel: Partial<InspectorChannel> | null | undefined,
): channel is InspectorChannel => {
  if (!channel) {
    return false
  }
  if (
    typeof channel?.notTransmitting === 'boolean' &&
    typeof channel.notCumulative === 'boolean' &&
    typeof channel.includesZeroes === 'boolean'
  ) {
    return true
  }
  return false
}
const tableIssueTopPadding = 0.5

export const BuildingIssues = () => {
  const auth = useAuth()
  const params = useParams()
  const buildingSlug = params['buildingSlug'] ?? 'unknown building'
  const {
    data: buildingData,
    loading: buildingLoading,
    //error,
  } = useBuildingGetOneQuery({ variables: { where: { slug: buildingSlug } } })
  const building = buildingData?.buildingGetOne

  const [getChannelData, channelData] = useChannelSearchManyLazyQuery()

  useEffect(() => {
    if (building) {
      getChannelData({ variables: { where: { buildingId: building?.id } } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building])

  const [resetFlag] = useResetFlagMutation()

  const resetInspectorFlag = (where: {
    channelId: string
    flag: 'notCumulative' | 'includesZeroes'
  }) => {
    resetFlag({
      variables: { where },
      refetchQueries: [
        {
          query: GET_BUILDING,
          variables: { where: { slug: buildingSlug } },
        },
      ],
    })
  }

  const channels = channelData.data?.channelSearchMany

  const loading = buildingLoading

  const breadcrumbLinks = [
    { text: 'Home', route: '/' },
    { text: 'Buildings', route: '/buildings' },
    { text: buildingSlug, route: `/buildings/${buildingSlug}` },
    { text: 'Issues', route: `/buildings/${buildingSlug}/issues` },
  ]

  const companyRelationship =
    buildingData?.buildingGetOne?.companyRelationships.find(
      (relationship) => relationship.companyId === auth.user.companyId,
    )
  const buildingName = companyRelationship
    ? companyRelationship.buildingName
    : buildingData?.buildingGetOne?.name

  const buildingIssuesString = (): JSX.Element => {
    if (loading) {
      return <p>Loading...</p>
    }
    if (!building?.lastSeen) {
      return (
        <p>Building is still being set up, and hasn't transmitted data yet.</p>
      )
    }
    if (!building.buildingTransmitting) {
      const msSinceSeen =
        new Date().getTime() - new Date(building.lastSeen).getTime()
      const hoursSinceSeen = Math.floor(msSinceSeen / (1000 * 60 * 60))
      const daysSinceSeen = Math.floor(msSinceSeen / (1000 * 60 * 60 * 24))
      const timeString =
        daysSinceSeen === 1
          ? `${hoursSinceSeen} hours ago`
          : `${daysSinceSeen} days ago`
      return (
        <p>
          Building has stopped transmitting data. It was last seen {timeString}
        </p>
      )
    }
    return <p>No issues detected.</p>
  }

  const notTransmittingString = (
    channel: InspectorChannel,
  ): string | undefined => {
    if (!channel.lastSeen) {
      return `It has not transmitted data`
    }
    const msSinceSeen =
      channel.lastSeen &&
      new Date().getTime() - new Date(channel.lastSeen).getTime()

    const hoursSinceSeen = Math.floor(msSinceSeen / (1000 * 60 * 60))
    const daysSinceSeen = Math.floor(msSinceSeen / (1000 * 60 * 60 * 24))
    const timeString =
      daysSinceSeen < 2 ? `${hoursSinceSeen} hours` : `${daysSinceSeen} days`
    return `It has not transmitted data in ${timeString}`
  }

  const resetButton = (where: {
    channelId: string
    flag: 'notCumulative' | 'includesZeroes'
  }) => {
    return auth.user.isStaff ? (
      <Button
        fontSize={'sm'}
        leftIcon={<RotateCcw />}
        iconSpacing={'0'}
        colorScheme={'purple'}
        variant={'ghost'}
        padding={'0'}
        onClick={() => {
          resetInspectorFlag(where)
        }}
      ></Button>
    ) : (
      <></>
    )
  }

  const importantIssues: JSX.Element[] = []
  const normalIssues: JSX.Element[] = []
  if (building?.channelIssues.length) {
    for (const inspectorChannel of building.channelIssues) {
      if (
        !inspectorChannelGuard(inspectorChannel) ||
        !inspectorChannel.channelId
      ) {
        continue
      }
      if (
        !inspectorChannel.notTransmitting &&
        !inspectorChannel.notCumulative &&
        !inspectorChannel.includesZeroes
      ) {
        continue
      }
      const channelName = channels?.find((queryChannel) => {
        if (queryChannel?.id) {
          console.error(`channel has no associated name : ${queryChannel?.id}`)
        }
        return queryChannel?.id === inspectorChannel.channelId
      })?.name
      if (!channelName) {
        continue
      }
      const transmitBorder =
        inspectorChannel.notCumulative || inspectorChannel.includesZeroes
          ? undefined
          : '0'
      const cumulativeBorder = inspectorChannel.includesZeroes ? undefined : '0'
      // const transmitBorder = undefined
      // const cumulativeBorder = undefined
      const issue = (
        <Tr>
          <Td wordBreak={'break-word'}>
            <Text whiteSpace={'pre-wrap'}>{channelName}</Text>
          </Td>
          <Td padding={0}>
            <Table borderBottom={'0'} layout={'fixed'} width={'100%'}>
              <colgroup>
                <col />
                <col width={'50px'} />
              </colgroup>
              {inspectorChannel.notTransmitting ? (
                <Tr padding={tableIssueTopPadding}>
                  <Td
                    paddingTop={'10px'}
                    paddingBottom={'9px'}
                    height={'40px'}
                    borderBottom={transmitBorder}
                    whiteSpace={'pre-wrap'}
                    overflowWrap={'break-word'}
                    lineHeight={'1.6rem'}
                  >
                    {notTransmittingString(inspectorChannel)}
                  </Td>
                  <Td borderBottom={transmitBorder}></Td>
                </Tr>
              ) : undefined}
              {inspectorChannel.notCumulative ? (
                <Tr>
                  <Td
                    paddingY={'1'}
                    borderBottom={cumulativeBorder}
                    whiteSpace={'pre-wrap'}
                    overflowWrap={'break-word'}
                    lineHeight={'1.6rem'}
                    paddingRight={'1'}
                    minWidth={'160px'}
                  >
                    It is sending non-cumulative data
                  </Td>
                  <Td
                    borderBottom={cumulativeBorder}
                    paddingY={'1'}
                    textAlign={'right'}
                    padding={'0'}
                  >
                    <Tooltip hasArrow label="Reset tracking for this issue">
                      {resetButton({
                        channelId: inspectorChannel.channelId,
                        flag: 'notCumulative',
                      })}
                    </Tooltip>
                  </Td>
                </Tr>
              ) : // <Text padding={tableIssueTopPadding}>
              //   It seems to be misconfigured as it is sending non-cumulative
              //   data
              // </Text>
              undefined}
              {inspectorChannel.includesZeroes ? (
                <Tr>
                  <Td
                    paddingY={'1'}
                    borderBottom={0}
                    whiteSpace={'pre-wrap'}
                    wordBreak={'break-word'}
                    lineHeight={'1.6rem'}
                    paddingRight={'1'}
                    minWidth={'160px'}
                  >
                    It is sending zeroes in its raw data
                  </Td>
                  <Td
                    borderBottom={0}
                    paddingY={'1'}
                    textAlign={'right'}
                    padding={'0'}
                  >
                    <Tooltip hasArrow label="Reset tracking for this issue">
                      {resetButton({
                        channelId: inspectorChannel.channelId,
                        flag: 'includesZeroes',
                      })}
                    </Tooltip>
                  </Td>
                </Tr>
              ) : // <Text padding={tableIssueTopPadding}>
              //   It has sent zeroes in its raw data
              // </Text>
              undefined}
            </Table>
          </Td>
        </Tr>
      )
      if (inspectorChannel.isImportant) {
        importantIssues.push(issue)
      } else {
        normalIssues.push(issue)
      }
    }
  }

  return (
    <SimpleGrid columns={12}>
      <GridItem
        colStart={{ base: 1, sm: 1, md: 2, lg: 3 }}
        colSpan={{ base: 12, sm: 12, md: 10, lg: 8 }}
        p={{ base: '5px', md: 0 }}
      >
        <Breadcrumbs links={breadcrumbLinks} />
        <Heading
          as={'h1'}
          paddingTop={4}
          fontSize={{ base: 22, md: 40 }}
          color={'purple.500'}
        >
          {buildingName} Issues
        </Heading>

        <Heading
          as={'h2'}
          fontSize={{ base: 16, md: 28 }}
          color={'purple.500'}
          paddingTop={8}
          paddingBottom={4}
        >
          Building Issues
        </Heading>
        <Box paddingLeft={6}>{buildingIssuesString()}</Box>

        {loading ? undefined : (
          <>
            <Heading
              as={'h2'}
              fontSize={{ base: 16, md: 28 }}
              color={'purple.500'}
              paddingTop={8}
            >
              Important Channel Issues
            </Heading>

            {importantIssues.length ? (
              <TableContainer
                display={'flex'}
                paddingTop={4}
                paddingBottom={4}
                width={'100%'}
                paddingRight={'2'}
              >
                <Table width={'100%'} layout={'fixed'}>
                  {/* <colgroup>
                    <col span={1} width={"20%"} />
                    <col span={1} width={"20%"} />
                  </colgroup> */}

                  <Thead>
                    <Th width={'30%'}>Name</Th>
                    <Th width={'70%'}>Issues</Th>
                  </Thead>

                  <Tbody>{importantIssues}</Tbody>
                </Table>
              </TableContainer>
            ) : (
              <Text paddingTop={4} paddingLeft={6}>
                No issues detected.
              </Text>
            )}

            <Heading
              as={'h2'}
              fontSize={{ base: 16, md: 28 }}
              color={'purple.500'}
              paddingTop={8}
            >
              Other Channel Issues
            </Heading>

            {normalIssues.length ? (
              <TableContainer
                display={'flex'}
                paddingTop={4}
                paddingBottom={16}
                width={'100%'}
                paddingRight={'2'}
              >
                <Table width={'100%'} layout={'fixed'}>
                  {/* <colgroup>
                    <col span={1} width={"20%"} />
                    <col span={1} width={"20%"} />
                  </colgroup> */}

                  <Thead>
                    <Th width={'30%'}>Name</Th>
                    <Th width={'70%'}>Issues</Th>
                  </Thead>

                  <Tbody>{normalIssues}</Tbody>
                </Table>
              </TableContainer>
            ) : (
              <Text paddingTop={4} paddingLeft={6}>
                No issues detected.
              </Text>
            )}
          </>
        )}
      </GridItem>
    </SimpleGrid>
  )
}
