import { createIcon } from '@chakra-ui/react'

const WaterUsage = createIcon({
  displayName: 'Water Usage',
  viewBox: '0 0 165 235',
  path: (
    <path
      d="m 7.60495,148.54505 c 0,43.548 33.529,78.85 74.891,78.85 41.363,-0.003 74.895,-35.301 74.895,-78.847 0,-48.292 -57.838,-82.062003 -74.895,-141.1530029 C 59.41895,65.698047 7.60495,100.25605 7.60495,148.54505 Z"
      id="path8"
      style={{
        fill: 'none',
        stroke: 'currentColor',
        strokeWidth: 15.2099,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeMiterlimit: 22.9256,
      }}
    />
  ),
})

export default WaterUsage
