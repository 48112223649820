const prefixLookup: { [key: number]: string } = {
  '-24': 'z',
  '-21': 'a',
  '-18': 'f',
  '-15': 'p',
  '-12': 'n',
  '-9': 'μ',
  '-6': 'm',
  '-3': '',
  0: 'k',
  3: 'M',
  6: 'G',
  9: 'T',
  12: 'P',
  15: 'E',
  18: 'Z',
  21: 'Y',
}

//const volumeLookup: { [key: number]: string} = {
//  '-6': 'ml',
//  '-3': 'l',
//  0: 'm'
//}

const massLookup: { [key: number]: string } = {
  '-9': 'μg',
  '-6': 'mg',
  '-3': 'g',
  0: 'kg',
  3: 't',
  6: 'kt',
  9: 'Mt',
  12: 'Gt',
}

function displayScale(
  value: number,
  precision: number,
  unit: string,
): { value: number; valueString: string; unit: string } {
  // Special case for volume, needs improving:
  if (unit === 'm3') {
    if (value < 1) {
      return {
        value: value * 1000,
        valueString: value !== Infinity ? (value * 1000).toLocaleString() : '-', //toPrecision(precision),
        unit: value !== Infinity ? 'l' : '',
      }
    } else {
      return {
        value: value,
        valueString: value.toLocaleString(), // toPrecision(precision),
        unit: 'm<sup>3</sup>',
      }
    }
  }

  const expStr = value.toExponential(precision + 1)
  const idx = expStr.indexOf('e')

  if (idx <= 0) {
    // Wasn't a real number
    return {
      value: NaN,
      valueString: '-',
      unit: '',
    }
  }

  const mantissa = +expStr.slice(0, idx)
  const exponent = +expStr.slice(idx + 1)

  const shift =
    exponent >= 0 ? Math.abs(exponent) % 3 : 3 - (Math.abs(exponent) % 3)

  const siMantissa = mantissa * 10 ** shift
  const siExponent = exponent - shift

  const siPrefix = prefixLookup[siExponent] ?? 'Prefix not found'
  const massUnit = massLookup[siExponent] ?? 'Unit not found'

  const finalUnit = unit.includes('e') // condition to check to kgCO2e which is the unit for renewables
    ? `${massUnit}${unit.slice(2)}`
    : `${siPrefix}${unit.slice(1)}`

  const stringPrecision = shift === 0 ? precision + 1 : precision + 2

  return {
    value: siMantissa,
    valueString: siMantissa.toPrecision(stringPrecision),
    unit: finalUnit,
  }
}

export default displayScale
