import { createIcon } from '@chakra-ui/react'

const CoolingStyle = {
  fill: 'none',
  stroke: 'currentColor',
  strokeWidth: 10,
  // strokeLinecap: 'round',
  // strokeLinejoin: 'round',
  strokeMiterlimit: 10,
}

const Cooling = createIcon({
  displayName: 'Cooling',
  viewBox: '0 0 200 230',
  path: (
    <g id="g30" transform="translate(-67.955259,-73.072213)">
      <line
        x1="168.28"
        y1="188.00101"
        x2="168.28"
        y2="78.000999"
        id="line6"
        style={CoolingStyle}
      />
      <polyline
        points="138.28,98.001 168.28,128.001 198.28,98.001  "
        id="polyline8"
        style={CoolingStyle}
      />
      <line
        x1="168.28"
        y1="188.00101"
        x2="168.28"
        y2="298.00101"
        id="line10"
        style={CoolingStyle}
      />
      <polyline
        points="138.28,278.001 168.28,248.001 198.28,278.001  "
        id="polyline12"
        style={CoolingStyle}
      />
      <line
        x1="168.28"
        y1="188.00101"
        x2="73.016998"
        y2="133.00101"
        id="line14"
        style={CoolingStyle}
      />
      <polyline
        points="75.337,168.982 116.318,158.001 105.337,117.02  "
        id="polyline16"
        style={CoolingStyle}
      />
      <line
        x1="168.28"
        y1="188.00101"
        x2="263.543"
        y2="243.00101"
        id="line18"
        style={CoolingStyle}
      />
      <polyline
        points="231.222,258.982 220.241,218.001 261.222,207.02  "
        id="polyline20"
        style={CoolingStyle}
      />
      <line
        x1="168.28"
        y1="188.00101"
        x2="263.543"
        y2="133.00101"
        id="line22"
        style={CoolingStyle}
      />
      <polyline
        points="261.222,168.982 220.241,158.001 231.222,117.02  "
        id="polyline24"
        style={CoolingStyle}
      />
      <line
        x1="168.28"
        y1="188.00101"
        x2="73.016998"
        y2="243.00101"
        id="line26"
        style={CoolingStyle}
      />
      <polyline
        points="105.337,258.982 116.318,218.001 75.337,207.02  "
        id="polyline28"
        style={CoolingStyle}
      />
    </g>
  ),
})

export default Cooling
