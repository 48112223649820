import { createIcon } from '@chakra-ui/react'

const SmallPower = createIcon({
  displayName: 'SmallPower',
  viewBox: '0 0 237 220',
  path: (
    <g id="g14" transform="translate(-98.814,-67)">
      <path
        fill="currentColor"
        d="m 327.525,156.839 h -33.853 c -9.013,0 -17.489,3.511 -23.867,9.887 -6.374,6.376 -9.884,14.851 -9.884,23.865 v 62.658 c 0,4.853 -1.89,9.418 -5.321,12.85 -3.436,3.433 -7.999,5.324 -12.853,5.324 h -55.764 c -4.854,0 -9.418,-1.891 -12.85,-5.321 -3.434,-3.435 -5.324,-7.999 -5.324,-12.853 v -16.802 c 17.036,-1.647 33.971,-9.019 50.444,-21.976 l 2.973,-2.339 V 118.418 H 192.954 V 74.789 c 0,-4.301 -3.488,-7.789 -7.789,-7.789 -4.301,0 -7.789,3.487 -7.789,7.789 v 43.628 H 141.028 V 74.789 c 0,-4.301 -3.488,-7.789 -7.789,-7.789 -4.301,0 -7.789,3.487 -7.789,7.789 v 43.628 H 98.814 v 93.793 l 3.073,2.338 c 16.678,12.687 33.575,19.984 50.343,21.793 v 16.907 c 0,9.013 3.51,17.489 9.887,23.868 6.377,6.373 14.851,9.884 23.865,9.884 h 55.764 c 9.013,0 17.488,-3.51 23.869,-9.886 6.373,-6.377 9.883,-14.853 9.883,-23.865 v -62.658 c 0,-4.853 1.89,-9.417 5.322,-12.85 3.434,-3.434 7.998,-5.324 12.852,-5.324 h 33.853 c 4.301,0 7.789,-3.488 7.789,-7.789 0,-4.302 -3.488,-7.789 -7.789,-7.789 z m -167.058,64.386 c -14.989,0 -30.474,-5.651 -46.075,-16.807 v -70.423 h 91.255 v 70.498 c -15.053,11.105 -30.237,16.732 -45.18,16.732 z"
        id="path6"
      />
      <path
        fill="currentColor"
        d="m 177.808,155.706 h -37.215 c -2.151,0 -3.894,1.744 -3.894,3.894 0,2.151 1.743,3.894 3.894,3.894 h 37.215 c 2.151,0 3.894,-1.744 3.894,-3.894 0,-2.151 -1.743,-3.894 -3.894,-3.894 z"
        id="path8"
      />
      <path
        fill="currentColor"
        d="m 177.808,171.283 h -37.215 c -2.151,0 -3.894,1.744 -3.894,3.894 0,2.151 1.743,3.894 3.894,3.894 h 37.215 c 2.151,0 3.894,-1.744 3.894,-3.894 0,-2.15 -1.743,-3.894 -3.894,-3.894 z"
        id="path10"
      />
      <path
        fill="currentColor"
        d="m 177.808,186.861 h -37.215 c -2.151,0 -3.894,1.744 -3.894,3.894 0,2.151 1.743,3.894 3.894,3.894 h 37.215 c 2.151,0 3.894,-1.744 3.894,-3.894 0,-2.15 -1.743,-3.894 -3.894,-3.894 z"
        id="path12"
      />
    </g>
  ),
})

export default SmallPower
