import { getCategory, getDefaultUnit, unitCategory } from './unitCategories'

export enum electricityUnit {
  kwh = 'electricityKwh',
  btu = 'electricityBtu',
  kj = 'electricityKj',
  carbonTonnesMetric = 'electricityCarbonTonnesMetric',
  carbonTonnesImperial = 'electricityCarbonTonnesImperial',
}
export function isElectricityUnit(unit?: unknown): unit is electricityUnit {
  if (Object.values(electricityUnit).some((value) => value === unit)) {
    return true
  }
  return false
}

export enum gasUnit {
  metersCubed = 'gasMetersCubed',
  feetCubed = 'gasFeetCubed',
  kwh = 'gasKwh',
  btu = 'gasBtu',
  kj = 'gasKj',
  carbonTonnesMetric = 'gasCarbonTonnesMetric',
  carbonTonnesImperial = 'gasCarbonTonnesImperial',
}
export function isGasUnit(unit?: unknown): unit is gasUnit {
  if (Object.values(gasUnit).some((value) => value === unit)) {
    return true
  }
  return false
}

export enum combiPowerUnit {
  kwh = 'combiKwh',
  btu = 'combiBtu',
  kj = 'combiKj',
}

export function isCombiPowerUnit(unit?: unknown): unit is combiPowerUnit {
  if (Object.values(combiPowerUnit).some((value) => value === unit)) {
    return true
  }
  return false
}

export enum combiCarbonUnit {
  carbonTonnesMetric = 'combiCarbonTonnesMetric',
  carbonTonnesImperial = 'combiCarbonTonnesImperial',
}

export function isCombiCarbonUnit(unit?: unknown): unit is combiCarbonUnit {
  if (Object.values(combiCarbonUnit).some((value) => value === unit)) {
    return true
  }
  return false
}

export enum waterUnit {
  metersCubed = 'waterMetersCubed',
  feetCubed = 'waterFeetCubed',
  gallonsImperial = 'waterGallonsImperial',
  gallonsUS = 'waterGallonsUS',
  litres = 'waterLitres',
}
export function isWaterUnit(unit?: unknown): unit is waterUnit {
  if (Object.values(waterUnit).some((value) => value === unit)) {
    return true
  }
  return false
}

export enum airQualityUnit {
  ppm = 'ppm',
}
export function isAirQualityUnit(unit?: unknown): unit is airQualityUnit {
  if (Object.values(airQualityUnit).some((value) => value === unit)) {
    return true
  }
  return false
}

export enum temperatureUnit {
  celsius = 'celsius',
  fahrenheit = 'fahrenheit',
  kelvin = 'kelvin',
}
export function isTemperatureUnit(unit?: unknown): unit is temperatureUnit {
  if (Object.values(temperatureUnit).some((value) => value === unit)) {
    return true
  }
  return false
}

export enum booleanUnit {
  boolean = 'boolean',
}
export function isBooleanUnit(unit?: unknown): unit is booleanUnit {
  if (Object.values(booleanUnit).some((value) => value === unit)) {
    return true
  }
  return false
}

export enum unknownUnit {
  unknown = 'unknown',
}

export function isUnknownUnit(unit?: unknown): unit is unknownUnit {
  if (Object.values(unknownUnit).some((value) => value === unit)) {
    return true
  }
  return false
}

export type anyUnit =
  | gasUnit
  | waterUnit
  | booleanUnit
  | combiPowerUnit
  | combiCarbonUnit
  | electricityUnit
  | temperatureUnit
  | airQualityUnit
  | unknownUnit

export function isUnit(unit?: unknown): unit is anyUnit {
  if (
    isElectricityUnit(unit) ||
    isGasUnit(unit) ||
    isCombiPowerUnit(unit) ||
    isCombiCarbonUnit(unit) ||
    isWaterUnit(unit) ||
    isAirQualityUnit(unit) ||
    isTemperatureUnit(unit) ||
    isBooleanUnit(unit) ||
    isUnknownUnit(unit)
  ) {
    return true
  }
  return false
}

type gasCaloriesUnit = gasUnit.kwh | gasUnit.btu | gasUnit.kj
export function isGasCaloriesUnit(unit?: unknown): unit is gasCaloriesUnit {
  if (unit === gasUnit.kwh || unit === gasUnit.btu || unit === gasUnit.kj) {
    return true
  }
  return false
}

type gasCarbonUnit = gasUnit.carbonTonnesMetric | gasUnit.carbonTonnesImperial
export function isGasCarbonUnit(unit?: unknown): unit is gasCarbonUnit {
  if (
    unit === gasUnit.carbonTonnesMetric ||
    unit === gasUnit.carbonTonnesImperial
  ) {
    return true
  }
  return false
}

type electricityCarbonUnit =
  | electricityUnit.carbonTonnesMetric
  | electricityUnit.carbonTonnesImperial
export function isElectricityCarbonUnit(
  unit?: unknown,
): unit is electricityCarbonUnit {
  if (
    unit === electricityUnit.carbonTonnesMetric ||
    unit === electricityUnit.carbonTonnesImperial
  ) {
    return true
  }
  return false
}

type complexUnit =
  | gasUnit.kwh
  | gasUnit.btu
  | gasUnit.kj
  | gasUnit.carbonTonnesMetric
  | gasUnit.carbonTonnesImperial
  | electricityUnit.carbonTonnesMetric
  | electricityUnit.carbonTonnesImperial

export function isComplexUnit(unit?: unknown): unit is complexUnit {
  if (
    unit === gasUnit.kwh ||
    unit === gasUnit.btu ||
    unit === gasUnit.kj ||
    unit === gasUnit.carbonTonnesMetric ||
    unit === gasUnit.carbonTonnesImperial ||
    unit === electricityUnit.carbonTonnesMetric ||
    unit === electricityUnit.carbonTonnesImperial
  ) {
    return true
  }
  return false
}

export type incomingUnit = Exclude<anyUnit, complexUnit>

export function isIncomingUnit(unit?: unknown): unit is incomingUnit {
  if (isCombiUnit(unit)) {
    return true
  }
  if (isUnit(unit) && !isComplexUnit(unit)) {
    return true
  }
  return false
}

export type defaultUnit =
  | electricityUnit.kwh
  | gasUnit.metersCubed
  | waterUnit.metersCubed
  | temperatureUnit.celsius
  | airQualityUnit.ppm
  | booleanUnit.boolean
  | unknownUnit.unknown

export function isDefaultUnit(unit?: unknown): unit is defaultUnit {
  if (
    unit === electricityUnit.kwh ||
    unit === gasUnit.metersCubed ||
    unit === waterUnit.metersCubed ||
    unit === temperatureUnit.celsius ||
    unit === airQualityUnit.ppm ||
    unit === booleanUnit.boolean ||
    unit === unknownUnit.unknown
  ) {
    return true
  }
  return false
}

export type combiUnit =
  | defaultUnit
  | combiPowerUnit.kwh
  | combiPowerUnit.btu
  | combiPowerUnit.kj
  | combiCarbonUnit.carbonTonnesMetric
  | combiCarbonUnit.carbonTonnesImperial

export function isCombiUnit(unit?: unknown): unit is combiUnit {
  if (
    isDefaultUnit(unit) ||
    isCombiPowerUnit(unit) ||
    isCombiCarbonUnit(unit)
  ) {
    return true
  }
  return false
}

export function isValidCombiConversion(
  startUnit: incomingUnit,
  endUnit: incomingUnit,
): boolean {
  if (startUnit === unknownUnit.unknown || endUnit === unknownUnit.unknown) {
    return true
  }

  // combi units
  if (
    getCategory(endUnit) === unitCategory.combiPower ||
    getCategory(endUnit) === unitCategory.combiCarbon
  ) {
    if (
      getCategory(startUnit) !== unitCategory.electricity &&
      getCategory(startUnit) !== unitCategory.gas
    ) {
      return false
    }
    return true
  }

  // other units
  if (getDefaultUnit(startUnit) === endUnit) {
    return true
  }
  return false
}
