import { createIcon } from '@chakra-ui/react'

const TotalGas = createIcon({
  displayName: 'Total Gas',
  viewBox: '0 0 158 235',
  path: (
    <g id="g12" transform="translate(-78.189339,-59.295294)">
      <path
        d="m 174.572,244.956 c 8.753,-1.751 20.728,-6.424 26.455,-10.604 27.209,-19.856 38.945,-56.887 15.2,-84.465 9.076,18.581 -19.016,44.617 -42.186,24.416 C 147.545,151.202 214.968,118.738 138.287,67 c 21.045,57.658 -78.138,73.79 -46.659,142.335 8.703,18.95 24.754,30.471 42.791,35.627"
        id="path6"
        style={{
          fill: 'none',
          stroke: 'currentColor',
          strokeWidth: 14.5906,
          strokeLinejoin: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="m 118.559,265.943 c 0,-7.904 0,21.057 0,21.057 h 74.942 v -21.057"
        id="path8"
        style={{
          fill: 'none',
          stroke: 'currentColor',
          strokeWidth: 14.5906,
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <line
        x1="156.03"
        y1="282.92599"
        x2="156.03"
        y2="264.97601"
        id="line10"
        style={{
          fill: 'none',
          stroke: 'currentColor',
          strokeWidth: 14.5906,
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
    </g>
  ),
})

export default TotalGas
