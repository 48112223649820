import { Box } from '@chakra-ui/react'
import displayScale from '../../utils/unitHelpers/displayScale'

type balanceMeterProps = {
  consumed: number | undefined
  generated: number | undefined
  colors: {
    primaryColor: string
    secondaryColor: string
    fontColor: string
  }
}

const BalanceMeter = ({
  consumed = 0,
  generated = 0,
  colors,
}: balanceMeterProps) => {
  let rot = ''
  let difference = consumed - generated
  if (difference > 10) {
    rot =
      'matrix(0.984807, -0.173648, 0.173648, 0.984807, -1163.925171, 1509.697876)'
  } else if (difference < -10) {
    rot =
      'matrix(0.984807, 0.173648, -0.173648, 0.984807, 1408.401978, -1284.650513)'
  }

  let scaledConsumedValue = displayScale(consumed, 1, 'kWh').value,
    // scaledConsumedUnit = displayScale(consumed, 1, 'kWh').unit,
    scaledGeneratedValue = displayScale(generated, 1, 'kWh').value,
    // scaledGeneratedUnit = displayScale(generated, 1, 'kWh').unit,
    formattedConsumedValue = scaledConsumedValue?.toString().includes('.')
      ? +scaledConsumedValue.toFixed(1)
      : scaledConsumedValue,
    formattedGeneratedValue = scaledGeneratedValue?.toString().includes('.')
      ? +scaledGeneratedValue.toFixed(1)
      : scaledGeneratedValue

  return (
    <>
      <Box
        as="svg"
        viewBox="0 0 13000 8000"
        xmlns="http://www.w3.org/2000/svg"
        h={'100%'}
        w={'100%'}
      >
        <g
          id="Layer_x0020_1"
          transform="matrix(1.000005, -0.000073, 0.000073, 1.000005, -1773.900757, -1233.206299)"
        >
          <g
            id="_1242101040"
            transform="matrix(1, 0, 0, 1, 0.000052, 0.000182)"
          >
            <polygon
              fill={colors.fontColor}
              points="7556.033 8804.017 8041.033 7964.017 8526.033 8804.017 8850.033 8804.017 8041.033 7404.017 7233.033 8804.017"
            />
            <g transform={rot} origin="0.517 0.707">
              <path
                fill={colors.fontColor}
                fillRule="nonzero"
                d="M 2803.86 8319.232 C 2739.859 8331.232 2678.858 8289.231 2666.858 8225.231 L 2592.858 7833.231 C 2580.858 7769.23 2622.858 7708.23 2686.858 7696.23 C 2750.86 7684.23 2811.86 7726.23 2823.86 7790.23 L 2875.86 8067.23 L 13164.876 6139.228 L 13112.875 5862.226 C 13100.874 5798.226 13142.875 5737.226 13206.876 5725.226 C 13270.876 5713.226 13331.876 5755.226 13343.876 5819.226 L 13417.875 6211.228 C 13429.875 6275.228 13387.875 6336.228 13323.876 6348.228 L 2803.86 8320.232 L 2803.86 8319.232 Z"
                transform="matrix(0.981627, 0.190808, -0.190808, 0.981627, 1486.977643, -1398.471667)"
              />
              <g
                id="1233357"
                transform="matrix(0.927186, 0.374609, -0.374609, 0.927186, 3444.82959, -2270.046143)"
                origin="0.526 0.483"
              >
                <path
                  fill="red"
                  d="M 3289.327 6030.78 C 3582.329 5975.78 3864.33 6168.78 3918.33 6461.781 C 3947.33 6615.781 3907.33 6766.781 3820.33 6883.781 L 4333.336 6787.781 C 4403.336 6774.781 4460.336 6823.781 4484.336 6890.781 L 4959.339 8244.786 C 4968.339 8268.786 4947.339 8294.786 4921.339 8299.786 L 3785.329 8512.788 L 2649.323 8725.792 C 2624.323 8730.792 2595.323 8713.792 2594.323 8687.791 L 2547.323 7253.781 C 2545.323 7182.781 2580.323 7115.78 2650.323 7102.78 L 3102.325 7017.781 C 2979.324 6940.781 2887.323 6813.781 2858.323 6659.781 C 2803.323 6366.781 2996.324 6084.78 3289.327 6030.78 Z M 3341.327 6305.781 C 3482.328 6279.781 3617.329 6371.781 3644.329 6512.781 C 3670.329 6653.781 3578.329 6788.781 3437.328 6815.781 C 3296.327 6841.781 3161.326 6749.781 3134.326 6608.781 C 3108.326 6467.781 3200.327 6332.781 3341.327 6305.781 Z"
                  transform="matrix(0.981627, -0.190809, 0.190809, 0.981627, -1338.055658, 851.845751)"
                />
                <g transform="matrix(0.981627, -0.190809, 0.190809, 0.981627, -167.78, 2386.74)">
                  <text
                    x="3340.629"
                    y="6320.525"
                    fill="white"
                    fontFamily="Verdana"
                    fontSize="700px"
                    fontWeight="bold"
                    style={{
                      transform: 'translate(-1600px, 900px) rotate(-11deg)',
                      textAnchor: 'middle',
                    }}
                    transform="matrix(0.981627, -0.190809, 0.190809, 0.981627, -1600, 900)"
                  >
                    {formattedConsumedValue}
                  </text>
                </g>
                <path
                  fill="#33CC33"
                  d="M 10770.149 3062.389 C 11062.962 3007.607 11343.316 3200.591 11398.138 3491.935 C 11426.798 3646.322 11386.925 3796.974 11300.95 3912.763 L 11813.061 3816.895 C 11882.839 3804.445 11940.155 3853.001 11963.832 3920.234 L 12438.561 5271.121 C 12447.282 5296.023 12426.101 5320.924 12401.181 5325.905 L 11267.31 5538.81 L 10133.437 5751.716 C 10108.518 5756.696 10079.86 5740.508 10078.614 5714.362 L 10031.266 4283.792 C 10028.774 4212.823 10064.907 4146.835 10134.685 4133.139 L 10585.739 4048.476 C 10462.386 3971.284 10371.425 3845.532 10341.522 3691.144 C 10286.698 3398.556 10479.829 3118.417 10771.396 3063.634 L 10770.149 3062.389 Z M 10821.235 3337.547 C 10962.034 3311.401 11096.605 3403.536 11122.771 3544.226 C 11148.936 3684.919 11056.732 3819.384 10915.933 3845.532 C 10775.134 3871.678 10640.564 3779.543 10614.398 3638.851 C 10588.232 3498.16 10680.438 3363.694 10821.235 3337.547 Z"
                  transform="matrix(0.981627, -0.190809, 0.190809, 0.981627, -633.669746, 2224.794653)"
                />
                <g transform="matrix(0.972031, -0.188943, 0.189434, 0.974551, -1105.85144, 1679.220337)">
                  <polygon
                    fill={colors.fontColor}
                    fillRule="nonzero"
                    points="10491.428 2199.028 10587.428 2199.028 10662.428 2550.339 10754.428 2199.028 10835.427 2199.028 10929.427 2550.339 11004.427 2199.028 11100.427 2199.028 10983.427 2750.02 10889.427 2750.02 10794.428 2393.372 10700.428 2750.02 10608.428 2750.02"
                    transform="matrix(0.981627, -0.190809, 0.190809, 0.981627, -273.80989, 2105.424173)"
                  />
                  <path
                    id="1"
                    fill={colors.fontColor}
                    fillRule="nonzero"
                    d="M 11550.646 2468.479 L 11243.647 2468.479 C 11247.647 2497.31 11259.647 2520.802 11278.647 2537.887 C 11297.647 2554.973 11322.647 2563.515 11351.647 2563.515 C 11387.647 2563.515 11417.646 2549.633 11443.646 2522.938 L 11523.646 2563.515 C 11503.646 2593.414 11479.646 2616.906 11451.646 2630.788 C 11423.646 2645.738 11390.647 2652.145 11351.647 2652.145 C 11291.647 2652.145 11243.647 2631.856 11205.648 2591.279 C 11168.649 2550.701 11149.649 2500.514 11149.649 2440.715 C 11149.649 2378.782 11168.649 2327.526 11205.648 2285.881 C 11242.647 2245.304 11289.647 2223.947 11346.647 2223.947 C 11406.647 2223.947 11455.646 2244.236 11492.646 2284.813 C 11530.646 2325.39 11548.646 2379.849 11548.646 2447.122 L 11548.646 2466.343 L 11550.646 2468.479 Z M 11455.646 2388.392 C 11449.646 2365.968 11436.646 2347.815 11418.646 2333.933 C 11399.647 2320.051 11378.647 2312.576 11353.647 2312.576 C 11326.647 2312.576 11303.647 2320.051 11283.647 2336.069 C 11270.647 2345.679 11259.647 2362.764 11248.647 2387.324 L 11456.646 2387.324 L 11455.646 2388.392 Z"
                    transform="matrix(0.981627, -0.190809, 0.190809, 0.981627, -256.66692, 2210.504515)"
                  />
                  <path
                    id="2"
                    fill={colors.fontColor}
                    fillRule="nonzero"
                    d="M 10061.474 3261.888 L 9992.476 3334.5 C 9948.476 3285.38 9897.476 3260.82 9839.476 3260.82 C 9788.476 3260.82 9746.476 3278.973 9712.476 3314.211 C 9678.476 3350.517 9661.476 3393.23 9661.476 3445.554 C 9661.476 3498.945 9679.476 3543.794 9714.476 3580.099 C 9750.476 3617.471 9793.476 3635.624 9845.476 3635.624 C 9879.476 3635.624 9907.476 3628.15 9931.476 3613.201 C 9954.476 3598.252 9973.476 3573.692 9987.476 3541.658 L 9838.476 3541.658 L 9838.476 3443.418 L 10097.474 3443.418 L 10098.474 3466.91 C 10098.474 3514.962 10086.474 3560.878 10063.474 3603.591 C 10040.474 3646.303 10009.476 3679.405 9972.476 3702.897 C 9935.476 3725.322 9891.476 3737.068 9841.476 3737.068 C 9787.476 3737.068 9740.476 3724.254 9698.476 3699.694 C 9656.476 3675.134 9623.476 3639.896 9598.476 3593.98 C 9574.476 3548.065 9561.476 3498.945 9561.476 3445.554 C 9561.476 3372.942 9584.476 3308.872 9629.476 3255.481 C 9683.476 3191.413 9752.476 3159.379 9838.476 3159.379 C 9883.476 3159.379 9925.476 3167.921 9964.476 3186.073 C 9997.476 3201.023 10029.475 3226.65 10061.474 3262.956 L 10061.474 3261.888 Z"
                    transform="matrix(0.981627, -0.190809, 0.190809, 0.981627, -477.347759, 1939.001439)"
                  />
                  <path
                    id="3"
                    fill={colors.fontColor}
                    fillRule="nonzero"
                    d="M 10562.995 3445.852 L 10255.996 3445.852 C 10259.996 3474.683 10271.996 3498.175 10290.996 3515.26 C 10309.996 3532.346 10334.996 3540.888 10363.996 3540.888 C 10399.996 3540.888 10429.996 3527.007 10455.996 3500.311 L 10535.995 3540.888 C 10515.996 3570.785 10491.996 3594.277 10463.996 3608.159 C 10435.996 3623.109 10402.996 3629.516 10363.996 3629.516 C 10303.996 3629.516 10255.996 3609.227 10217.996 3568.65 C 10180.999 3528.074 10161.999 3477.887 10161.999 3418.088 C 10161.999 3356.155 10180.999 3304.901 10217.996 3263.256 C 10254.996 3222.679 10301.996 3201.322 10358.996 3201.322 C 10418.996 3201.322 10467.996 3221.611 10504.996 3262.188 C 10542.995 3302.765 10560.995 3357.222 10560.995 3424.495 L 10560.995 3443.716 L 10562.995 3445.852 Z M 10467.996 3365.765 C 10461.996 3343.341 10448.996 3325.189 10430.996 3311.308 C 10411.996 3297.426 10390.996 3289.952 10365.996 3289.952 C 10338.996 3289.952 10315.996 3297.426 10295.996 3313.444 C 10282.996 3323.053 10271.996 3340.138 10260.996 3364.697 L 10468.996 3364.697 L 10467.996 3365.765 Z"
                    transform="matrix(0.981627, -0.190809, 0.190809, 0.981627, -461.304448, 2040.008764)"
                  />
                  <path
                    id="4"
                    fill={colors.fontColor}
                    fillRule="nonzero"
                    d="M 10631.968 3127.854 L 10727.967 3127.854 L 10727.967 3169.498 C 10749.967 3150.279 10768.967 3136.397 10786.967 3128.922 C 10804.967 3121.447 10822.967 3117.176 10840.967 3117.176 C 10878.967 3117.176 10910.967 3131.058 10936.967 3159.889 C 10958.967 3184.448 10969.967 3219.686 10969.967 3265.602 L 10969.967 3534.692 L 10874.967 3534.692 L 10874.967 3356.367 C 10874.967 3308.315 10872.967 3275.212 10868.967 3259.195 C 10864.967 3243.177 10857.967 3231.432 10847.967 3222.89 C 10837.967 3214.347 10824.967 3210.076 10809.967 3210.076 C 10790.967 3210.076 10773.967 3216.483 10759.967 3230.364 C 10745.967 3244.245 10736.967 3263.466 10730.967 3288.026 C 10727.967 3300.84 10726.967 3328.603 10726.967 3370.248 L 10726.967 3533.624 L 10630.968 3533.624 L 10630.968 3126.786 L 10631.968 3127.854 Z"
                    transform="matrix(0.981627, -0.190809, 0.190809, 0.981627, -436.183122, 2121.933388)"
                  />
                  <path
                    id="5"
                    fill={colors.fontColor}
                    fillRule="nonzero"
                    d="M 11437.623 3275.843 L 11130.624 3275.843 C 11134.624 3304.674 11146.624 3328.166 11165.624 3345.251 C 11184.624 3362.337 11209.623 3370.879 11238.623 3370.879 C 11274.623 3370.879 11304.623 3356.998 11330.623 3330.302 L 11410.623 3370.879 C 11390.623 3400.777 11366.623 3424.269 11338.623 3438.151 C 11310.623 3453.101 11277.623 3459.508 11238.623 3459.508 C 11178.624 3459.508 11130.624 3439.219 11092.625 3398.642 C 11055.625 3358.065 11036.625 3307.878 11036.625 3248.079 C 11036.625 3186.146 11055.625 3134.89 11092.625 3093.246 C 11129.624 3052.669 11176.624 3031.312 11233.623 3031.312 C 11293.623 3031.312 11342.623 3051.601 11379.623 3092.178 C 11417.623 3132.754 11435.623 3187.213 11435.623 3254.486 L 11435.623 3273.707 L 11437.623 3275.843 Z M 11342.623 3195.756 C 11336.623 3173.332 11323.623 3155.179 11305.623 3141.297 C 11286.623 3127.415 11265.623 3119.942 11240.623 3119.942 C 11213.623 3119.942 11190.623 3127.415 11170.624 3143.433 C 11157.624 3153.043 11146.624 3170.128 11135.624 3194.688 L 11343.623 3194.688 L 11342.623 3195.756 Z"
                    transform="matrix(0.981627, -0.190809, 0.190809, 0.981627, -412.795866, 2203.771967)"
                  />
                  <path
                    id="6"
                    fill={colors.fontColor}
                    fillRule="nonzero"
                    d="M 11485.341 2974.912 L 11568.341 2974.912 L 11568.341 3026.168 C 11577.341 3005.879 11589.341 2990.93 11603.341 2980.251 C 11618.341 2969.573 11634.341 2964.234 11651.341 2964.234 C 11663.341 2964.234 11676.341 2967.437 11690.341 2974.912 L 11660.341 3063.542 C 11649.341 3057.135 11640.341 3054.999 11632.341 3054.999 C 11617.341 3054.999 11605.341 3064.609 11595.341 3083.83 C 11585.341 3103.051 11580.341 3141.493 11580.341 3198.087 L 11580.341 3218.376 L 11580.341 3382.821 L 11484.341 3382.821 L 11484.341 2975.98 L 11485.341 2974.912 Z"
                    transform="matrix(0.981627, -0.190809, 0.190809, 0.981627, -392.64553, 2269.275696)"
                  />
                  <path
                    id="7"
                    fill={colors.fontColor}
                    fillRule="nonzero"
                    d="M 12015.064 2911.582 L 12111.064 2911.582 L 12111.064 3318.421 L 12015.064 3318.421 L 12015.064 3274.64 C 11996.064 3293.861 11978.064 3306.675 11959.064 3315.218 C 11940.064 3323.76 11920.064 3328.032 11898.064 3328.032 C 11849.064 3328.032 11806.065 3307.743 11770.065 3267.166 C 11734.065 3226.588 11716.066 3176.401 11716.066 3115.536 C 11716.066 3052.534 11733.065 3001.278 11768.065 2960.701 C 11803.065 2921.192 11845.065 2900.905 11895.064 2900.905 C 11918.064 2900.905 11939.064 2905.176 11960.064 2914.785 C 11980.064 2923.327 11999.064 2937.209 12016.064 2955.362 L 12016.064 2910.514 L 12015.064 2911.582 Z M 11914.064 2994.872 C 11884.064 2994.872 11860.064 3005.55 11840.065 3027.974 C 11820.065 3050.398 11811.065 3079.23 11811.065 3113.4 C 11811.065 3148.638 11821.065 3177.468 11841.065 3199.893 C 11861.064 3222.317 11886.064 3234.063 11915.064 3234.063 C 11945.064 3234.063 11970.064 3223.385 11990.064 3200.961 C 12010.064 3178.536 12020.064 3149.706 12020.064 3113.4 C 12020.064 3078.162 12010.064 3049.331 11990.064 3026.906 C 11970.064 3005.55 11945.064 2993.804 11915.064 2993.804 L 11914.064 2994.872 Z"
                    transform="matrix(0.981627, -0.190809, 0.190809, 0.981627, -375.382856, 2330.437282)"
                  />
                  <polygon
                    id="8"
                    fill={colors.fontColor}
                    fillRule="nonzero"
                    points="12197.465 2695.512 12293.465 2695.512 12293.465 2845.007 12349.465 2845.007 12349.465 2933.634 12293.465 2933.634 12293.465 3252.913 12197.465 3252.913 12197.465 2933.634 12148.465 2933.634 12148.465 2845.007 12197.465 2845.007"
                    transform="matrix(0.981627, -0.190809, 0.190809, 0.981627, -342.458524, 2391.857628)"
                  />
                  <path
                    id="9"
                    fill={colors.fontColor}
                    fillRule="nonzero"
                    d="M 12791.282 3012.721 L 12484.282 3012.721 C 12488.282 3041.552 12500.282 3065.044 12519.282 3082.129 C 12538.282 3099.215 12563.282 3107.756 12592.282 3107.756 C 12628.282 3107.756 12658.282 3093.876 12684.282 3067.18 L 12764.282 3107.756 C 12744.282 3137.655 12720.282 3161.147 12692.282 3175.029 C 12664.282 3189.979 12631.282 3196.386 12592.282 3196.386 C 12532.282 3196.386 12484.282 3176.097 12446.282 3135.52 C 12409.283 3094.943 12390.283 3044.756 12390.283 2984.957 C 12390.283 2923.024 12409.283 2871.768 12446.282 2830.123 C 12483.282 2789.546 12530.282 2768.189 12587.282 2768.189 C 12647.282 2768.189 12696.282 2788.478 12733.282 2829.055 C 12771.282 2869.632 12789.282 2924.091 12789.282 2991.364 L 12789.282 3010.585 L 12791.282 3012.721 Z M 12696.282 2932.634 C 12690.282 2910.21 12677.282 2892.057 12659.282 2878.175 C 12640.282 2864.293 12619.282 2856.819 12594.282 2856.819 C 12567.282 2856.819 12544.282 2864.293 12524.282 2880.311 C 12511.282 2889.921 12500.282 2907.006 12489.282 2931.566 L 12697.282 2931.566 L 12696.282 2932.634 Z"
                    transform="matrix(0.981627, -0.190809, 0.190809, 0.981627, -337.719173, 2457.227847)"
                  />
                </g>
                <g transform="matrix(0.898795, -0.438372, 0.438372, 0.898795, -2323.121094, 4045.477295)">
                  <polygon
                    fill={colors.fontColor}
                    fillRule="nonzero"
                    points="3447.389 2653.777 3543.389 2653.777 3618.389 2982.775 3710.39 2653.777 3791.39 2653.777 3885.39 2982.775 3960.389 2653.777 4056.389 2653.777 3939.389 3168.777 3845.39 3168.777 3750.39 2835.775 3657.39 3168.777 3564.389 3168.777"
                    transform="matrix(0.99863, 0.052336, -0.052336, 0.99863, 157.506416, -192.36901)"
                  />
                  <path
                    id="1"
                    fill={colors.fontColor}
                    fillRule="nonzero"
                    d="M 4500.178 3034.575 L 4193.178 3034.575 C 4197.178 3061.575 4209.178 3083.575 4228.178 3099.575 C 4247.178 3115.575 4272.178 3123.575 4301.178 3123.575 C 4337.178 3123.575 4367.178 3110.575 4393.178 3085.575 L 4473.178 3123.575 C 4453.178 3151.575 4429.178 3173.575 4401.178 3186.575 C 4373.178 3200.575 4340.178 3206.575 4301.178 3206.575 C 4241.178 3206.575 4193.178 3187.575 4155.178 3149.575 C 4118.178 3111.575 4099.178 3064.575 4099.178 3008.575 C 4099.178 2950.575 4118.178 2902.573 4155.178 2863.573 C 4192.178 2825.573 4239.178 2805.573 4296.178 2805.573 C 4356.178 2805.573 4405.178 2824.573 4442.178 2862.573 C 4480.178 2900.573 4498.178 2951.575 4498.178 3014.575 L 4498.178 3032.575 L 4500.178 3034.575 Z M 4405.178 2959.575 C 4399.178 2938.574 4386.178 2921.573 4368.178 2908.573 C 4349.178 2895.573 4328.178 2888.573 4303.178 2888.573 C 4276.178 2888.573 4253.178 2895.573 4233.178 2910.573 C 4220.178 2919.573 4209.178 2935.574 4198.178 2958.575 L 4406.178 2958.575 L 4405.178 2959.575 Z"
                    transform="matrix(0.99863, 0.052336, -0.052336, 0.99863, 163.218421, -220.908189)"
                  />
                  <path
                    id="2"
                    fill={colors.fontColor}
                    fillRule="nonzero"
                    d="M 2811.187 3404.338 L 2742.187 3469.338 C 2695.187 3420.338 2643.187 3395.338 2584.187 3395.338 C 2535.187 3395.338 2493.188 3412.338 2459.188 3446.338 C 2425.188 3480.338 2408.188 3521.338 2408.188 3570.338 C 2408.188 3604.338 2416.188 3635.338 2431.188 3661.338 C 2446.188 3688.338 2467.188 3709.338 2494.188 3724.338 C 2521.188 3739.338 2552.187 3747.338 2585.187 3747.338 C 2613.187 3747.338 2640.187 3742.338 2663.187 3731.338 C 2687.187 3720.338 2713.187 3701.338 2741.187 3673.338 L 2808.187 3743.338 C 2770.187 3780.338 2734.187 3806.338 2700.187 3820.338 C 2666.187 3834.338 2627.187 3842.338 2584.187 3842.338 C 2504.188 3842.338 2438.188 3817.338 2387.188 3766.338 C 2336.188 3715.338 2310.188 3650.338 2310.188 3571.338 C 2310.188 3520.338 2322.188 3474.338 2345.188 3434.338 C 2368.188 3394.338 2402.188 3362.338 2445.188 3338.338 C 2488.188 3314.338 2535.187 3301.338 2585.187 3301.338 C 2628.187 3301.338 2668.187 3310.338 2708.187 3328.338 C 2747.187 3346.338 2781.187 3371.338 2810.187 3405.338 L 2811.187 3404.338 Z"
                    transform="matrix(0.99863, 0.052336, -0.052336, 0.99863, 190.445014, -129.121032)"
                  />
                  <path
                    id="3"
                    fill={colors.fontColor}
                    fillRule="nonzero"
                    d="M 3060.986 3465.807 C 3096.985 3465.807 3130.985 3474.807 3162.985 3492.807 C 3194.985 3510.807 3219.984 3534.807 3236.984 3565.807 C 3254.984 3596.807 3263.984 3629.807 3263.984 3665.807 C 3263.984 3701.807 3254.984 3734.807 3236.984 3766.807 C 3218.984 3797.807 3194.985 3822.807 3163.985 3839.807 C 3132.985 3857.807 3098.985 3865.807 3061.986 3865.807 C 3006.986 3865.807 2959.986 3846.807 2921.985 3807.807 C 2882.985 3768.807 2863.985 3721.807 2863.985 3665.807 C 2863.985 3605.807 2885.985 3556.807 2929.985 3516.807 C 2967.986 3482.807 3012.986 3464.807 3061.986 3464.807 L 3060.986 3465.807 Z M 3061.986 3555.807 C 3031.986 3555.807 3006.986 3565.807 2987.986 3586.807 C 2967.986 3607.807 2957.986 3633.807 2957.986 3665.807 C 2957.986 3698.807 2967.986 3725.807 2986.986 3745.807 C 3006.986 3765.807 3030.986 3776.807 3060.986 3776.807 C 3090.985 3776.807 3115.985 3766.807 3135.985 3745.807 C 3155.985 3724.807 3165.985 3698.807 3165.985 3665.807 C 3165.985 3632.807 3155.985 3606.807 3136.985 3585.807 C 3116.985 3565.807 3091.985 3554.807 3061.986 3554.807 L 3061.986 3555.807 Z"
                    transform="matrix(0.99863, 0.052336, -0.052336, 0.99863, 196.02656, -155.333488)"
                  />
                  <path
                    id="4"
                    fill={colors.fontColor}
                    fillRule="nonzero"
                    d="M 3341.635 3498.182 L 3437.635 3498.182 L 3437.635 3537.182 C 3459.635 3519.182 3478.634 3506.182 3496.634 3499.182 C 3514.633 3492.182 3532.633 3488.182 3550.633 3488.182 C 3588.633 3488.182 3620.633 3501.182 3646.633 3528.182 C 3668.633 3551.182 3679.633 3584.182 3679.633 3627.182 L 3679.633 3879.181 L 3584.633 3879.181 L 3584.633 3712.182 C 3584.633 3667.182 3582.633 3636.182 3578.633 3621.182 C 3574.633 3606.182 3567.633 3595.182 3557.633 3587.182 C 3547.633 3579.182 3534.633 3575.182 3519.633 3575.182 C 3500.633 3575.182 3483.634 3581.182 3469.634 3594.182 C 3455.635 3607.182 3446.635 3625.182 3440.635 3648.182 C 3437.635 3660.182 3436.635 3686.182 3436.635 3725.182 L 3436.635 3878.181 L 3340.635 3878.181 L 3340.635 3497.182 L 3341.635 3498.182 Z"
                    transform="matrix(0.99863, 0.052336, -0.052336, 0.99863, 197.599637, -178.657981)"
                  />
                  <path
                    id="5"
                    fill={colors.fontColor}
                    fillRule="nonzero"
                    d="M 4004.865 3571.512 L 3945.865 3630.512 C 3921.866 3606.512 3899.866 3594.512 3880.866 3594.512 C 3869.866 3594.512 3861.865 3596.512 3855.865 3601.512 C 3849.865 3606.512 3846.865 3611.512 3846.865 3618.512 C 3846.865 3623.512 3848.865 3628.512 3852.865 3632.512 C 3856.865 3636.512 3865.865 3642.512 3881.866 3650.512 L 3916.866 3667.512 C 3953.865 3685.512 3978.865 3704.511 3992.865 3722.511 C 4006.865 3741.511 4013.865 3763.511 4013.865 3788.511 C 4013.865 3822.511 4001.865 3850.511 3976.865 3873.511 C 3951.865 3896.511 3918.866 3907.511 3876.866 3907.511 C 3820.865 3907.511 3776.865 3885.511 3743.865 3842.511 L 3802.865 3778.511 C 3813.865 3791.511 3826.865 3802.511 3842.865 3810.511 C 3857.865 3818.511 3870.866 3822.511 3882.866 3822.511 C 3895.866 3822.511 3905.866 3819.511 3913.866 3813.511 C 3921.866 3807.511 3925.866 3800.511 3925.866 3792.511 C 3925.866 3777.511 3911.866 3763.511 3883.866 3749.511 L 3851.865 3733.511 C 3789.865 3702.511 3758.865 3663.512 3758.865 3617.512 C 3758.865 3587.512 3770.865 3561.512 3793.865 3540.512 C 3816.865 3519.512 3846.865 3508.512 3882.866 3508.512 C 3907.866 3508.512 3930.865 3514.512 3952.865 3524.512 C 3974.865 3535.512 3992.865 3551.512 4007.865 3571.512 L 4004.865 3571.512 Z"
                    transform="matrix(0.99863, 0.052336, -0.052336, 0.99863, 199.378312, -197.922527)"
                  />
                  <path
                    id="6"
                    fill={colors.fontColor}
                    fillRule="nonzero"
                    d="M 4087.087 3537.237 L 4184.087 3537.237 L 4184.087 3720.236 C 4184.087 3756.236 4187.087 3780.236 4191.087 3794.236 C 4196.087 3808.236 4204.087 3819.236 4215.087 3826.236 C 4226.087 3834.236 4239.087 3838.236 4255.087 3838.236 C 4271.087 3838.236 4285.087 3834.236 4296.087 3827.236 C 4307.087 3819.236 4315.087 3808.236 4321.087 3794.236 C 4325.087 3783.236 4327.087 3760.236 4327.087 3724.236 L 4327.087 3537.237 L 4423.087 3537.237 L 4423.087 3698.236 C 4423.087 3765.236 4418.087 3810.236 4407.087 3835.236 C 4394.087 3865.236 4375.087 3888.236 4350.087 3904.236 C 4325.087 3920.236 4293.087 3928.236 4255.087 3928.236 C 4213.087 3928.236 4179.087 3919.236 4153.087 3900.236 C 4127.087 3881.236 4109.087 3855.236 4098.087 3822.236 C 4090.087 3799.236 4087.087 3757.236 4087.087 3696.236 L 4087.087 3537.237 Z"
                    transform="matrix(0.99863, 0.052336, -0.052336, 0.99863, 201.187921, -217.578595)"
                  />
                  <path
                    id="7"
                    fill={colors.fontColor}
                    fillRule="nonzero"
                    d="M 4516.771 3566.35 L 4612.771 3566.35 L 4612.771 3611.35 C 4628.771 3593.35 4647.771 3579.35 4667.771 3571.35 C 4687.771 3562.35 4709.771 3558.35 4733.771 3558.35 C 4757.771 3558.35 4779.771 3564.35 4798.771 3576.35 C 4817.771 3588.35 4833.771 3605.35 4844.771 3627.35 C 4859.771 3604.35 4878.771 3587.35 4900.771 3576.35 C 4922.771 3564.35 4946.771 3558.35 4973.771 3558.35 C 5000.771 3558.35 5024.771 3564.35 5045.771 3577.35 C 5066.771 3590.35 5080.771 3606.35 5090.771 3626.35 C 5099.771 3646.35 5104.771 3679.35 5104.771 3725.35 L 5104.771 3948.348 L 5008.771 3948.348 L 5008.771 3755.35 C 5008.771 3712.35 5003.771 3683.35 4992.771 3667.35 C 4981.771 3652.35 4965.771 3644.35 4943.771 3644.35 C 4926.771 3644.35 4912.771 3649.35 4898.771 3658.35 C 4885.771 3667.35 4875.771 3680.35 4869.771 3697.35 C 4863.771 3713.35 4859.771 3740.35 4859.771 3776.35 L 4859.771 3947.348 L 4763.771 3947.348 L 4763.771 3763.35 C 4763.771 3729.35 4760.771 3704.35 4755.771 3689.35 C 4750.771 3674.35 4742.771 3662.35 4732.771 3655.35 C 4722.771 3648.35 4709.771 3644.35 4695.771 3644.35 C 4679.771 3644.35 4664.771 3649.35 4651.771 3658.35 C 4638.771 3667.35 4628.771 3681.35 4622.771 3698.35 C 4616.771 3715.35 4612.771 3742.35 4612.771 3778.35 L 4612.771 3947.348 L 4516.771 3947.348 L 4516.771 3566.35 Z"
                    transform="matrix(0.99863, 0.052336, -0.052336, 0.99863, 203.028243, -246.63263)"
                  />
                  <path
                    id="8"
                    fill={colors.fontColor}
                    fillRule="nonzero"
                    d="M 5573.867 3813.836 L 5266.866 3813.836 C 5270.866 3840.834 5282.866 3862.834 5301.866 3878.834 C 5320.866 3894.834 5345.866 3902.834 5374.866 3902.834 C 5410.866 3902.834 5440.868 3889.834 5466.867 3864.834 L 5546.867 3902.834 C 5526.867 3930.834 5502.867 3952.834 5474.867 3965.834 C 5446.868 3979.834 5413.866 3985.834 5374.866 3985.834 C 5314.866 3985.834 5266.866 3966.834 5228.866 3928.834 C 5191.866 3890.834 5172.867 3843.834 5172.867 3787.837 C 5172.867 3729.837 5191.866 3681.837 5228.866 3642.837 C 5265.866 3604.837 5312.866 3584.837 5369.866 3584.837 C 5429.867 3584.837 5478.867 3603.837 5515.867 3641.837 C 5553.867 3679.837 5571.867 3730.837 5571.867 3793.837 L 5571.867 3811.836 L 5573.867 3813.836 Z M 5478.867 3738.837 C 5472.867 3717.837 5459.867 3700.837 5441.868 3687.837 C 5422.866 3674.837 5401.866 3667.837 5376.866 3667.837 C 5349.866 3667.837 5326.866 3674.837 5306.866 3689.837 C 5293.866 3698.837 5282.866 3714.837 5271.866 3737.837 L 5479.867 3737.837 L 5478.867 3738.837 Z"
                    transform="matrix(0.99863, 0.052336, -0.052336, 0.99863, 205.4733, -276.03281)"
                  />
                </g>
                {/* <text
                  x="10817.575"
                  y="5866.787"
                  fill={colors.fontColor}
                  fontFamily="Verdana"
                  fontSize="500px"
                  style={{
                    transform: 'translate(-6800px, 3400px) rotate(-22deg)',
                    textAnchor: 'middle',
                  }}
                  transform="matrix(0.981627, -0.190809, 0.190809, 0.981627, -1100, 2200)"
                >
                  ({scaledConsumedUnit})
                </text>
                <text
                  x="10817.575"
                  y="5866.787"
                  fill={colors.fontColor}
                  fontFamily="Verdana"
                  fontSize="500px"
                  style={{
                    transform: 'translate(700px, 500px) rotate(-22deg)',
                    textAnchor: 'middle',
                  }}
                  transform="matrix(0.981627, -0.190809, 0.190809, 0.981627, -1100, 2200)"
                >
                  ({scaledGeneratedUnit})
                </text> */}
                <g transform="matrix(0.981628, -0.19081, 0.19081, 0.981628, -201.55, 1442.24)">
                  <text
                    x="10817.575"
                    y="5866.787"
                    fill="white"
                    fontFamily="Verdana"
                    fontSize="700px"
                    fontWeight="bold"
                    style={{
                      transform: 'translate(-1100px, 2200px) rotate(-11deg)',
                      textAnchor: 'middle',
                    }}
                    transform="matrix(0.981627, -0.190809, 0.190809, 0.981627, -1100, 2200)"
                  >
                    {formattedGeneratedValue}
                  </text>
                </g>
              </g>
            </g>
          </g>
        </g>
      </Box>
    </>
  )
}

export default BalanceMeter
