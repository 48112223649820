import { createIcon } from '@chakra-ui/react'

const Lighting = createIcon({
  displayName: 'Lighting',
  viewBox: '0 0 152 220',
  path: (
    <g id="g8" transform="translate(-92.594,-64.976)">
      <path
        d="m 243.966,140.66 c 0,-41.732 -33.952,-75.684 -75.686,-75.684 -41.734,0 -75.686,33.952 -75.686,75.684 0,17.936 6.402,35.341 18.015,48.998 0,0 0.247,0.293 0.715,0.816 0.4,0.465 0.806,0.928 1.165,1.321 11.331,12.761 14.137,15.564 15.069,16.427 0.798,0.897 5.423,6.378 5.499,12.953 v 35.735 c 0,10.079 7.105,18.529 16.57,20.607 2.231,4.42 6.812,7.458 12.09,7.458 h 13.141 c 5.278,0 9.859,-3.038 12.089,-7.457 9.465,-2.077 16.571,-10.528 16.571,-20.608 v -35.958 c 0.213,-6.549 4.75,-11.906 5.476,-12.723 0.926,-0.856 3.715,-3.635 15.013,-16.364 0.421,-0.461 0.827,-0.927 1.229,-1.391 0.459,-0.513 0.705,-0.804 0.705,-0.804 0,0 0,0 -0.001,0 11.624,-13.669 18.026,-31.074 18.026,-49.01 z m -54.375,83.997 v 0.337 l -42.605,12.874 v -13.211 z m -0.001,16.376 -42.604,12.874 v -8.764 l 42.604,-12.874 z m -7.167,23.046 h -7.961 v 6.964 l -12.35,0.005 v -6.969 h -7.955 c -2.509,0 -4.717,-1.296 -5.999,-3.251 l 41.431,-12.519 -0.001,8.602 c 0.001,3.952 -3.214,7.168 -7.165,7.168 z m 32.901,-83.426 c -0.029,0.035 -0.203,0.237 -0.507,0.575 l -0.092,0.105 c -0.329,0.382 -0.661,0.765 -1.071,1.215 -9.038,10.182 -13.287,14.719 -14.18,15.511 l -0.374,0.357 c -0.861,0.887 -7.86,8.412 -9.261,19.277 h -43.113 c -1.399,-10.86 -8.399,-18.385 -9.271,-19.281 l -0.369,-0.354 c -0.885,-0.785 -5.129,-5.318 -14.245,-15.583 -0.346,-0.378 -0.678,-0.761 -1.007,-1.143 l -0.092,-0.105 c -0.304,-0.338 -0.477,-0.541 -0.512,-0.581 -9.484,-11.153 -14.707,-25.354 -14.707,-39.987 0,-34.052 27.704,-61.755 61.758,-61.755 34.054,0 61.757,27.703 61.757,61.755 -0.001,14.634 -5.224,28.835 -14.714,39.994 z"
        id="path6"
        fill="currentColor"
      />
    </g>
  ),
})

export default Lighting
