import { Container, Heading, Text } from '@chakra-ui/react'
import { useRouteError } from 'react-router-dom'

type ErrorResponse = {
  status: number
  statusText: string
  data: any
}

const ErrorPage = () => {
  const error = useRouteError() as ErrorResponse

  console.log(error)

  return (
    <Container>
      <Heading size="xxl">Oops!</Heading>
      <Text>Sorry, and unexpected error has occurred</Text>
      <Text>
        {error.status} <i>{error.statusText}</i>
      </Text>
    </Container>
  )
}

export default ErrorPage
