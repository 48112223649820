import {
  Center,
  ComponentWithAs,
  GridItem,
  IconProps,
  SimpleGrid,
} from '@chakra-ui/react'
import { Fragment } from 'react'
import WholeBuilding from '../../elements/icons/wholeBuilding'
import PfHeadlineItem from '../../elements/pfHeadlineItem'

export type PfHeadlineItemProps = {
  Icon: ComponentWithAs<'svg', IconProps>
  label: string
  value: number
  target: number
  units: string
  description: string
  upIsBetter: boolean
}

type PfHeadlineProps = {
  items?: PfHeadlineItemProps[]
  loading: boolean
  period?: string
  isDashboard?: boolean
}

// Note: use valid translation strings in loading element
const loadingItem: PfHeadlineItemProps = {
  Icon: WholeBuilding,
  label: 'buildingEnergy',
  value: 123.3,
  target: 140,
  units: 'MWh',
  description: 'totalEnergyUsed',
  upIsBetter: false,
}

const PfHeadline = ({
  items,
  loading,
  period,
  isDashboard = false,
}: PfHeadlineProps) => {
  const wholeBuilding = items?.find((item) => item.label === 'buildingEnergy')
  const renewables = items?.find((item) => item.label === 'renewables')
  const carbon = items?.find((item) => item.label === 'carbonDioxide')
  const water = items?.find((item) => item.label === 'water')
  // const unknown = items?.find((item) => item.label === 'unknown')

  const orderedItems = [wholeBuilding, renewables, carbon, water]

  const headlineItems: PfHeadlineItemProps[] =
    loading ||
    orderedItems.every(
      (value: PfHeadlineItemProps | undefined) => value === undefined,
    )
      ? Array(4)
          .fill(loadingItem)
          .map((placeholder, idx) => ({
            ...placeholder,
            slug: `loading-placeholder-${idx}`,
          }))
      : orderedItems

  return (
    <SimpleGrid
      backgroundColor={'gray.300'}
      py={isDashboard ? 0 : 4}
      px={isDashboard ? 0 : '0.5em'}
      h={isDashboard ? '100%' : 'unset'}
      spacing={isDashboard ? { base: 4, lg: 10 } : 6}
      columns={4}
    >
      {headlineItems.slice(0, 4).map((item, idx) => (
        <Fragment key={`Item${idx}`}>
          <GridItem
            colSpan={{ base: 2, sm: 2, md: 1, lg: 1 }}
            overflow={'hidden'}
          >
            <Center h={isDashboard ? '100%' : 'unset'}>
              <PfHeadlineItem
                {...item}
                loading={loading}
                period={period}
                isDashboard={isDashboard}
              />
            </Center>
          </GridItem>
        </Fragment>
      ))}
    </SimpleGrid>
  )
}

export default PfHeadline
