import { Box, Center, Heading, Text, useMediaQuery } from '@chakra-ui/react'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import {
  XYChart,
  AnimatedAxis,
  AnimatedGrid,
  AnimatedBarGroup,
  AnimatedBarSeries,
  Tooltip,
  // RenderTooltipParams,
} from '@visx/xychart'
import { useTranslation } from 'react-i18next'
import { PortfolioTypes } from '../../../graphql/generated/graphql'
import { topLargest } from '../../../utils/helpers/basicFunc'
import { indicatorTypeData } from '../../../utils/indicators/indicators'
import { mediaQueries } from '../../../utils/mediaQueries/mediaQueries'
import { unknownUnit } from '../../../utils/unitMaps/unitEnums'
import Legend from '../../graphPlots/Legend'
import GraphReplacement from '../../graphReplacement/graphReplacement'

type RenderTooltipFunction = (
  params: any, // REMOVED SUPPORT FOR THE BELOW LINE (RenderTooltipParams), NOT ABLE TO ASSIGN TYPE
  // params: RenderTooltipParams<SecondaryGraphData>,
) => JSX.Element

export const overlayStyles = {
  position: 'absolute' as const,
  width: '100%',
  height: '100%',
  background: '#ffffffc7',
  borderRadius: '5px',
  zIndex: 1,
}

const accessors = {
  xAccessor: (d: SecondaryGraphData) => (
    <Text>
      Energy Used: {d.measurement}&thinsp;kWh, Energy Target: {d.target}
      &thinsp;kWh
    </Text>
  ),
  label: (d: SecondaryGraphData) => indicatorTypeData[d.type!]?.name,
}

export type SecondaryGraphData = {
  target: number
  measurement: number
  type: string
}

export function buildPlotData(
  isDistribution: boolean,
  portfolio?: PortfolioTypes,
) {
  const identifyKey = (key: string) => {
    let type = ''
    switch (key) {
      case 'coolingEnergy':
        type = 'Cooling'
        break
      case 'lightingEnergy':
        type = 'Lighting'
        break
      case 'heatingEnergy':
        type = 'Heating'
        break
      case 'processEnergy':
        type = 'ProcessPower'
        break
      case 'socketPower':
        type = 'SmallPower'
        break
      case 'ventilation':
        type = 'Ventilation'
        break
    }
    return type
  }

  const secondaryGraphData: SecondaryGraphData[] = []

  for (const [key, value] of Object.entries(portfolio || {})) {
    if (identifyKey(key)) {
      secondaryGraphData.push({
        type: identifyKey(key),
        measurement: value?.measurement ?? 0,
        target: value?.target ?? 0,
      })
    }
  }

  const isGraphInvalid = secondaryGraphData?.every(
    (secondaryData) => !secondaryData?.measurement,
  )
  const defaultPlotData = [
    {
      type: 'Lighting',
      measurement: 500,
      target: 2000,
    },
    {
      type: 'ProcessPower',
      measurement: 1500,
      target: 2000,
    },
    {
      type: 'Ventilation',
      measurement: 2500,
      target: 2000,
    },
    {
      type: 'Cooling',
      measurement: 2000,
      target: 2000,
    },
    {
      type: 'Heating',
      measurement: 2000,
      target: 2000,
    },
  ]

  return {
    data: isGraphInvalid
      ? defaultPlotData
      : isDistribution
        ? secondaryGraphData
        : topLargest(secondaryGraphData, 5),
    isGraphInvalid,
  }
}

export interface IEnergyUseProps {
  loading: boolean
  portfolio?: PortfolioTypes
  isDashboard?: boolean
}

const EnergyUse = ({
  portfolio,
  loading,
  isDashboard = false,
}: IEnergyUseProps) => {
  const { t } = useTranslation('building')
  const { data, isGraphInvalid } = buildPlotData(false, portfolio)

  const [is4k, is2k, is1080, isLG, isTab] = useMediaQuery(mediaQueries)

  let headingSize = 20,
    graphTicksFont = 10,
    graphLabelFont = 14,
    graphBottom = 70,
    graphLeft = 70

  // const responsiveValues = () => {
  if (is4k) {
    headingSize = 50
    graphTicksFont = 35
    graphLabelFont = 40
    graphBottom = 100
    graphLeft = 130
  } else if (is2k) {
    headingSize = 28
    graphTicksFont = 16
    graphLabelFont = 20
  } else if (is1080) {
    headingSize = 20
    graphTicksFont = 10
    graphLabelFont = 14
  } else if (isLG) {
    headingSize = 20
    graphTicksFont = 10
    graphLabelFont = 14
  } else if (isTab) {
    headingSize = 20
    graphTicksFont = 10
    graphLabelFont = 14
  } else {
    headingSize = 20
    graphTicksFont = 10
    graphLabelFont = 14
  }
  // }

  if (loading || data === undefined) {
    return (
      <Box
        py={6}
        px={'0.25rem'}
        background={'gray.300'}
        textColor={'purple.600'}
        h="100%"
      >
        <Box background={'white'} p={4} h="100%">
          <Heading as="h3" size="md">
            Energy Use
          </Heading>
          <Box height="300px" width="100px">
            loading...
          </Box>
        </Box>
      </Box>
    )
  }

  const renderTooltip: RenderTooltipFunction = ({
    tooltipData,
    colorScale,
  }) => {
    return (
      <Box>
        <Text
          style={{
            color: colorScale(tooltipData.nearestDatum.key),
          }}
          mb={1}
        >
          {accessors.label(tooltipData.nearestDatum.datum)}
        </Text>
        <Text mb={1}>
          {accessors.xAccessor(tooltipData.nearestDatum.datum)}
        </Text>
      </Box>
    )
  }

  const colorBar = (d: SecondaryGraphData): string => {
    const upIsBetter = indicatorTypeData[d.type!]?.upIsBetter,
      { measurement, target } = d
    if (!target) {
      return '#372e70'
    }

    const ratio = measurement / target

    if (ratio < 0.95) {
      return upIsBetter ? '#f2253e' : '#00AB08'
    } else if (ratio < 1.05) {
      return upIsBetter ? '#00AB08' : 'yellow'
    } else {
      return upIsBetter ? '#00AB08' : '#f2253e'
    }
  }

  return (
    <Box
      py={isDashboard ? 0 : { base: 2, md: 6 }}
      px={isDashboard ? 0 : { base: '0.5rem', md: 6 }}
      background={'gray.300'}
      textColor={'purple.600'}
      h="100%"
      // w={'100%'}
    >
      <GraphReplacement>
        <Box
          background="white"
          p={isDashboard ? { '2xl': is4k ? 12 : 4, base: 4 } : 4}
          h="100%"
        >
          <Center justifyContent={'space-between'}>
            <Heading
              as="h3"
              size="md"
              fontWeight={isDashboard ? '500' : 'revert'}
              fontSize={isDashboard ? headingSize : 20}
            >
              {t('energyUse')}
            </Heading>
            {isDashboard && (
              <Legend
                legendChannels={[
                  {
                    name: 'No Target',
                    id: 'No Target',
                    unit: unknownUnit.unknown,
                  },
                  { name: 'Target', id: 'Target', unit: unknownUnit.unknown },
                  {
                    name: 'Above Target',
                    id: 'Above Target',
                    unit: unknownUnit.unknown,
                  },
                  {
                    name: 'Close to Target',
                    id: 'Close to Target',
                    unit: unknownUnit.unknown,
                  },
                  {
                    name: 'Below Target',
                    id: 'Below Target',
                    unit: unknownUnit.unknown,
                  },
                ]}
                graphColors={[
                  '#372e70',
                  '#33C4FF',
                  '#f2253e',
                  'yellow',
                  '#00AB08',
                ]}
                size={10}
              />
            )}
          </Center>
          <Box
            height={isDashboard ? '95%' : '300px'}
            width="100%"
            position={'relative'}
          >
            <Center
              {...overlayStyles}
              display={isGraphInvalid ? 'flex' : 'none'}
            >
              <Heading>{t('noData')}</Heading>
            </Center>
            <ParentSize>
              {({ width, height }) => (
                <XYChart
                  height={height}
                  width={width}
                  xScale={{ type: 'band', paddingInner: 0.2 }}
                  yScale={{ type: 'linear' }}
                  margin={{
                    top: 40,
                    right: 50,
                    left: isDashboard ? graphLeft : 70,
                    bottom: isDashboard ? graphBottom : 70,
                  }}
                >
                  <AnimatedGrid
                    rows={false}
                    columns={false}
                    animationTrajectory={'center'}
                  />
                  <AnimatedBarGroup>
                    <AnimatedBarSeries
                      dataKey="Energy Use"
                      // data={topLargest(use, 6)}
                      data={data.sort((a, b) => b.measurement - a.measurement)}
                      yAccessor={(d) => d.measurement!}
                      xAccessor={(d) => indicatorTypeData[d.type!]?.miniName}
                      colorAccessor={(d) => colorBar(d)}
                    />
                    {isDashboard && (
                      <AnimatedBarSeries
                        dataKey="Energy target"
                        // data={topLargest(use, 6)}
                        data={data.sort(
                          (a, b) => b.measurement - a.measurement,
                        )}
                        yAccessor={(d) => d.target}
                        xAccessor={(d) => indicatorTypeData[d.type!]?.miniName}
                        colorAccessor={() => '#33C4FF'}
                      />
                    )}
                  </AnimatedBarGroup>
                  <AnimatedAxis
                    key={'use-axis'}
                    orientation={'bottom'}
                    label={'End Use'}
                    animationTrajectory="outside"
                    labelProps={{
                      fontFamily: 'bouldregular',
                      textAnchor: 'middle',
                      fontSize: graphLabelFont,
                    }}
                    labelOffset={15}
                    tickLabelProps={(props) => {
                      return {
                        ...props,
                        fontFamily: 'bouldregular',
                        fontSize: graphTicksFont,
                        textAnchor: 'middle',
                      }
                    }}
                  />
                  <AnimatedAxis
                    key={'consumption-axis'}
                    orientation="left"
                    animationTrajectory="max"
                    label={'Consumption/kWh'}
                    labelProps={{
                      fontFamily: 'bouldregular',
                      textAnchor: 'middle',
                      fontSize: graphLabelFont,
                    }}
                    labelOffset={isDashboard && is4k ? 75 : 45}
                    tickLabelProps={(props) => {
                      return {
                        ...props,
                        fontSize: graphTicksFont,
                        fontFamily: 'bouldregular',
                      }
                    }}
                  />
                  {!isDashboard && (
                    <Tooltip<SecondaryGraphData>
                      snapTooltipToDatumX
                      snapTooltipToDatumY
                      showVerticalCrosshair
                      showSeriesGlyphs
                      renderTooltip={renderTooltip}
                    />
                  )}
                </XYChart>
              )}
            </ParentSize>
          </Box>
        </Box>
      </GraphReplacement>
    </Box>
  )
}

export default EnergyUse
