import { createIcon } from '@chakra-ui/react'

const Lab = createIcon({
  displayName: 'Lab',
  viewBox: '0 0 162 254',
  path: (
    <g id="g18" transform="translate(-77.453,-51.113)">
      <g id="g8">
        <path
          d="m 185.519,63.292 c 4.15,0 7.513,3.364 7.513,7.513 0,4.149 -3.363,7.512 -7.513,7.512 h -2.925 v 80.938 c 26.135,9.777 44.778,34.904 44.778,64.452 0,38.036 -30.833,68.87 -68.87,68.87 -38.037,0 -68.87,-30.833 -68.87,-68.87 0,-29.547 18.642,-54.674 44.778,-64.451 V 78.318 h -2.925 c -4.149,0 -7.513,-3.363 -7.513,-7.512 0,-4.149 3.364,-7.513 7.513,-7.513 h 54.034 m 0,-12.18 h -54.034 c -10.858,0 -19.692,8.834 -19.692,19.692 0,7.515 4.232,14.061 10.438,17.378 v 63.11 c -11.917,5.956 -22.164,14.738 -29.951,25.737 -9.7,13.701 -14.827,29.841 -14.827,46.677 0,44.69 36.359,81.049 81.05,81.049 44.69,0 81.049,-36.358 81.049,-81.049 0,-16.836 -5.127,-32.976 -14.827,-46.677 -7.787,-10.999 -18.034,-19.781 -29.951,-25.737 V 88.184 c 6.206,-3.318 10.438,-9.863 10.438,-17.378 -10e-4,-10.859 -8.835,-19.693 -19.693,-19.693 z"
          fill="currentColor"
        />
      </g>
      <g id="g16">
        <path
          d="m 176.332,208.48 h -35.659 c -2.061,0 -3.732,1.671 -3.732,3.732 0,2.061 1.67,3.732 3.732,3.732 h 35.659 c 2.061,0 3.732,-1.671 3.732,-3.732 -0.001,-2.062 -1.671,-3.732 -3.732,-3.732 z"
          fill="currentColor"
        />
        <path
          d="m 176.332,223.406 h -35.659 c -2.061,0 -3.732,1.671 -3.732,3.732 0,2.061 1.67,3.732 3.732,3.732 h 35.659 c 2.061,0 3.732,-1.671 3.732,-3.732 -0.001,-2.061 -1.671,-3.732 -3.732,-3.732 z"
          fill="currentColor"
        />
        <path
          d="m 176.332,238.333 h -35.659 c -2.061,0 -3.732,1.671 -3.732,3.732 0,2.061 1.67,3.732 3.732,3.732 h 35.659 c 2.061,0 3.732,-1.671 3.732,-3.732 -0.001,-2.062 -1.671,-3.732 -3.732,-3.732 z"
          fill="currentColor"
        />
      </g>
    </g>
  ),
})

export default Lab
