export const gasRegions = [
  {
    name: 'Campbeltown',
    code: 'Campbeltown',
  },
  {
    name: 'East Anglia',
    code: 'EA',
  },
  {
    name: 'East Midlands',
    code: 'EM',
  },
  {
    name: 'North East',
    code: 'NE',
  },
  {
    name: 'North England',
    code: 'NO',
  },
  {
    name: 'North Thames',
    code: 'NT',
  },
  {
    name: 'North Wales',
    code: 'WN',
  },
  {
    name: 'North West',
    code: 'NW',
  },
  {
    name: 'Oban',
    code: 'Oban',
  },
  {
    name: 'Scotland',
    code: 'SC',
  },
  {
    name: 'Stornoway',
    code: 'Stornoway',
  },
  {
    name: 'South East',
    code: 'SE',
  },
  {
    name: 'South England',
    code: 'SO',
  },
  {
    name: 'South Wales',
    code: 'WS',
  },
  {
    name: 'South West',
    code: 'SW',
  },
  {
    name: 'Stranraer',
    code: 'Stranraer',
  },
  {
    name: 'Thurso',
    code: 'Thurso',
  },
  {
    name: 'West Midlands',
    code: 'WM',
  },
  {
    name: 'Wick',
    code: 'Wick',
  },
]
