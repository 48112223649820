import { Box, Center, Heading, useMediaQuery } from '@chakra-ui/react'
//import { GradientPinkBlue } from '@visx/gradient'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import { scaleOrdinal } from '@visx/scale'
import { Group } from '@visx/group'
import Pie, { PieArcDatum, ProvidedProps } from '@visx/shape/lib/shapes/Pie'
import { animated, to, useTransition } from '@react-spring/web'
import { indicatorTypeData } from '../../../utils/indicators/indicators'
import { useTranslation } from 'react-i18next'
import { overlayStyles } from '../energyUse'
import { buildPlotData } from '../energyUse/index'
import { PortfolioTypes } from '../../../graphql/generated/graphql'
import { mediaQueries } from '../../../utils/mediaQueries/mediaQueries'
import GraphReplacement from '../../graphReplacement/graphReplacement'

export interface IEnergyUseProps {
  loading: boolean
  portfolio?: PortfolioTypes
  isDashboard?: boolean
}

const EnergyDist = ({ portfolio, loading, isDashboard }: IEnergyUseProps) => {
  const { t } = useTranslation('building')

  const { data, isGraphInvalid } = buildPlotData(true, portfolio)
  const [is4k, is2k, is1080, isLG, isTab] = useMediaQuery(mediaQueries)

  let headingSize = 20,
    graphLabelFont = 14

  // const responsiveValues = () => {
  if (is4k) {
    headingSize = 50
    graphLabelFont = 40
  } else if (is2k) {
    headingSize = 28
    graphLabelFont = 20
  } else if (is1080) {
    headingSize = 20
    graphLabelFont = 14
  } else if (isLG) {
    headingSize = 20
    graphLabelFont = 14
  } else if (isTab) {
    headingSize = 20
    graphLabelFont = 14
  } else {
    headingSize = 20
    graphLabelFont = 14
  }

  const getEnergyColors = scaleOrdinal({
    domain: ['1', '2', '3', '4'],
    range: [
      '#EEAF91',
      '#A6EE91',
      '#AAAAAA',
      '#91ADEE',
      '#FFC300',
      '#FF5733',
      '#33FF57',
      '#33C4FF',
      '#FF33E8',
      '#9A33FF',
    ],
  })

  if (loading || data === undefined) {
    return (
      <Box
        py={6}
        pl={6}
        pr={6}
        background={'gray.300'}
        textColor={'purple.600'}
        h="100%"
      >
        <Box background={'white'} p={4} h="100%">
          <Heading as="h3" size="md">
            Energy Distribution
          </Heading>
          <Box height="300px" width="100px">
            loading...
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      py={isDashboard ? 0 : { base: 2, md: 6 }}
      px={isDashboard ? 0 : { base: '0.5rem', md: 6 }}
      background={'gray.300'}
      textColor={'purple.600'}
      h="100%"
    >
      <GraphReplacement>
        <Box
          background={'white'}
          p={isDashboard ? { '2xl': is4k ? 12 : 4, base: 4 } : 4}
          h="100%"
        >
          <Heading
            as="h3"
            size="md"
            fontWeight={isDashboard ? '500' : 'revert'}
            fontSize={isDashboard ? headingSize : 20}
          >
            {t('energyDist')}
          </Heading>
          <Box
            height={isDashboard ? '95%' : '300px'}
            width="100%"
            position={'relative'}
          >
            <Center
              {...overlayStyles}
              display={isGraphInvalid ? 'flex' : 'none'}
            >
              <Heading>{t('noData')}</Heading>
            </Center>
            <ParentSize>
              {({ width, height }) => {
                const margin = { top: 10, bottom: 10, left: 10, right: 10 }
                const innerWidth = width - margin.top - margin.bottom
                const innerHeight = height - margin.left - margin.right
                const centerX = innerWidth / 2
                const centerY = innerHeight / 2
                const radius = Math.min(innerWidth, innerHeight) / 2
                const thickness = 50

                return (
                  <svg width={width} height={height}>
                    {/* <GradientPinkBlue id="visx-pie-gradient" /> */}
                    <rect
                      rx={14}
                      width={width}
                      height={height}
                      fill={'white'}
                    />
                    <Group
                      top={centerY + margin.top}
                      left={centerX + margin.left}
                    >
                      <Pie
                        data={data}
                        pieValue={(d) => Math.abs(d.measurement!)}
                        outerRadius={radius}
                        innerRadius={isDashboard ? 0 : radius - thickness}
                        padAngle={0.005}
                        cornerRadius={3}
                      >
                        {(pie) => (
                          <AnimatedPie
                            {...pie}
                            isDashboard={isDashboard}
                            graphLabelFont={graphLabelFont}
                            getKey={(arc) =>
                              indicatorTypeData[arc.data.type!]?.miniName
                            }
                            getColor={(arc) =>
                              getEnergyColors(
                                indicatorTypeData[arc.data.type!]?.miniName,
                              )
                            }
                            onClickDatum={(_d) => {}}
                          />
                        )}
                      </Pie>
                    </Group>
                  </svg>
                )
              }}
            </ParentSize>
          </Box>
        </Box>
      </GraphReplacement>
    </Box>
  )
}

type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number }

const fromLeaveTransition = ({ endAngle }: PieArcDatum<any>) => ({
  // NOT CLEAR IN THE PLUGIN - HAVE TO USE ANY
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
})

const enterUpdateTransition = ({ startAngle, endAngle }: PieArcDatum<any>) => ({
  // NOT CLEAR IN THE PLUGIN - HAVE TO USE ANY
  startAngle,
  endAngle,
  opacity: 1,
})

type AnimatedPieProps<T> = ProvidedProps<T> & {
  animate?: boolean
  getKey: (d: PieArcDatum<T>) => string
  getColor: (d: PieArcDatum<T>) => string
  onClickDatum: (d: PieArcDatum<T>) => void
  delay?: number
  isDashboard?: boolean
  graphLabelFont?: number
}

function AnimatedPie<T>({
  animate,
  arcs,
  path,
  getKey,
  getColor,
  onClickDatum,
  isDashboard,
  graphLabelFont,
}: AnimatedPieProps<T>) {
  const transitions = useTransition<PieArcDatum<T>, AnimatedStyles>(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey,
  })
  return transitions((props, arc, { key }) => {
    const [centroidX, centroidY] = path.centroid(arc)
    const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1

    return (
      <g key={key}>
        <animated.path
          d={to([props.startAngle, props.endAngle], (startAngle, endAngle) =>
            path({
              ...arc,
              startAngle,
              endAngle,
            }),
          )}
          fill={getColor(arc)}
          onClick={() => onClickDatum(arc)}
          onTouchStart={() => onClickDatum(arc)}
        />
        {hasSpaceForLabel && (
          <animated.g style={{ opacity: props.opacity }}>
            <text
              fill="black"
              x={centroidX}
              y={centroidY}
              dy=".33em"
              fontSize={isDashboard ? graphLabelFont : 9}
              textAnchor="middle"
              pointerEvents="none"
            >
              {getKey(arc)}
            </text>
          </animated.g>
        )}
      </g>
    )
  })
}

export default EnergyDist
