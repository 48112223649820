import {
  Box,
  Image,
  Text,
  useBreakpointValue,
  useMediaQuery,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import BuildingTotal from '../../assets/SimpleDash/primary/001-TotalBuilding.svg'
import WaterTotal from '../../assets/SimpleDash/primary/002-TotalWater.svg'
import CarbonTotal from '../../assets/SimpleDash/primary/003-TotalCarbon.svg'
import {
  isPeriodEnum,
  periodEnum,
} from '../../elements/building/buildingConfiguration'
import Comparison from '../../elements/comparison/comparison'
import { indicatorTypeData } from '../../utils/indicators/indicators'
import { mediaQueries } from '../../utils/mediaQueries/mediaQueries'
import displayScale from '../../utils/unitHelpers/displayScale'
import { colorCard } from '../../utils/valueInference/colorCard'

type primaryCardProps = {
  measurement: number
  type: string
  comparison: string
  target: number
  period: string
}

type cardObjectProps = {
  text: string
  src: string
  unit: string
  [key: string]: string
}
interface CardType {
  WholeBuilding: cardObjectProps
  WaterUsage: cardObjectProps
  CarbonDioxide: cardObjectProps
  [key: string]: cardObjectProps
}

const cardType: CardType = {
  WholeBuilding: { text: 'energy', src: BuildingTotal, unit: 'kWh' },
  WaterUsage: {
    text: 'water',
    src: WaterTotal,
    unit: 'm3',
  },
  CarbonDioxide: {
    text: 'carbon emissions',
    src: CarbonTotal,
    unit: 'tCO₂e',
  },
}

const PrimaryCard = ({
  measurement,
  type,
  comparison,
  target,
  period,
}: primaryCardProps) => {
  const { t } = useTranslation(['portfolio'])
  const bgColor = colorCard({ measurement, target })
  const [is4k, is2k] = useMediaQuery(mediaQueries)

  let periodText: string = 'lastMonth'
  if (isPeriodEnum(period)) {
    if (period === periodEnum.hourly) {
      periodText = 'lastHour'
    } else if (period === periodEnum.daily) {
      periodText = 'lastDay'
    } else if (period === periodEnum.weekly) {
      periodText = 'lastWeek'
    } else if (period === periodEnum.monthly) {
      periodText = 'lastMonth'
    } else if (period === periodEnum.yearly) {
      periodText = 'lastYear'
    }
  }

  const comparisonFontSize = useBreakpointValue({
    base: 14,
    md: 16,
    '2xl': is4k ? 45 : is2k ? 30 : 20,
  })

  const scaledMeasurement = displayScale(
    measurement,
    1,
    indicatorTypeData[type!].unit,
  ).value

  const formattedMeasurement: number = scaledMeasurement
    ?.toString()
    .includes('.')
    ? +scaledMeasurement.toFixed(1)
    : scaledMeasurement

  return (
    <Box height={'100%'}>
      <Box
        bg={bgColor}
        position={'relative'}
        p={'5%'}
        h={{
          base: 175,
          md: 350,
          lg: 195,
          xl: 250,
          '2xl': is4k ? '800px' : is2k ? '435px' : 315,
        }}
      >
        <Box color={'white'} maxWidth={'75%'}>
          <Text
            noOfLines={1}
            textTransform={'uppercase'}
            fontSize={{
              base: 13,
              md: 25,
              lg: 15,
              '2xl': is4k ? '55px' : is2k ? '28px' : 22,
            }}
          >
            Total {cardType[type].text} Used
          </Text>
          <Box display={'flex'} alignItems={'baseline'} lineHeight={1}>
            <Text
              noOfLines={1}
              fontSize={{
                base: 40,
                md: 85,
                lg: 50,
                '2xl': is4k ? '210px' : is2k ? '100px' : '75px',
              }}
            >
              <b>{formattedMeasurement}</b>
            </Text>
            &nbsp;
            <Text
              fontSize={{
                base: 28,
                md: 65,
                lg: 40,
                '2xl': is4k ? '150px' : is2k ? '65px' : 60,
              }}
            >
              <b>
                {' '}
                {
                  displayScale(
                    measurement,
                    1,
                    type === 'WaterUsage'
                      ? 'm3'
                      : indicatorTypeData[type!].unit,
                  ).unit
                }
              </b>
            </Text>
          </Box>
          <Text
            fontSize={{
              base: 13,
              md: 28,
              lg: 18,
              '2xl': is4k ? '75px' : is2k ? '30px' : 24,
            }}
            align={'center'}
          >
            Over the {t(periodText)}
          </Text>
        </Box>
        <Box
          position={'absolute'}
          bottom={{
            base: '-14px',
            md: '-22px',
            lg: '-14px',
            '2xl': is4k ? '-40px' : is2k ? '-26px' : '-25px',
          }}
          right={{ '2xl': is4k ? '2px' : is2k ? '-2px' : '-1px' }}
        >
          <Image
            src={cardType[type].src}
            alt={'Total-buidling.svg'}
            w={{
              '2xl': is4k ? '1265px' : is2k ? '840px' : 'auto',
            }}
          />
        </Box>
      </Box>
      <Comparison
        p={'4% 0 2%'}
        fontSize={comparisonFontSize}
        color={'grey.800'}
        text={comparison}
        centerAlign={true}
      />
    </Box>
  )
}

export default PrimaryCard
