export const incomingUnitMap = [
  {
    name: 'Cubic Metres (Gas)',
    code: 'gasMetersCubed',
  },
  {
    name: 'Cubic Feet (Gas)',
    code: 'gasFeetCubed',
  },
  {
    name: 'kWh (Electricity)',
    code: 'electricityKwh',
  },
  {
    name: 'BTUs (Electricity)',
    code: 'electricityBtu',
  },
  {
    name: 'KiloJoules (Electricity)',
    code: 'electricityKj',
  },
  {
    name: 'kWh (Gas & Electricity)',
    code: 'combiKwh',
  },
  {
    name: 'BTUs (Gas & Electricity)',
    code: 'combiBtu',
  },
  {
    name: 'KiloJoules (Gas & Electricity)',
    code: 'combiKj',
  },
  {
    name: 'Metric Tonnes',
    code: 'combiCarbonTonnesMetric',
  },
  {
    name: 'Imperial Tonnes',
    code: 'combiCarbonTonnesImperial',
  },
  {
    name: 'Cubic Metres (Water)',
    code: 'waterMetersCubed',
  },
  {
    name: 'Cubic Feet (Water)',
    code: 'waterFeetCubed',
  },
  {
    name: 'Imperial Gallons (Water)',
    code: 'waterGallonsImperial',
  },
  {
    name: 'US Gallons (Water)',
    code: 'waterGallonsUS',
  },
  {
    name: 'Litres (Water)',
    code: 'waterLitres',
  },
  {
    name: 'ppm (Air Quality)',
    code: 'ppm',
  },
  {
    name: 'Celsius (Temperature)',
    code: 'celsius',
  },
  {
    name: 'Fahrenheit (Temperature)',
    code: 'fahrenheit',
  },
  {
    name: 'Kelvin (Temperature)',
    code: 'kelvin',
  },
  {
    name: 'Boolean',
    code: 'boolean',
  },
  {
    name: 'Unknown',
    code: 'unknown',
  },
] as const

export const combiIncomingUnitMap = [
  {
    name: 'Power (Gas & Electricity)',
    code: 'combiKwh',
  },
  {
    name: 'Carbon (Gas & Electricity)',
    code: 'combiCarbonTonnesMetric',
  },
  {
    name: 'Cubic Metres (Gas)',
    code: 'gasMetersCubed',
  },
  {
    name: 'kWh (Electricity)',
    code: 'electricityKwh',
  },
  {
    name: 'Cubic Metres (Water)',
    code: 'waterMetersCubed',
  },
  {
    name: 'ppm (Air Quality)',
    code: 'ppm',
  },
  {
    name: 'Celsius (Temperature)',
    code: 'celsius',
  },
  {
    name: 'Boolean',
    code: 'boolean',
  },
  {
    name: 'Unknown',
    code: 'unknown',
  },
] as const

export const categoryMap = [
  { name: 'Gas', code: 'gas' },
  { name: 'Water', code: 'water' },
  { name: 'Power', code: 'combiPower' },
  { name: 'Carbon', code: 'combiCarbon' },
  { name: 'Electricity', code: 'electricity' },
  { name: 'Temperature', code: 'temperature' },
  { name: 'Air Quality', code: 'airQuality' },
  { name: 'Boolean', code: 'boolean' },
  { name: 'Unknown', code: 'unknown' },
] as const
