import { gql } from '@apollo/client'

export const UPDATE_METER_RELATIONSHIP = gql`
  mutation ChannelRelationshipTreeUpdate($data: [ChannelUpdateInput!]!) {
    channelRelationshipTreeUpdate(data: $data) {
      id
      createdAt
      updatedAt
      name
      uploadLabel
      reference
      period
      incomingUnit
      measurementType
      buildingId
      parentId
      channelType
      channelIssues {
        lastSeen
        isImportant
        notTransmitting
        notCumulative
        includesZeroes
      }
      children {
        id
        createdAt
        updatedAt
        name
        uploadLabel
        reference
        period
        incomingUnit
        measurementType
        buildingId
        parentId
        channelType
        channelIssues {
          lastSeen
          isImportant
          notTransmitting
          notCumulative
          includesZeroes
        }
        children {
          id
          createdAt
          updatedAt
          name
          uploadLabel
          reference
          period
          measurementType
          buildingId
          parentId
          channelType
          channelIssues {
            lastSeen
            isImportant
            notTransmitting
            notCumulative
            includesZeroes
          }
        }
      }
    }
  }
`

export const CREATE_COMBI_CHANNEL = gql`
  mutation CompositeChannelCreate($data: CompositeChannelCreateInput!) {
    compositeChannelCreate(data: $data) {
      id
      createdAt
      updatedAt
      name
      buildingId
      channelList
      period
      shadowId
      isDashboardDefault
      incomingUnit
    }
  }
`

export const EDIT_COMBI_CHANNEL = gql`
  mutation CompositeChannelUpdate($data: CompositeChannelUpdateInput!) {
    compositeChannelUpdate(data: $data) {
      id
      createdAt
      updatedAt
      name
      buildingId
      channelList
      period
      shadowId
      isDashboardDefault
      incomingUnit
    }
  }
`

export const CREATE_VIRTUAL_CHANNEL = gql`
  mutation ChannelCreate($data: ChannelCreateInput!) {
    channelCreate(data: $data) {
      id
      createdAt
      updatedAt
      name
      uploadLabel
      reference
      period
      incomingUnit
      measurementType
      buildingId
      parentId
      channelType
    }
  }
`

export const UPDATE_COMPOSITE_RELATIONSHIP = gql`
  mutation CompositeChannelRelationshipUpdate($data: [MixedChannelInput!]!) {
    compositeChannelRelationshipUpdate(data: $data) {
      name
      id
      composite
      isDashboardDefault
      children {
        name
        id
        composite
        isDashboardDefault
        children {
          name
          id
          composite
          isDashboardDefault
          children {
            name
            id
            composite
            isDashboardDefault
            children {
              name
              id
              composite
              isDashboardDefault
              children {
                name
                id
                composite
                isDashboardDefault
              }
            }
          }
        }
      }
    }
  }
`
export const EDIT_USERS = gql`
  mutation UserUpdate($data: UserChangeInput!) {
    userUpdate(data: $data) {
      id
      createdAt
      updatedAt
      companyId
      email
      firstName
      lastName
      slug
      picture
      role
      customPermissions
    }
  }
`
export const EDIT_COMPANIES = gql`
  mutation CompanyUpdate($data: CompanyUpdateInput!) {
    companyUpdate(data: $data) {
      id
      createdAt
      updatedAt
      name
      slug
      portfolioImage
      logo
      primaryColor
      secondaryColor
      fontColor
      userIds
    }
  }
`
export const EDIT_CHANNELS = gql`
  mutation ChannelUpdate($data: ChannelUpdateInput!) {
    channelUpdate(data: $data) {
      id
      createdAt
      updatedAt
      name
      uploadLabel
      reference
      period
      incomingUnit
      measurementType
      buildingId
      parentId
      channelType
      channelIssues {
        lastSeen
        isImportant
        notTransmitting
        notCumulative
        includesZeroes
      }
    }
  }
`

export const DELETE_USER = gql`
  mutation deleteUser($data: UserDeleteInput!) {
    userDelete(data: $data) {
      message
    }
  }
`

export const DELETE_COMPANY = gql`
  mutation deleteCompany($data: CompanyDeleteInput!) {
    companyDelete(data: $data) {
      message
    }
  }
`

export const DELETE_CHANNEL = gql`
  mutation ChannelDelete($data: ChannelUniqueIdInput!) {
    channelDelete(data: $data) {
      message
    }
  }
`

export const CREATE_TARGET = gql`
  mutation TargetCreate($data: TargetCreateInput!) {
    targetCreate(data: $data) {
      id
      createdAt
      updatedAt
      name
      period
      benchmark
      channelId
      targetValue
      triggerCondition
      nextExpectedTime
      lastComparisonTime
      lastComparisonValue
    }
  }
`

export const DELETE_TARGET = gql`
  mutation TargetDelete($data: TargetDeleteInput!) {
    targetDelete(data: $data) {
      message
    }
  }
`

export const UPDATE_TARGET = gql`
  mutation TargetUpdate($data: TargetUpdateInput!) {
    targetUpdate(data: $data) {
      id
      createdAt
      updatedAt
      name
      period
      benchmark
      channelId
      targetValue
      triggerCondition
      nextExpectedTime
      lastComparisonTime
      lastComparisonValue
    }
  }
`

export const CREATE_ALERT = gql`
  mutation AlertCreate($where: AlertCreateInput!) {
    alertCreate(where: $where) {
      id
      createdAt
      updatedAt
      id
      destination
      messageType
      severity
    }
  }
`

export const UPDATE_ALERT = gql`
  mutation AlertUpdate($where: AlertUpdateInput!) {
    alertUpdate(where: $where) {
      id
      createdAt
      updatedAt
      id
      destination
      messageType
      severity
    }
  }
`

export const DELETE_ALERT = gql`
  mutation AlertDelete($where: AlertDeleteInput!) {
    alertDelete(where: $where) {
      message
    }
  }
`

export const SEND_RESET_PASSWORD_EMAIL = gql`
  mutation SendResetPasswordEmail($resetType: String!, $email: String!) {
    sendResetPasswordEmail(resetType: $resetType, email: $email) {
      message
    }
  }
`

export const SET_PASSWORD = gql`
  mutation SetPassword($password: String!, $token: String!) {
    setPassword(password: $password, token: $token) {
      message
    }
  }
`

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      message
    }
  }
`

export const CREATE_BUILDING = gql`
  mutation BuildingCreate($data: BuildingCreateInput!) {
    buildingCreate(data: $data) {
      id
      createdAt
      updatedAt
      name
      defaultName
      projectRef
      slug
      picture
      thumb
      epcRating
      epcUrl
      floorArea
      hasFileServer
      hasDataServer
      dashboardPeriod
      dashboardStyle
      lastSeen
      buildingTransmitting
      mqttInfo {
        mqttUsername
        mqttPassword
      }
      uploadConfig {
        ftpConfig {
          id
          buildingId
          connectionType
          parser
        }
        mqttConfig {
          id
          buildingId
          connectionType
          host
          password
          port
          protocol
          topic
          useDefaultServer
          username
        }
      }
    }
  }
`

export const UPDATE_BUILDING = gql`
  mutation BuildingUpdate($data: BuildingUpdateInput!) {
    buildingUpdate(data: $data) {
      id
      createdAt
      updatedAt
      name
      defaultName
      projectRef
      slug
      picture
      thumb
      epcRating
      epcUrl
      floorArea
      hasFileServer
      hasDataServer
      dashboardPeriod
      dashboardStyle
      lastSeen
      buildingTransmitting
      breeamScore
      mqttInfo {
        mqttUsername
        mqttPassword
      }
      uploadConfig {
        ftpConfig {
          id
          buildingId
          connectionType
          parser
        }
        mqttConfig {
          id
          buildingId
          connectionType
          host
          password
          port
          protocol
          topic
          useDefaultServer
          username
        }
      }
    }
  }
`
export const DELETE_BUILDING = gql`
  mutation BuildingDelete($data: BuildingDeleteInput!) {
    buildingDelete(data: $data) {
      message
    }
  }
`

export const UPDATE_DASHBOARD = gql`
  mutation DashboardUpdate($data: DashboardUpdateInput!) {
    dashboardUpdate(data: $data) {
      id
      period
      tryMessage
      infoMessage
    }
  }
`

export const SIGN_IN = gql`
  mutation signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      token
      message
      user {
        id
        firstName
        lastName
        slug
        picture
        isStaff
        isAdmin
        companyId
      }
    }
  }
`

export const SIGN_OUT = gql`
  mutation signOut {
    signOut {
      message
    }
  }
`
export const CREATE_COMPANIES = gql`
  mutation createCompany($data: CompanyCreateInput!) {
    companyCreate(data: $data) {
      id
      createdAt
      updatedAt
      name
      slug
      portfolioImage
      logo
      userIds
    }
  }
`

export const CREATE_USERS = gql`
  mutation UserCreate($data: UserCreateInput!) {
    userCreate(data: $data) {
      id
      createdAt
      updatedAt
      companyId
      email
      firstName
      lastName
      slug
      picture
      role
      customPermissions
    }
  }
`

export const ADD_COMPANY_RELATIONSHIP = gql`
  mutation AddCompanyRelationship($data: AddCompanyRelationshipInput!) {
    addCompanyRelationship(data: $data) {
      buildingId
      companyId
      companyName
      buildingName
      companyReference
      companyReferenceName
      relationshipType
    }
  }
`

export const REMOVE_COMPANY_RELATIONSHIP = gql`
  mutation RemoveCompanyRelationship($data: RemoveCompanyRelationshipInput!) {
    removeCompanyRelationship(data: $data) {
      message
    }
  }
`

export const UPDATE_COMPANY_RELATIONSHIP = gql`
  mutation UpdateCompanyRelationship($data: UpdateCompanyRelationshipInput!) {
    updateCompanyRelationship(data: $data) {
      companyId
      buildingId
      companyName
      buildingName
      companyReference
      companyReferenceName
      relationshipType
    }
  }
`

export const RESET_INSPECTOR_FLAG = gql`
  mutation ResetFlag($where: ResetFlagInput!) {
    resetFlag(where: $where) {
      message
    }
  }
`

export const UPDATE_INSPECTOR_CHANNEL = gql`
  mutation InspectorChannelUpdateOne($where: InspectorChannelUpdateOneInput!) {
    inspectorChannelUpdateOne(where: $where) {
      channelId
      lastSeen
      isImportant
      notTransmitting
      notCumulative
      includesZeroes
    }
  }
`

export const UPDATE_TARGET_ALERT = gql`
  mutation TargetAlertUpdate($data: TargetAlertUpdate!) {
    targetAlertUpdate(data: $data) {
      name
      benchmark
      channelId
      triggerCondition
      isDashboardDefault
    }
  }
`

export const UPDATE_TARGET_ALERT_MANY = gql`
  mutation TargetAlertUpdateMany($data: targetAlertUpdateMany!) {
    targetAlertUpdateMany(data: $data) {
      name
      benchmark
      channelId
      triggerCondition
      isDashboardDefault
      severity
      daily {
        targetId
        targetValue
        alertId
      }
      weekly {
        targetId
        targetValue
        alertId
      }
      monthly {
        targetId
        targetValue
        alertId
      }
      yearly {
        targetId
        targetValue
        alertId
      }
    }
  }
`

export const DELETE_TARGETS = gql`
  mutation TargetAlertDelete($where: TargetAlertDeleteInput!) {
    targetAlertDelete(where: $where) {
      message
    }
  }
`

export const CHECK_CHANNEL_DATA = gql`
  mutation CheckChannelData($data: CheckChannelDataInput!) {
    checkChannelData(data: $data) {
      message
    }
  }
`

export const CHECK_BUILDING_DATA = gql`
  mutation CheckBuildingData($data: CheckBuildingDataInput!) {
    checkBuildingData(data: $data) {
      message
    }
  }
`

export const CHECK_ALL_DATA = gql`
  mutation CheckAllData($data: CheckAllDataInput!) {
    checkAllData(data: $data) {
      message
    }
  }
`

export const CREATE_TARGET_ALERT = gql`
  mutation TargetAlertCreate($data: TargetAlertCreateInput!) {
    targetAlertCreate(data: $data) {
      name
      benchmark
      channelId
      triggerCondition
      isDashboardDefault
      severity
      daily {
        targetId
        targetValue
        alertId
      }
      weekly {
        targetId
        targetValue
        alertId
      }
      monthly {
        targetId
        targetValue
        alertId
      }
      yearly {
        targetId
        targetValue
        alertId
      }
    }
  }
`

export const UPDATE_CHANNEL_MANY = gql`
  mutation ChannelUpdateMany($data: ChannelUpdateManyInput!) {
    channelUpdateMany(data: $data) {
      id
      name
      measurementType
    }
  }
`

export const GAS_CALORIES_CREATE_BY_URL = gql`
  mutation GasCaloriesCreateByUrl($where: GasCaloriesCreateByUrlInput!) {
    gasCaloriesCreateByUrl(where: $where) {
      message
    }
  }
`

export const GAS_CALORIES_CREATE = gql`
  mutation GasCaloriesCreate($data: GasCaloriesCreateInput!) {
    gasCaloriesCreate(data: $data) {
      id
      country
      date
      value
    }
  }
`

export const GAS_CARBON_CREATE = gql`
  mutation GasCarbonCreate($data: GasCarbonCreateInput!) {
    gasCarbonCreate(data: $data) {
      id
      country
      date
      value
    }
  }
`

export const ELECTRICITY_CARBON_CREATE = gql`
  mutation ElectricityCarbonCreate($data: ElectricityCarbonCreateInput!) {
    electricityCarbonCreate(data: $data) {
      id
      country
      date
      value
    }
  }
`

export const GAS_CALORIES_DELETE = gql`
  mutation GasCaloriesDelete($data: GasCaloriesDeleteInput!) {
    gasCaloriesDelete(data: $data) {
      message
    }
  }
`

export const GAS_CARBON_DELETE = gql`
  mutation GasCarbonDelete($data: GasCarbonDeleteInput!) {
    gasCarbonDelete(data: $data) {
      message
    }
  }
`

export const ELECTRICITY_CARBON_DELETE = gql`
  mutation ElectricityCarbonDelete($data: ElectricityCarbonDeleteInput!) {
    electricityCarbonDelete(data: $data) {
      message
    }
  }
`
