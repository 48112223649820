import { Box } from '@chakra-ui/react'
import { AlertCircle, AlertTriangle, HelpCircle, Tool } from 'react-feather'

export const icon = (
  type: 'warn' | 'error' | 'spanner' | 'question',
  options?: {
    colorOnHover?: boolean
    size?: string
  },
): JSX.Element => {
  switch (type) {
    case 'warn': {
      if (options?.colorOnHover) {
        return (
          <Box sx={{ 'svg:hover': { fill: '#c29904' } }}>
            <AlertTriangle
              color="white"
              fill="#ECC94B"
              enableBackground="true"
            />
          </Box>
        )
      } else {
        return (
          <AlertTriangle color="white" fill="#ECC94B" enableBackground="true" />
        )
      }
    }
    case 'error': {
      if (options?.colorOnHover) {
        return (
          <Box sx={{ 'svg:hover': { fill: '#c70202' } }}>
            <AlertCircle color="white" fill="#ed0000" enableBackground="true" />
          </Box>
        )
      } else {
        return (
          <AlertCircle color="white" fill="#ed0000" enableBackground="true" />
        )
      }
    }
    case 'spanner': {
      if (options?.colorOnHover) {
        return (
          <Box sx={{ 'svg:hover': { fill: '#2469d6' } }}>
            <Tool color="white" fill="#4287f5" enableBackground="true" />
          </Box>
        )
      }
      return <Tool color="white" fill="#4287f5" enableBackground="true" />
    }
    case 'question': {
      if (options?.colorOnHover) {
        return (
          <Box sx={{ 'svg:hover': { fill: '#2469d6' } }}>
            <HelpCircle color="white" fill="#4287f5" enableBackground="true" />
          </Box>
        )
      }
      return <HelpCircle color="white" fill="#4287f5" enableBackground="true" />
    }
    default:
      console.error(`invalid symbol name chosen`)
      return <></>
  }
}
