import { PossibleDataTypesWithUndefined } from '../../components/listTable/listTable'
import {
  Channel,
  ChannelSearchManyQuery,
} from '../../graphql/generated/graphql'
import {
  CombiWithAddedProps,
  RelWithAddedProps,
} from '../../Pages/channels/channels'

// Function is mainly to change all the key name 'id' to 'channelId'. Should ask backend to analyse other ways to do
export function changeKeyRecursively<T extends RelWithAddedProps>(
  array: T | T[] | null,
  oldKey: keyof T,
  newKey: string,
  keysToKeep: string[],
): T | T[] | null {
  if (array === null) {
    return array
  }

  if (Array.isArray(array)) {
    return array.map((item) =>
      changeKeyRecursively(item, oldKey, newKey, keysToKeep),
    ) as T[]
  }

  // If not an array, must be an object
  const newObj: Record<string, unknown> = {}
  for (const untypedKey of Object.keys(array)) {
    const key = untypedKey as keyof T
    if (key !== oldKey && !keysToKeep.includes(untypedKey)) {
      continue
    }
    if (Array.isArray(array[key])) {
      const subArray = array[key] as T[]
      if (key === oldKey) {
        newObj[newKey as string] = changeKeyRecursively(
          subArray,
          oldKey,
          newKey,
          keysToKeep,
        ) as unknown as keyof T
      } else {
        newObj[key as string] = changeKeyRecursively(
          subArray,
          oldKey,
          newKey,
          keysToKeep,
        ) as unknown as keyof T
      }
    } else {
      if (key === oldKey && keysToKeep.includes(newKey)) {
        newObj[newKey as string] = array[key]
      } else if (keysToKeep.includes(key as string)) {
        newObj[key as string] = array[key]
      }
    }
  }
  return newObj as T
}

export const sortTree = (
  arr: (CombiWithAddedProps | RelWithAddedProps)[],
  from: string,
): (CombiWithAddedProps | RelWithAddedProps)[] => {
  let sortedArr: (CombiWithAddedProps | RelWithAddedProps)[] =
    structuredClone(arr)
  if (from === 'combi') {
    sortedArr.sort((a, b) => childrenSort(a, b))
  } else {
    sortedArr.sort((a, b) => ascendingSort(a, b))
  }

  let flaggedObject = null
  const newArray: (CombiWithAddedProps | RelWithAddedProps | null)[] =
    sortedArr.map((obj) => {
      obj.singleChild = false
      if (obj?.children?.length) {
        const updatedChildren = sortTree(
          obj.children as (CombiWithAddedProps | RelWithAddedProps)[],
          from,
        )
        obj['hasVirtual'] = obj.children.some(
          (channel) => channel?.channelType === 'virtual',
        )
        obj = { ...obj, children: updatedChildren }
      } else {
        obj['children'] = []
        obj['hasVirtual'] = false
      }
      if (from === 'rel') {
        if (obj?.children?.length) {
          const allFixedChannels = (obj.children as RelWithAddedProps[]).every(
            (channel) => channel.channelType === 'actual',
          )

          ;(obj.children as RelWithAddedProps[]).forEach((channel) => {
            channel.singleChild =
              obj.children?.length === 2 && !allFixedChannels
          })
        }
        if (obj.channelType === 'virtual') {
          flaggedObject = obj
          return null
        }
        if (obj.channelType === 'shadow') {
          return null
        }
      }
      return obj!
    })

  if (flaggedObject) {
    newArray.unshift(flaggedObject)
  }

  return newArray.filter(Boolean) as (CombiWithAddedProps | RelWithAddedProps)[]
}

export const ascendingSort = (
  a: CombiWithAddedProps | RelWithAddedProps,
  b: CombiWithAddedProps | RelWithAddedProps,
) => {
  let x = a.name?.toLowerCase()
  let y = b.name?.toLowerCase()
  if (x && y) {
    if (x < y) {
      return -1
    }
    if (x > y) {
      return 1
    }
  }
  return 0
}

export const childrenSort = (
  a: CombiWithAddedProps | RelWithAddedProps,
  b: CombiWithAddedProps | RelWithAddedProps,
) => {
  // Reminder: negative number = correct order, positive number = wrong order
  let aIsCombi = (a as CombiWithAddedProps).composite
  let bIsCombi = (b as CombiWithAddedProps).composite
  let aIsDashboardDefault = (a as CombiWithAddedProps).isDashboardDefault
  let bIsDashboardDefault = (b as CombiWithAddedProps).isDashboardDefault

  // Within the combis, put dashboardDefault first
  if (aIsDashboardDefault && !bIsDashboardDefault && bIsCombi) {
    return -1
  } else if (!aIsDashboardDefault && bIsDashboardDefault && aIsCombi) {
    return 1
  } else {
    // At least one is not dashboardDefault, put non-combi first? Don't understand this
    if (aIsCombi && !bIsCombi) {
      return 1
    } else if (!aIsCombi && bIsCombi) {
      return -1
    } else {
      // When two are equal, sort by name
      return ascendingSort(a, b)
    }
  }
}

function deepEqual(
  obj1: PossibleDataTypesWithUndefined,
  obj2: PossibleDataTypesWithUndefined,
): boolean {
  if (obj1 === obj2) {
    return true
  }

  if (
    typeof obj1 !== 'object' ||
    typeof obj2 !== 'object' ||
    obj1 === null ||
    obj2 === null
  ) {
    return false
  }

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false
    }
  }

  return true
}

export function areArraysOfObjectsEqual(
  arr1:
    | (CombiWithAddedProps | RelWithAddedProps)[]
    | PossibleDataTypesWithUndefined,
  arr2:
    | (CombiWithAddedProps | RelWithAddedProps)[]
    | PossibleDataTypesWithUndefined,
) {
  if (arr1?.length !== arr2?.length) {
    return false
  }

  const sortedArr1 = arr1?.slice().sort(compareObjects)
  const sortedArr2 = arr2?.slice().sort(compareObjects)

  return deepEqual(
    sortedArr1 as PossibleDataTypesWithUndefined,
    sortedArr2 as PossibleDataTypesWithUndefined,
  )
}

function compareObjects(
  obj1: CombiWithAddedProps | RelWithAddedProps,
  obj2: CombiWithAddedProps | RelWithAddedProps,
) {
  const hash1 = JSON.stringify(obj1)
  const hash2 = JSON.stringify(obj2)
  return hash1.localeCompare(hash2)
}

export type ChannelWithCombiParent = {
  text: string
  value: string
  parentNames?: string[]
}

export function recursiveSearchInCombi(
  combiRelationshipData: CombiWithAddedProps[],
  channel: Partial<Channel>,
  parentNames: string[] = [],
): string[] {
  for (const treeData of combiRelationshipData) {
    if (
      treeData.children?.some((child) => child.id === channel.id) &&
      treeData.name
    ) {
      parentNames.push(treeData.name)
    } else if (
      treeData &&
      treeData.children?.some(
        (child) => child.children && child?.children?.length > 0,
      )
    ) {
      recursiveSearchInCombi(treeData.children, channel, parentNames)
    }
  }
  return parentNames
}

export function parentCombiForChannel(
  combiRelationshipData: CombiWithAddedProps[],
  channelListData?: ChannelSearchManyQuery['channelSearchMany'],
) {
  let channelListWithCombiParent: ChannelWithCombiParent[] = []
  if (channelListData) {
    for (const channel of channelListData) {
      if (channel?.channelType !== 'shadow' && channel?.id && channel?.name) {
        let parentNames: string[] = []
        channelListWithCombiParent.push({
          text: channel.name,
          value: channel.id,
          parentNames: recursiveSearchInCombi(
            combiRelationshipData,
            channel,
            parentNames,
          ),
        })
      }
    }
  }
  return channelListWithCombiParent
}
