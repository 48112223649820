import { Modal, ModalOverlay } from '@chakra-ui/react'
import { Suspense } from 'react'
import SignInForm from '../elements/forms/signIn'

const SignIn = () => {
  return (
    <Suspense
      fallback={
        <Modal isOpen={true} onClose={() => {}} size="md" isCentered>
          <ModalOverlay
            bgGradient={
              'radial-gradient(circle, purple.300 0%, purple.500 80%, purple.800 100%)'
            }
          />
        </Modal>
      }
    >
      <Modal isOpen={true} onClose={() => {}} size="md" isCentered>
        <ModalOverlay
          bgGradient={
            'radial-gradient(circle, purple.300 0%, purple.500 80%, purple.800 100%)'
          }
        />
        <SignInForm />
      </Modal>
    </Suspense>
  )
}

export default SignIn
