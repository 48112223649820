import { CardData } from '../../Pages/dashboards/dashboardWrapper'
import {
  ChannelSearchManyQuery,
  DataGetManyQuery,
} from '../../graphql/generated/graphql'
import { SecondaryGraphData } from '../../elements/building/energyUse'
import { BUILDING_SLUG } from '../../constants'

type CSVType = {
  timestamp: string
  // value: number
  // channelName: string
  [key: string]: string | number
}

export function isCurrentUserStaff() {
  return localStorage.getItem('user-is-staff') === 'TRUE'
}

export function isCurrentUserAdmin() {
  return localStorage.getItem('user-is-admin') === 'TRUE'
}

export function isCurrentUserEndUser() {
  return localStorage.getItem('user-is-admin') !== 'TRUE'
}

export function camelCaseToNormal(string: string) {
  return string.replace(/([a-z])([A-Z])/g, '$1 $2')
}

export const camelCaseToTitleCase = (string: string) => {
  const spaced = string.replace(/([A-Z])/g, ' $1')
  const upperCase = spaced.charAt(0).toUpperCase() + spaced.slice(1)
  return upperCase.trim()
}

export function simulateLoading() {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      // Resolve the promise after the specified delay
      resolve()
    }, 2000) // 2000 milliseconds (2 seconds) delay
  })
}

export const topLargest = (
  secondary: CardData[] | SecondaryGraphData[],
  top: number,
) => {
  const sortedData = secondary.sort((a, b) => b.measurement - a.measurement)
  return sortedData.slice(0, top)
}

export const top3ByPercent = (secondary: CardData[]) => {
  const dataWithPercentage = secondary.map((item) => ({
    ...item,
    percentage: item.target ? (item.measurement / item.target) * 100 : 0,
  }))

  const sortedData = dataWithPercentage.sort(
    (a, b) => b.percentage - a.percentage,
  )

  return sortedData.slice(0, 3)
}

export async function isValidImageUrl(url: string) {
  try {
    const img = new Image()
    await new Promise((resolve, reject) => {
      img.onload = resolve
      img.onerror = reject
      img.src = url
    })
    return true
  } catch (error) {
    return false
  }
}

export function slugify(input: string) {
  return input
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-') // Replace spaces with dashes
    .replace(/[^\w-]+/g, '') // Remove non-word characters except dashes
    .replace(/--+/g, '-') // Replace multiple dashes with a single dash
}

export function uniqueElement({
  arr1,
  arr2,
}: {
  arr1: string[]
  arr2: string[]
}) {
  return arr1.filter((item) => !arr2.includes(item))
}

export const convertJsonToCsv = ({
  datasets,
  buildingName,
  channelListData,
}: {
  datasets: DataGetManyQuery[]
  buildingName: string
  channelListData: ChannelSearchManyQuery['channelSearchMany']
}) => {
  if (!datasets || datasets.length === 0) {
    console.error('There is no dataset to download here')
    return
  }

  const allChannels: string[] = datasets.map(
    (channelData) =>
      channelListData.find(
        (channel) => channelData.dataGetMany.data[0].channelId === channel?.id,
      )?.name ?? '',
  )
  // Flatten the datasets into a single array of BuildingDetailsArrayProps
  const flattenedData: CSVType[] = datasets.flatMap((dataset, i) => {
    return dataset.dataGetMany.data.map((datum) => ({
      timestamp: datum.timestamp
        ? (datum.timestamp.toString() as string)
        : 'noDate',
      [allChannels[i]]: datum.value ?? 0,
    }))
  })

  const resultMap = new Map()

  // Iterate over the input data
  flattenedData.forEach((item) => {
    const { timestamp, ...rest } = item

    // Check if the timestamp already exists in the Map
    if (resultMap.has(timestamp)) {
      // If it exists, update the existing item by merging properties
      const existingItem = resultMap.get(timestamp)
      resultMap.set(timestamp, { ...existingItem, ...rest })
    } else {
      // If it doesn't exist, add a new entry to the Map
      resultMap.set(timestamp, { timestamp, ...rest })
    }
  })
  // Convert the Map values back to an array
  const resultArray = Array.from(resultMap.values())

  if (resultArray.length > 0) {
    const items = resultArray
    const replacer = (
      key: string,
      value: Partial<DataGetManyQuery['dataGetMany']['data']>,
    ) => (value === null ? '' : value)
    const header = ['timestamp', ...allChannels]
    const csv = [
      header.join(','), // header row first
      ...items.map((row: CSVType) =>
        header
          .map((fieldName: string) => JSON.stringify(row[fieldName], replacer))
          .join(','),
      ),
    ].join('\r\n')
    const blob = new Blob([csv], { type: 'data:text/csv;charset=utf-8,' })
    const blobURL = window.URL.createObjectURL(blob)

    // Create new tag for download file
    const anchor = document.createElement('a')
    anchor.download = `${buildingName}-selected-channels.csv` // change the name
    anchor.href = blobURL
    anchor.dataset.downloadurl = [
      'text/csv',
      anchor.download,
      anchor.href,
    ].join(':')
    anchor.click()

    // Remove URL.createObjectURL. The browser should not save the reference to the file.
    setTimeout(() => {
      // For Firefox, it is necessary to delay revoking the ObjectURL
      URL.revokeObjectURL(blobURL)
    }, 100)
  }
}

export const findSlug = () => {
  return localStorage.getItem(BUILDING_SLUG)
}
