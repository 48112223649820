import { Group } from '@visx/group'
import { scaleLinear } from '@visx/scale'
import { HeatmapRect } from '@visx/heatmap'
import { DataInBins, Datum } from '../../graphql/generated/graphql'
import AreaChart from './areaChart'
import { scaleTime, scaleBand } from '@visx/scale'
import { Brush } from '@visx/brush'
import BaseBrush from '@visx/brush/lib/BaseBrush'
import { Bounds } from '@visx/brush/lib/types'
import { BrushHandleRenderProps } from '@visx/brush/lib/BrushHandle'
import { useEffect, useMemo, useRef, useState } from 'react'
import { AxisLeft, AxisBottom } from '@visx/axis'
import { LegendLinear, LegendItem, LegendLabel } from '@visx/legend'

import { PatternLines } from '@visx/pattern'
import {
  convertDataStructure,
  flattenGraphData,
  minMaxTimeStamp,
} from '../../utils/helpers/graphHelpers'
import { min } from 'd3-array'
import { Box, Flex, Text } from '@chakra-ui/react'
import { format } from '@visx/vendor/d3-format'

export type HeatmapProps = {
  width: number
  height: number
  margin?: { top: number; right: number; bottom: number; left: number }
  separation?: number
  events?: boolean
  channelDataInBins?: DataInBins[]
}

const minColor = '#FDDE55'
const maxColor = '#03AED2'

const brushMargin = { top: 10, bottom: 30, left: 20, right: 25 }
const PATTERN_ID = 'brush_pattern'
const accentColor = '#3182ce70'
const background = 'white'
const selectedBrushStyle = {
  fill: `url(#${PATTERN_ID})`,
  stroke: 'white',
}
const tickValues = [22, 20, 18, 16, 14, 12, 10, 8, 6, 4, 2, 0]

const defaultMargin = { top: 10, left: 35, right: -1, bottom: 80 }

const getValue = (d: Partial<Datum>) => d?.value!

const HeatMap = ({
  width,
  height,
  events = false,
  margin = defaultMargin,
  separation = 0,
  channelDataInBins = [],
}: HeatmapProps) => {
  const brushRef = useRef<BaseBrush | null>(null)
  const [filteredHeatmapData, setBinData] = useState<DataInBins[]>([])
  const convertedBinData = convertDataStructure(channelDataInBins ?? [])
  const legendRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (
      channelDataInBins?.length &&
      JSON.stringify(filteredHeatmapData) !== JSON.stringify(channelDataInBins)
    ) {
      setBinData(channelDataInBins)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelDataInBins])

  function max<Datum>(data: Datum[], value: (d: Datum) => number): number {
    return Math.max(...data.map(value))
  }

  const bucketSizeMax = 12 // 2 hours slot 24

  const { minTimestamp, maxTimestamp } = minMaxTimeStamp(convertedBinData)
  const flattenedGraphData = flattenGraphData(convertedBinData)

  // scales
  const xScale = scaleLinear<number>({
    domain: [0, filteredHeatmapData.length],
  })
  const yScale = scaleLinear<number>({
    domain: [0, bucketSizeMax],
  })
  const rectColorScale = scaleLinear<string>({
    range: [minColor, maxColor],
    domain: [
      min(flattenedGraphData ?? [], getValue) ?? 0,
      max(flattenedGraphData ?? [], getValue) ?? 0,
    ],
  })
  const opacityScale = scaleLinear<number>({
    range: [1, 1],
    domain: [
      min(flattenedGraphData ?? [], getValue) ?? 0,
      max(flattenedGraphData ?? [], getValue) ?? 0,
    ],
  })

  const innerHeight = height - margin.top - margin.bottom
  const topChartHeight = 0.8 * innerHeight
  const bottomChartHeight = 0.2 * innerHeight
  const xBrushMax = Math.max(width - brushMargin.left - brushMargin.right, 0)
  const yBrushMax = bottomChartHeight

  const countValue = (bin: any) => bin.bin.value
  const color = (bin: any) => rectColorScale(countValue(bin))
  const opacity = (bin: any) => opacityScale(countValue(bin))

  const size =
    width > margin.left + margin.right
      ? width - margin.left - margin.right - separation
      : width
  const xMax = size
  const yMax = topChartHeight - margin.top - margin.bottom

  const binWidth = xMax / filteredHeatmapData.length
  const binHeight = yMax / bucketSizeMax

  xScale.range([0, xMax])
  yScale.range([yMax, 0])

  const brushDateRange = useMemo(
    () => [minTimestamp, maxTimestamp],
    [minTimestamp, maxTimestamp],
  )

  const brushDateScale = useMemo(
    () =>
      scaleTime<number>({
        range: [0, xBrushMax],
        domain: brushDateRange,
      }),
    [xBrushMax, brushDateRange],
  )

  const brushValueScale = useMemo(
    () =>
      scaleLinear({
        range: [yBrushMax, 0],
        domain: [
          min(flattenedGraphData ?? [], getValue)!,
          max(flattenedGraphData ?? [], getValue)!,
        ],
        nice: true,
      }),
    [yBrushMax, flattenedGraphData],
  )

  const filterBinData = (
    binData: DataInBins[],
    startDate: number,
    endDate: number,
  ) => {
    return binData.filter((bin) => {
      const timestampDate: Date = new Date(bin.date)
      return (
        timestampDate.getTime() >= startDate &&
        timestampDate.getTime() < endDate
      )
    })
  }

  const onBrushChange = (domain: Bounds | null) => {
    if (!domain) return
    const { x0, x1 } = domain
    const filteredData = filterBinData(channelDataInBins, x0, x1)
    if (
      filteredData.length !== filteredHeatmapData.length ||
      JSON.stringify(filteredData) !== JSON.stringify(filteredHeatmapData)
    ) {
      setBinData(filteredData)
    }
  }

  const hoursScale = scaleLinear({
    domain: [0, 24],
    range: [yMax, 0],
  })

  const binScale = scaleBand({
    domain: filteredHeatmapData.map((datum) => datum.date),
    range: [0, filteredHeatmapData.length * binWidth],
  })

  const oneDecimalFormat = format('.0f')
  const legendGlyphSize = 15

  return width < 10 ? null : (
    <Box className="demo_heatmap">
      <Flex justifyContent={'right'} ref={legendRef}>
        <LegendLinear
          scale={rectColorScale}
          direction={'row'}
          itemDirection={'row'}
          steps={10}
          labelAlign={'bottom'}
          labelFormat={(d, i) => oneDecimalFormat(d)}
        >
          {(labels) =>
            labels.map((label, i) => (
              <LegendItem
                key={`legend-quantile-${i}`}
                onClick={() => {
                  if (events) alert(`clicked: ${JSON.stringify(label)}`)
                }}
                style={{ display: 'inline-block', margin: -1 }}
              >
                <svg
                  width={legendGlyphSize * 3}
                  height={legendGlyphSize}
                  style={{ margin: '0' }}
                >
                  <rect
                    fill={label.value}
                    width={legendGlyphSize * 3}
                    height={legendGlyphSize}
                  />
                </svg>
                <LegendLabel align="bottom">
                  <Text fontSize={'10px'}>{label.text}</Text>
                </LegendLabel>
              </LegendItem>
            ))
          }
        </LegendLinear>
      </Flex>
      <svg
        width={width}
        height={height - (legendRef.current?.clientHeight ?? 0)}
      >
        <rect x={0} y={0} width={width} height={height} fill={background} />
        <Group left={margin.left}>
          <HeatmapRect
            data={filteredHeatmapData}
            xScale={(d) => xScale(d) ?? 0}
            yScale={(d) => yScale(d) ?? 0}
            colorScale={rectColorScale}
            opacityScale={opacityScale}
            binWidth={binWidth}
            binHeight={binHeight}
            gap={2}
          >
            {(heatmap) =>
              heatmap.map((heatmapBins, i) =>
                heatmapBins.map((bin, j) => {
                  const colorValue = color(bin),
                    opacityValue = opacity(bin)
                  return (
                    <rect
                      key={`heatmap-rect-${i}-${j}`}
                      className="visx-heatmap-rect"
                      width={bin.width}
                      height={bin.height}
                      x={bin.x}
                      y={bin.y}
                      fill={colorValue}
                      fillOpacity={opacityValue}
                      onClick={() => {
                        // if (!events) return;
                        console.log({ bin })
                      }}
                    />
                  )
                }),
              )
            }
          </HeatmapRect>
          <AxisLeft
            scale={hoursScale}
            tickValues={tickValues}
            tickFormat={(tick) => (tick < 10 ? `0${tick}:00` : `${tick}:00`)}
            top={binHeight}
            numTicks={12}
          />
          <AxisBottom scale={binScale} top={yMax + binHeight} />
        </Group>
        <Group className="demo_brushchart">
          <AreaChart
            hideLeftAxis={true}
            hideBottomAxis={false}
            selectedChannelData={convertedBinData}
            isBrush={true}
            width={width}
            yMax={yBrushMax}
            xMax={xBrushMax}
            xScale={brushDateScale}
            yScale={brushValueScale}
            margin={brushMargin}
            top={topChartHeight}
          >
            <PatternLines
              id={PATTERN_ID}
              height={8}
              width={8}
              stroke={accentColor}
              strokeWidth={2}
              orientation={['diagonal']}
            />
            <Brush
              xScale={brushDateScale}
              yScale={brushValueScale}
              width={xBrushMax}
              height={yBrushMax}
              margin={brushMargin}
              handleSize={8}
              innerRef={brushRef}
              resizeTriggerAreas={['left', 'right']}
              brushDirection="horizontal"
              // initialBrushPosition={initialBrushPosition}
              onChange={onBrushChange}
              // onClick={() => setFilteredValues(details)}
              selectedBoxStyle={selectedBrushStyle}
              useWindowMoveEvents
              renderBrushHandle={(props) => <BrushHandle {...props} />}
            />
          </AreaChart>
        </Group>
      </svg>
    </Box>
  )
}

// We need to manually offset the handles for them to be rendered at the right position
function BrushHandle({ x, height, isBrushActive }: BrushHandleRenderProps) {
  const pathWidth = 8
  const pathHeight = 20
  if (!isBrushActive) {
    return null
  }
  return (
    <Group left={x + pathWidth / 2} top={(height - pathHeight) / 2}>
      <path
        fill="#f2f2f2"
        d="M -4.5 0.5 L 3.5 0.5 L 3.5 15.5 L -4.5 15.5 L -4.5 0.5 M -1.5 4 L -1.5 12 M 0.5 4 L 0.5 12"
        stroke="#999999"
        strokeWidth="1"
        style={{ cursor: 'ew-resize' }}
      />
    </Group>
  )
}

export default HeatMap
