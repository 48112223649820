import { createIcon } from '@chakra-ui/react'

const Office = createIcon({
  displayName: 'Office',
  viewBox: '0 0 224 213',
  path: (
    <g id="g62" transform="translate(-74.062,-70.679)">
      <g id="g8">
        <path
          d="M 280.693,142.866 186.04,81.784 91.39,142.865 c -2.164,1.403 -5.054,0.775 -6.45,-1.389 -1.398,-2.166 -0.774,-5.053 1.39,-6.45 l 99.71,-64.347 99.713,64.347 c 2.165,1.397 2.787,4.284 1.39,6.45 -1.401,2.172 -4.298,2.782 -6.45,1.39 z"
          fill="currentColor"
        />
      </g>
      <polygon
        points="258.888,193.24 271.6,193.24 271.6,207.907 258.888,207.907 "
        fill="currentColor"
      />
      <polygon
        points="238.354,193.24 250.088,193.24 250.088,207.907 238.354,207.907 "
        fill="currentColor"
      />
      <polygon
        points="258.888,167.817 271.6,167.817 271.6,182.484 258.888,182.484 "
        fill="currentColor"
      />
      <polygon
        points="238.354,167.817 250.088,167.817 250.088,182.484 238.354,182.484 "
        fill="currentColor"
      />
      <polygon
        points="258.888,216.707 271.6,216.707 271.6,232.352 258.888,232.352 "
        fill="currentColor"
      />
      <polygon
        points="238.354,216.707 250.088,216.707 250.088,232.352 238.354,232.352 "
        fill="currentColor"
      />
      <polygon
        points="258.888,241.152 271.6,241.152 271.6,255.82 258.888,255.82 "
        fill="currentColor"
      />
      <polygon
        points="238.354,241.152 250.088,241.152 250.088,255.82 238.354,255.82 "
        fill="currentColor"
      />
      <polygon
        points="258.888,264.62 271.6,264.62 271.6,280.265 258.888,280.265 "
        fill="currentColor"
      />
      <polygon
        points="238.354,264.62 250.088,264.62 250.088,280.265 238.354,280.265 "
        fill="currentColor"
      />
      <polygon
        points="122.972,193.24 134.706,193.24 134.706,207.907 122.972,207.907 "
        fill="currentColor"
      />
      <polygon
        points="101.461,193.24 114.172,193.24 114.172,207.907 101.461,207.907 "
        fill="currentColor"
      />
      <polygon
        points="122.972,167.817 134.706,167.817 134.706,182.484 122.972,182.484 "
        fill="currentColor"
      />
      <polygon
        points="101.461,167.817 114.172,167.817 114.172,182.484 101.461,182.484 "
        fill="currentColor"
      />
      <polygon
        points="122.972,216.707 134.706,216.707 134.706,232.352 122.972,232.352 "
        fill="currentColor"
      />
      <polygon
        points="101.461,216.707 114.172,216.707 114.172,232.352 101.461,232.352 "
        fill="currentColor"
      />
      <polygon
        points="122.972,241.152 134.706,241.152 134.706,255.82 122.972,255.82 "
        fill="currentColor"
      />
      <polygon
        points="101.461,241.152 114.172,241.152 114.172,255.82 101.461,255.82 "
        fill="currentColor"
      />
      <polygon
        points="122.972,264.62 134.706,264.62 134.706,280.265 122.972,280.265 "
        fill="currentColor"
      />
      <polygon
        points="101.461,264.62 114.172,264.62 114.172,280.265 101.461,280.265 "
        fill="currentColor"
      />
      <path
        d="m 78.423,156.327 h 5.945 V 283.32 H 92.19 V 156.327 H 278.951 V 283.32 h 8.801 V 156.327 h 5.905 c 2.405,0 4.361,-1.995 4.361,-4.4 0,-2.405 -1.956,-4.4 -4.361,-4.4 H 78.423 c -2.405,0 -4.361,1.995 -4.361,4.4 0,2.406 1.956,4.4 4.361,4.4 z"
        fill="currentColor"
      />
      <path
        d="M 151.329,283.321 V 170.765 c 0,-2.409 -1.992,-4.363 -4.4,-4.363 -2.409,0 -4.4,1.954 -4.4,4.363 v 112.556 z"
        fill="currentColor"
      />
      <path
        d="M 171.863,283.321 V 170.765 c 0,-2.409 -1.992,-4.363 -4.4,-4.363 -2.408,0 -4.4,1.954 -4.4,4.363 v 112.556 z"
        fill="currentColor"
      />
      <path
        d="M 189.464,283.321 V 170.765 c 0,-2.409 -1.991,-4.363 -4.4,-4.363 -2.408,0 -4.4,1.954 -4.4,4.363 v 112.556 z"
        fill="currentColor"
      />
      <path
        d="M 209.998,283.321 V 170.765 c 0,-2.409 -1.992,-4.363 -4.4,-4.363 -2.408,0 -4.4,1.954 -4.4,4.363 v 112.556 z"
        fill="currentColor"
      />
      <path
        d="M 229.554,283.321 V 170.765 c 0,-2.409 -1.502,-4.363 -3.911,-4.363 -2.408,0 -3.912,1.954 -3.912,4.363 v 112.556 z"
        fill="currentColor"
      />
    </g>
  ),
})

export default Office
