import { createIcon } from '@chakra-ui/react'

const Ventilation = createIcon({
  displayName: 'Ventilation',
  viewBox: '0 0 232 232',
  path: (
    <g id="g10" transform="translate(-76.624502,-61.079497)">
      <path
        d="m 259.832,117.937 c -4.437,-4.95 -11.014,-7.659 -17.591,-6.691 -23.634,3.482 -40.167,20.608 -48.879,42.649 -0.088,-0.001 -0.173,-0.026 -0.263,-0.026 -0.605,0 -1.174,0.134 -1.769,0.178 -1.156,-25.426 4.052,-44.339 18.597,-61.303 0.978,-1.14 0.41,-2.981 -1.07,-3.244 -17.95,-3.188 -35.996,-0.783 -52.045,6.317 -6.078,2.689 -10.687,8.109 -11.797,14.662 -3.998,23.583 7.212,44.619 25.521,59.721 -0.212,0.631 -0.433,1.259 -0.592,1.912 -24.517,-6.758 -40.881,-17.553 -52.514,-36.617 -0.782,-1.282 -2.708,-1.311 -3.414,0.014 -8.579,16.088 -11.869,33.992 -10.077,51.45 0.68,6.611 4.409,12.671 10.298,15.752 21.186,11.085 44.647,6.931 64.661,-5.804 0.542,0.403 1.088,0.782 1.663,1.138 -14.002,21.23 -29.329,33.457 -51.054,38.629 -1.461,0.348 -2.083,2.172 -1.041,3.253 12.649,13.13 28.662,21.79 45.817,25.48 6.498,1.397 13.414,-0.277 18.165,-4.926 17.079,-16.714 20.384,-40.297 14.468,-63.258 0.553,-0.392 1.048,-0.852 1.564,-1.291 15.88,19.89 22.78,38.25 20.985,60.522 -0.12,1.498 1.421,2.653 2.773,1.996 16.395,-7.972 29.579,-20.525 38.391,-35.702 3.337,-5.748 3.881,-12.842 0.928,-18.796 -10.629,-21.431 -32.066,-31.859 -55.758,-33.313 -0.201,-0.637 -0.344,-1.3 -0.595,-1.914 23.785,-8.927 43.36,-9.804 63.964,-1.224 1.386,0.577 2.962,-0.532 2.755,-2.02 -2.517,-18.056 -10.379,-34.474 -22.091,-47.544 z m -66.733,71.789 c -6.6,0 -11.952,-5.351 -11.952,-11.952 0,-6.601 5.352,-11.952 11.952,-11.952 6.602,0 11.952,5.351 11.952,11.952 0,6.601 -5.35,11.952 -11.952,11.952 z"
        id="path6"
        fill="currentColor"
      />
      <rect
        x="82.704002"
        y="67"
        width="220"
        height="220"
        id="rect8"
        style={{
          fill: 'none',
          stroke: 'currentColor',
          strokeWidth: 12.159,
          strokeLinejoin: 'round',
          strokeMiterlimit: 10,
        }}
      />
    </g>
  ),
})

export default Ventilation
