import { createIcon } from '@chakra-ui/react'

const TotalElectricity = createIcon({
  displayName: 'Total Electricity',
  viewBox: '0 0 156 220',
  path: (
    <polygon
      fill="currentColor"
      points="74.799,212.872 136.239,212.874 108.781,303.844 230.968,160.944 169.527,160.941 218.713,83.844 157.11,83.844 "
      id="polygon6"
      transform="translate(-74.799,-83.844)"
    />
  ),
})

export default TotalElectricity
