import { indicatorTypeData } from '../../../utils/indicators/indicators'

export type CircleIndicatorProps = {
  percent: number
  size: number | undefined
  type: string
}

const CircleIndicator = ({ percent, size, type }: CircleIndicatorProps) => {
  const upIsBetter = indicatorTypeData[type].upIsBetter
  const modifiedPercent = percent > 150 ? 150 : percent
  const dashArray = 100,
    dashOffsetMax = dashArray * 2
  let greenPathLength = dashArray,
    yellowPathLength = dashArray,
    redPathLength = dashArray,
    calcPercent = percent

  // let blipColor = `#${type}A`

  const pathRatio = (calculatedPercent: number): number => {
    return 2 * calculatedPercent + dashArray
  }

  // function getAngle(value: number) {
  //   const totalDegrees = 360
  //   const numPoints = 150
  //   const degreesPerPoint = totalDegrees / numPoints

  //   let degrees = (numPoints - value) * degreesPerPoint
  //   degrees += 270

  //   if (degrees >= 360) {
  //     degrees -= 360
  //   }

  //   return degrees
  // }

  const changeOffset = (percent: number): void => {
    if (percent > 100) {
      calcPercent = percent - 100
      // blipColor = `#${type}C`
      redPathLength = pathRatio(calcPercent)
      yellowPathLength = dashOffsetMax
      greenPathLength = dashOffsetMax
    } else if (percent > 50) {
      calcPercent = percent - 50
      // blipColor = `#${type}B`
      redPathLength = dashArray
      yellowPathLength = pathRatio(calcPercent)
      greenPathLength = dashOffsetMax
    } else if (percent <= 50) {
      // blipColor = `#${type}A`
      redPathLength = dashArray
      yellowPathLength = dashArray
      greenPathLength = pathRatio(percent)
    }
  }
  changeOffset(modifiedPercent)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={'100%'}
      height={'100%'}
      viewBox="0 0 120.266 119.54"
    >
      <style>
        {`.B{stroke-width:.1}.C{stroke:#000}.D{font-size:3.704px}.F{fill-opacity:1}`}
      </style>
      <defs>
        <linearGradient
          id={`${type}A`}
          x1="-51.504"
          y1="-126.664"
          x2="28.73"
          y2="-126.664"
          gradientUnits="userSpaceOnUse"
        >
          {upIsBetter ? (
            <>
              <stop offset="0.90" stopColor="#95F985" />
              <stop offset="0.95" stopColor="#4DED30" />
            </>
          ) : (
            <>
              <stop offset=".8" stopColor="#00ff00" />
              <stop offset=".95" stopColor="#ffff00" />
            </>
          )}
        </linearGradient>
        <linearGradient
          id={`${type}B`}
          x1="118.389"
          y1="82.053"
          x2="198.639"
          y2="82.053"
          gradientUnits="userSpaceOnUse"
        >
          {upIsBetter ? (
            <>
              <stop offset="0" stopColor="#4DED30" />
              <stop offset="0.9" stopColor="#26D701" />
            </>
          ) : (
            <>
              {' '}
              <stop offset=".8" stopColor="#ffff00" />
              <stop offset=".9" stopColor="#ffbe00" />
            </>
          )}
        </linearGradient>
        <linearGradient
          id={`${type}C`}
          x1="-147.268"
          y1="124.838"
          x2="-67.018"
          y2="124.838"
          gradientUnits="userSpaceOnUse"
        >
          {upIsBetter ? (
            <>
              <stop offset="0.1" stopColor="#26D701" />
              <stop offset="1" stopColor="#00AB08" />
            </>
          ) : (
            <>
              {' '}
              <stop offset="0" stopColor="#ffbe00" />
              <stop offset="1" stopColor="red" />
            </>
          )}
        </linearGradient>
        <radialGradient id="blipMaskGradient">
          <stop stopColor="#FFFFFF" offset="0" stopOpacity="10.8"></stop>
          <stop stopColor="#888888" offset="0.25" stopOpacity="0.6"></stop>
          <stop stopColor="#444444" offset="0.5" stopOpacity="0.4"></stop>
          <stop stopColor="#222222" offset="0.73" stopOpacity="0.4"></stop>
          <stop stopColor="#222222" offset="0.75" stopOpacity="0.3"></stop>
          <stop stopColor="#000000" offset="1" stopOpacity="0.4"></stop>
          <stop stopColor="#222222" offset="0.79" stopOpacity="0.4"></stop>
        </radialGradient>
      </defs>
      <g
        transform="translate(-39.346 -58.701)"
        fill="#fff"
        fillOpacity="0"
        paintOrder="markers stroke fill"
      >
        <g className="C">
          <circle
            cx="98.103"
            cy="120.61"
            r="50"
            strokeWidth="5"
            strokeOpacity=".114"
          />
          <path
            transform="matrix(-.841159 .540788 -.540434 -.841387 0 0)"
            d="M39.238-148.438c-3.111 28.542-27.292 50.754-56.662 50.754-9.877 0-19.168-2.512-27.267-6.933m-3.226-1.901c-15.932-10.108-26.506-27.902-26.506-48.166 0-19.257 9.55-36.284 24.171-46.603m6.649-4.112c7.927-4.179 16.596-6.286 26.18-6.286 31.48 0 57 25.52 57 57"
            strokeWidth="0.3"
          />
        </g>
        <g strokeWidth="2.5">
          <path
            d="m 25.230316,-153.4138 a 49.989017,50.001369 0 0 1 -24.99450853,43.30245 49.989017,50.001369 0 0 1 -49.98901747,0"
            transform="matrix(-.866017 .500015 -.49965 -.866227 0 0)"
            stroke={`url(#${type}A)`}
            strokeLinecap="round"
            strokeDasharray={dashArray}
            pathLength={dashArray}
            strokeDashoffset={greenPathLength}
          />
          <path
            d="m 195.13881,55.304436 a 50,50 0 0 1 -25,43.30127 50,50 0 0 1 -50,0"
            transform="rotate(29.985)"
            stroke={`url(#${type}B)`}
            strokeLinecap="round"
            strokeDasharray={dashArray}
            pathLength={dashArray - 0.3}
            strokeDashoffset={yellowPathLength}
          />
          <path
            d="m -70.517807,98.088737 a 50,50 0 0 1 -25,43.301273 50,50 0 0 1 -50.000003,0"
            transform="rotate(269.994)"
            stroke={`url(#${type}C)`}
            strokeLinecap="round"
            strokeDasharray={dashArray}
            pathLength={dashArray - 0.3}
            strokeDashoffset={redPathLength}
          />
        </g>

        <path
          d="M98.031 58.372l.008 5.506m-56.189 82.3l5.506-.002m100.174 3.137l5.506-.002"
          strokeWidth="0.3"
          fill="#000"
          className="C"
        />
        <text
          x="99.326"
          y="62.113"
          letterSpacing="0"
          fill="#000"
          className="C D E F"
        >
          <tspan
            x="99.326"
            y="62.113"
            dx="0.029999999"
            rotate="0 0 0 0 0"
            letterSpacing="0"
            className="B G"
          >
            150%
          </tspan>
        </text>
        <text
          x="148.751"
          y="147.998"
          letterSpacing="0"
          fill="#000"
          className="C D E F"
        >
          <tspan
            x="148.751"
            y="147.998"
            dx="0.029999999"
            rotate="0 0 0 0 0"
            letterSpacing="0"
            className="B G"
          >
            100%
          </tspan>
        </text>
        <text
          x="39.058"
          y="149.859"
          letterSpacing="0"
          fill="#000"
          className="C D E F"
        >
          <tspan
            x="39.058"
            y="149.859"
            rotate="0 0 0 0"
            letterSpacing="0"
            className="B G"
          >
            50%
          </tspan>
        </text>
      </g>
      {/* <g transform="translate(-39.346 -58.701)">
          <circle
            cx="98.103"
            cy="120.61"
            r="5"
            fill="url(#blipMaskGradient)"
            transform={`rotate(${getAngle(
              modifiedPercent,
            )} 50 50) translate(50 0) rotate(-${getAngle(
              modifiedPercent,
            )} 50 50)`}
            style={{ filter: 'blur(5px)' }}
          />

          <circle
            cx="98.103"
            cy="120.61"
            r="5"
            fill={`url(${blipColor})`}
            transform={`rotate(${getAngle(
              modifiedPercent,
            )} 50 50) translate(50 0) rotate(-${getAngle(
              modifiedPercent,
            )} 50 50)`}
            style={{ filter: 'blur(2px)' }}
          />
        </g> */}
    </svg>
  )
}

export default CircleIndicator
